import React from "react"
import withReducer from "redux/withReducer"
import { withStyles } from "@material-ui/core/styles"
import { } from "assets/jss/material-dashboard-react.js"
import { connect } from "react-redux"
import reducer from "redux/reducers"
import Actions from "redux/actions"
import { withRouter } from "react-router"
import MaterialTable from "components/MaterialTable.js"
import Button from "components/CustomButtons/Button.js"
import PubSub from "pubsub-js"
import { dossierListColumns } from "utils/columns"
import moment from "moment"
import { Switch } from "@material-ui/core"
var dispatch

class FormList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closed: false,
    }
    dispatch = this.props.dispatch
  }

  componentDidMount() {
    dispatch(Actions.getLocataires())
    dispatch(Actions.getClients())
  }

  render() {
    const c = this.props.classes
    const { dossiers, canDelete } = this.props
    const { closed } = this.state
    console.log("Dossier", dossiers)
    return (
      <div className={c.container}>
        <div className='fullW row align-c justify-e'>
          <p
            style={{
              color: closed ? "green" : "unset",
              fontWeight: closed ? "bold" : "unset",
            }}>
          </p>
          Clos
          <Switch checked={!closed} onChange={_ => this.setState({ closed: !closed })} />
          <p
            style={{
              color: !closed ? "green" : "unset",
              fontWeight: !closed ? "bold" : "unset",
            }}>
            En cours
          </p>
        </div>
        <MaterialTable
          title=''
          isLoading={!dossiers}
          columns={dossierListColumns}
          actions={[
            {
              icon: "edit",
              tooltip: "Modifier le dossier",
              onClick: (event, rowData) => this.props.history.push(`/dossiers/${rowData.id}/edit`),
            },
            {
              icon: "remove_red_eye",
              tooltip: "Voir les details du dossier",
              onClick: (event, rowData) => this.props.history.push(`/dossiers/${rowData.id}`),
            },
            {
              icon: "home",
              tooltip: "Voir les details de l'appartement",
              onClick: (event, rowData) =>
                this.props.history.push(`/appartements/${rowData.appartement.id}`),
            },
            {
              icon: "supervised_user_circle",
              tooltip: "Voir les details du locataire",
              onClick: (event, rowData) =>
                this.props.history.push(`/locataires/${rowData.locataire.id}`),
            },
            canDelete &&
            (rd => {
              const closed = moment().isAfter(rd.dateFinBail)
              return {
                icon: closed ? "delete" : "close",
                tooltip: closed ? "Supprimer le dossier" : "Clore le dossier",
                onClick: (event, rowData) =>
                  PubSub.confirm({
                    title: closed ? "Supprimer le dossier" : "Clore le dossier",
                    subtitle: "Cette action est irreversible.",
                    onAgree: _ => {
                      if (closed)
                        dispatch(Actions.deleteDossier(rd.id)).then(() =>
                          this.componentDidMount()
                        )
                      else
                        dispatch(
                          Actions.removeTenant(rowData.appartement.id, rowData.locataire.id)
                        ).then(() => this.componentDidMount())
                    },
                  }),
              }
            }),
            {
              icon: p => <Button color='primary'>Nouveau Dossier</Button>,
              isFreeAction: true,
              onClick: event => this.props.history.push(`/dossiers/new`),
            },
          ]}
          data={dossiers.filter(d =>
            d.dateFinBail && moment().isAfter(d.dateFinBail) ? closed : !closed
          )}
          onRowClick={(event, rowData) =>
            this.props.history.push(`/locataires/${rowData.locataire.id}`)
          }
          options={{
            filtering: true,
            pageSize: 20,
            actionsColumnIndex: -1,
          }}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container: {},
})

const mapStateToProps = state => ({
  canDelete: state.reducer.canDelete,
  dossiers: (state.reducer.locataires || []).reduce((out, curr) => {
    const locs = curr.locations.map(l => {
      console.log("start", l)
      const { Dossier, ...rest } = l
      rest.proprietaire = (state.reducer.clients || []).find(p =>
        p.biens.some(b => b.id === rest.id)
      )
      return { ...Dossier, appartement: rest, locataire: curr }
    })
    return (out = out.concat(locs))
  }, []),
})
export default withRouter(
  withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(FormList)))
)
