import React from "react"
import withReducer from "redux/withReducer"
import { withStyles } from "@material-ui/core/styles"
import {} from "assets/jss/material-dashboard-react.js"
import { connect } from "react-redux"
import reducer from "redux/reducers"
import Actions from "redux/actions"
import AssociationList from "components/AssociationList"
import Form from "components/MuiJsonForm"

import { withRouter } from "react-router"
import MaterialTable from "components/MaterialTable.js"
import PubSub from "pubsub-js"

import { parametresListColumns, equipementsColumns, usersColumns } from "utils/columns"
import { userSchema } from "utils/schemas"
var dispatch

class Parametres extends React.Component {
  constructor(props) {
    super(props)
    dispatch = this.props.dispatch
  }

  componentDidMount() {
    dispatch(Actions.getVariables())
    dispatch(Actions.getEquipements())
    dispatch(Actions.getUsers())
  }

  render() {
    const c = this.props.classes
    const { variables, equipements, users, currentUser } = this.props
    return (
      <div className={c.container}>
        <MaterialTable
          title='Variables'
          isLoading={!variables}
          columns={parametresListColumns}
          data={Object.entries(variables || {}).map(([k, v]) => v)}
          onRowClick={_ => null}
          options={{
            filtering: false,
            paging: false,
            pageSize: 20,
            actionsColumnIndex: -1,
          }}
          editable={{
            onRowUpdate: (newData, oldData) => {
              console.log("newdata", newData)
              return dispatch(Actions.setVariable(newData.id, newData))
            },
          }}
        />
        <MaterialTable
          title='Equipements'
          isLoading={!equipements}
          style={{ marginTop: 20, marginBottom: 20 }}
          columns={equipementsColumns}
          data={equipements}
          onRowClick={_ => null}
          options={{
            filtering: false,
            paging: false,
            pageSize: 20,
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: "delete",
              tooltip: "Supprimer l'equipement",
              onClick: (_, rowData) =>
                PubSub.confirm({
                  title: "Supprimer l'équipement ?",
                  subtitle: "Cette action est irreversible.",
                  onAgree: _ => dispatch(Actions.deleteEquipement(rowData.id)),
                }),
            },
          ]}
          editable={{
            onRowUpdate: (newData, oldData) => {
              return dispatch(Actions.setEquipement(newData.id, newData))
            },
          }}
        />
        <AssociationList
          title='Utilisateurs'
          data={(users && users.filter(u => u.id !== currentUser.id && u.role !== "admin")) || []}
          columns={usersColumns}
          actions={[
            {
              icon: "settings_backup_restore",
              tooltip: "Réinitialiser le mot de passe",
              onClick: (_, rowData) =>
                PubSub.confirm({
                  title: "Réinitialiser le mot de passe",
                  subtitle: `Cette action est irreversible, elle réglera le mot de passe de l'utilisateur à "patrimoinelocatif". L'utilisateur sera invité à changer son mot de passe à sa prochaine connexion.`,
                  onAgree: _ =>
                    dispatch(Actions.resetPassword(rowData.id)).then(_ => {
                      PubSub.notif({
                        txt: "Le mot de passe de l'utilisateur à bien été réinitilisé.",
                        color: "success",
                        icon: "settings_backup_restore",
                      })
                    }),
                }),
            },
          ]}
          onTopRightButton={close => (
            <Form
              style={{ width: 200 }}
              {...userSchema({}, true, false)}
              onSubmit={({ formData: { data } }) => {
                dispatch(Actions.addUser(data)).then(_ => {
                  this.componentDidMount()
                  close()
                })
              }}
              onError={console.log}
            />
          )}
          editable={{
            onRowUpdate: (newData, oldData) => {
              console.log("update", newData)
              return dispatch(Actions.setUser(newData.id, newData))
            },
          }}
          topRightButtonTxt='Ajouter un utilisateur'
          noDataTxt='Aucun utilisateur.'
          removeTxt="Supprimer l'utilisateur"
          onRemove={userId => dispatch(Actions.deleteUser(userId))}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container: {},
})

const mapStateToProps = state => ({
  variables: state.reducer.variables,
  equipements: state.reducer.equipements,
  users: state.reducer.users,
  currentUser: state.reducer.currentUser,
})
export default withRouter(withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(Parametres))))
