import { OneOfGenerator } from "components/MuiJsonForm"
import { appartementColumns } from "./columns"
import _ from "lodash"
import moment from "moment"
const deleteEmptyFields = (schema, exclude = []) => {
  Object.entries(schema.properties).forEach(([mainKey, scope]) => {
    if (scope.properties)
      Object.entries(scope.properties).forEach(([key, value]) => {
        if (
          (value.default === undefined || value.default === null) &&
          !exclude.includes(key) &&
          !key.includes("separator")
        )
          delete scope.properties[key]
      })
  })
}

export const dossierSchema = (
  dossier,
  onChange,
  appart = {},
  locataire = {},
  appartements,
  locataires,
  defaultQuitanceDay = 5,
  defaultIndice,
  defaultCarac,
  isNew = false,
  readonly = false,
  onCreateAppart,
  onCreateLoc,
  createTxt,
  defaultTVA,
  defaultindiceTrim
) => {
  function getDefault(key, def = undefined) {
    if (def !== undefined && (!dossier || !dossier[key])) return { default: def }
    if (isNew || !dossier) return { default: undefined }
    return { default: dossier[key] }
  }
  const fraisDossierTVA = dossier
    ? Math.round(
        ((Math.round(dossier.fraisDossierTauxTVA * 100) / 100 || 0) / 100) *
          (dossier.fraisDossierHT || 0) *
          100
      ) / 100
    : 0
  const fraisDossierTTC = dossier
    ? (Math.round(dossier.fraisDossierHT * 100) / 100 || 0) + fraisDossierTVA
    : 0
  const loyerTTC = dossier
    ? (Math.round(dossier.loyerHC * 100) / 100 || 0) + (dossier.LoyerCharge || 0)
    : 0
  const schema = {
    type: "object",
    properties: {
      data: {
        type: "object",
        title: "data",
        readOnly: readonly,
        required: [
          "dateEntree",
          "appart",
          "locataire",
          "jourQuitance",
          "loyerHC",
          "loyerCharge",
          "dateCreationBail",
          "dateDebutBail",
          "depotGarantie",
          "numFraisDossier",
        ],
        properties: {
          appart: {
            default: !isNew ? appart : undefined,
            title: "Appartement lié",
            type: "string",
            format: "selector",
            readOnly: !isNew,
            noTxt: "Aucun Appartement sélectionné",
            selectorTitle: "Lier un Appartement",
            onCreateAppart,
            createTxt,
            displayValue: app => app.adresse,
            choices: appartements,
            columns: [
              { title: "Adresse", field: "adresse" },
              { title: "Situation", field: "situation" },
              { title: "Ville", field: "ville" },
              { title: "Surface", field: "surface" },
            ],
            selectTxt: "lier au dossier",
          },
          locataire: {
            default: !isNew ? locataire : undefined,
            title: "Locataire lié",
            type: "string",
            format: "selector",
            readOnly: !isNew,
            noTxt: "Aucun Locataire sélectionné",
            selectorTitle: "Lier un Locataire",
            onCreateLoc,
            createTxt,
            displayValue: app => app.nom + " " + app.prenom,
            choices: locataires,
            columns: [{ title: "Nom", field: "nom" }, { title: "Prénom", field: "prenom" }],
            selectTxt: "lier au dossier",
          },
          lieuSignature: {
            ...getDefault("lieuSignature", "Paris"),
            type: "string",
            title: "Ville de signature du contrat",
          },
          dateEntree: {
            ...getDefault("dateEntree"),
            type: "string",
            format: "date",
            title: "Date d'entrée dans les lieux",
          },
          dateSortie: {
            ...getDefault("dateSortie"),
            type: "string",
            format: "date",
            title: "Date de sortie des lieux",
          },
          dateCreationBail: {
            ...getDefault("dateCreationBail"),
            type: "string",
            format: "date",
            title: "Date de signature du bail",
          },
          dateDebutBail: {
            ...getDefault("dateDebutBail"),
            type: "string",
            format: "date",
            title: "Date de commencement du bail",
          },
          dateFinBail: {
            ...getDefault("dateFinBail"),
            type: "string",
            format: "date",
            title: "Date de fin du bail",
          },
          dateDebutPreavis: {
            ...getDefault("dateDebutPreavis"),
            type: "string",
            format: "date",
            title: "Date de début du préavis",
          },
          dateFinPreavis: {
            ...getDefault("dateFinPreavis"),
            type: "string",
            format: "date",
            title: "Date de fin du préavis",
          },
          dateRetourCaution: {
            ...getDefault("dateRetourCaution"),
            type: "string",
            format: "date",
            title: "Date de retour de Caution",
          },
          depotGarantie: {
            ...getDefault("depotGarantie"),
            type: "number",
            title: "Dépot de garantie (€)",
          },
          edfEntree: { ...getDefault("edfEntree"), type: "number", title: "Compteur EDF d'entrée" },
          indice: { ...getDefault("indice", defaultIndice), type: "number", title: "Indice" },
          indiceTrim: {
            ...getDefault("indiceTrim", defaultindiceTrim),
            type: "string",
            title: "Trimestre de l'indice",
          },
          jourQuitance: {
            ...getDefault("jourQuitance", defaultQuitanceDay),
            type: "number",
            title: "Jour d'emmission de la quittance (1-31)",
          },
          separator1: { title: "Frais de dossiers", type: "null", fullWidth: true },
          numFraisDossier: {
            ...getDefault("numFraisDossier"),
            type: "string",
            title: "n°facture frais de dossier",
          },
          fraisDossierTTC: { default: fraisDossierTTC, type: "number", title: "Montant TTC (€)" },
          fraisDossierTauxTVA: {
            ...getDefault("fraisDossierTauxTVA", defaultTVA),
            type: "number",
            title: "Taux TVA (%)",
          },
          fraisDossierTVA: {
            default: fraisDossierTVA,
            type: "number",
            title: "Montant TVA (€)",
            readOnly: true,
          },
          fraisDossierHT: {
            ...getDefault("fraisDossierHT"),
            type: "number",
            title: "Montant Hors Taxe (€)",
            readOnly: true,
          },
          dateFraisDossier: {
            ...getDefault("dateFraisDossier"),
            type: "string",
            format: "date",
            title: "Date de frais de dossiers",
          },
          separator2: { title: "Loyer", type: "null", fullWidth: true },
          loyerHC: {
            ...getDefault("loyerHC", appart && appart.loyerHC),
            type: "number",
            title: "Loyer HC (€)",
          },
          loyerCharge: {
            ...getDefault("loyerCharge", appart && appart.charge),
            type: "number",
            title: "Charges (€)",
          },
          loyerTTC: {
            default: loyerTTC,
            type: "string",
            title: "Loyer Chargé (€)",
            readOnly: true,
          },
        },
      },
      encadrement: OneOfGenerator(
        "Encadrement du loyer",
        "Pas d'encadrement de loyer",
        "encadrement",
        "Le loyer est encadré ?",
        dossier && dossier.encadrement,
        readonly,
        {
          surface: {
            ...getDefault("surface"),
            type: "string",
            title: "Surface (m²)",
            readOnly: true,
            default: dossier?.appartement?.surface,
          },
          nbPiece: {
            ...getDefault("nbPiece"),
            type: "string",
            title: "Nombre de pièces",
            readOnly: true,
            default: dossier?.appartement?.nbPiece,
          },
          periodeConstruction: {
            ...getDefault("periodeConstruction", "1971-1990"),
            title: "Période de construction",
            readOnly: readonly,
            enum: ["Avant 1946", "1946-1970", "1971-1990", "apres 1990"],
          },
          loyerReference: {
            ...getDefault("loyerReference"),
            readOnly: readonly,
            type: "number",
            title: "Loyer de référence (€/m²)",
          },
          loyerReferenceMajore: {
            ...getDefault("loyerReferenceMajore"),
            readOnly: readonly,
            type: "number",
            title: "Loyer de référence majoré (€/m²)",
          },
          montantBase: {
            ...getDefault("montantBase"),
            type: "string",
            title: "Montant du loyer de base (€)",
            readOnly: true,
            default: (dossier?.loyerReferenceMajore || 0) * (dossier?.appartement?.surface || 0),
          },
          complementLoyer: {
            ...getDefault("complementLoyer"),
            type: "string",
            title: "Complément de loyer (€)",
            readOnly: true,
          },
          caracteristiqueLoyer: {
            ...getDefault("caracteristiqueLoyer", defaultCarac),
            type: "string",
            title: "Caractéristique du loyer",
            readOnly: readonly,
            fullWidth: true,
          },
        },
        ["syndicNom", "conseillerSyndicNom"]
      ),
      clauses: OneOfGenerator(
        "Clauses Particulières",
        "Pas de clauses particulières",
        "clauses",
        "Y a t'il des clauses Particulières?",
        dossier && dossier.clauses,
        readonly,
        {
          clausesText: {
            ...getDefault("clausesText"),
            type: "string",
            title: "Clauses particulières",
            readOnly: readonly,
            fullWidth: true,
          },
        },
        ["syndicNom", "conseillerSyndicNom"]
      ),
    },
  }
  const uiSchema = {
    data: {
      hideTitle: ["data"],
    },
    encadrement: {
      caracteristiqueLoyer: { "ui:widget": "textarea", "ui:options": { rows: 4 } },
    },
    clauses: {
      clausesText: { "ui:widget": "textarea", "ui:options": { rows: 5 } },
    },
    Dossier: {
      related: {
        "ui:widget": "selector",
      },
    },
  }
  if (readonly) deleteEmptyFields(schema)

  return {
    schema,
    uiSchema,
    onChange: ({ formData }) => {
      const out = _.cloneDeep(formData)
      const obj = out.data
      if ((obj.loyerHC === undefined && obj.appart) || (obj.loyerHC === 0 && obj.appart))
        obj.loyerHC = parseFloat(obj.appart.loyerHC || 0)
      if ((obj.loyerCharge === undefined && obj.appart) || (obj.loyerCharge === 0 && obj.appart))
        obj.loyerCharge = parseFloat(obj.appart.charge || 0)
      if (!obj.dateFinPreavis && obj.dateFinBail && moment(obj.dateFinBail).year() > 2010)
        out.data.dateFinPreavis = obj.dateFinBail
      if (obj.dateFinPreavis && !obj.dateFinBail && moment(obj.dateFinPreavis).year() > 2010)
        out.data.dateFinBail = obj.dateFinPreavis
      console.log(out)
      obj.loyerTTC = (obj.loyerCharge || 0) + (obj.loyerHC || 0)
      obj.fraisDossierHT =
        Math.round((obj.fraisDossierTTC * 100) / (1 + obj.fraisDossierTauxTVA / 100)) / 100
      obj.fraisDossierTVA = Math.round((obj.fraisDossierTTC - obj.fraisDossierHT) * 100) / 100
      if (out.encadrement.encadrement && obj.appart) {
        out.encadrement.surface = obj.appart.surface
        out.encadrement.nbPiece = obj.appart.nbPiece
        out.encadrement.montantBase =
          (out.encadrement.loyerReferenceMajore || 0) * obj.appart.surface
        out.encadrement.complementLoyer = obj.appart.loyerHC - out.encadrement.montantBase
      }
      onChange(out)
    },
  }
}

export const locataireSchema = (locataire, readOnly, isNew) => {
  function getDefault(key, def = undefined) {
    if (isNew || !locataire) return { default: def }
    return { default: locataire[key] }
  }

  const schema = {
    type: "object",
    readOnly,
    required: ["rp"],
    properties: {
      rp: {
        ...getDefault("rp", true),
        title: "Résidence",
        type: "boolean",
        enum: [true, false],
        enumNames: ["Résidence Principale", "Résidence Secondaire"],
      },
      quitanceAuto: {
        ...getDefault("quitanceAuto"),
        type: "boolean",
        title: "Gestion automatique des quittances",
      },

      personal: {
        title: "Titulaire par defaut",
        description: "ces coordonnées seront utilisé pour tout contact avec le(s) locataire(s)",
        type: "object",
        required: readOnly
          ? []
          : ["nom", "prenom", "mail", "tel", "adresse", "codePostal", "ville"],
        properties: {
          titre: {
            ...getDefault("titre", "Monsieur"),
            title: "Titre",
            type: "string",
            enum: ["Monsieur", "Madame", "Mademoiselle"],
          },
          nom: { ...getDefault("nom"), type: "string", title: "Nom" },
          prenom: { ...getDefault("prenom"), type: "string", title: "Prénom" },
          nomJeuneFille: {
            ...getDefault("nomJeuneFille"),
            type: "string",
            title: "Nom de jeune fille",
          },
          adresse: { ...getDefault("adresse"), type: "string", title: "Adresse" },
          codePostal: { ...getDefault("codePostal"), type: "string", title: "Code Postal" },
          ville: { ...getDefault("ville"), type: "string", title: "ville" },
          naissance: {
            ...getDefault("naissance"),
            type: "string",
            format: "date",
            title: "Date de naissance",
          },
          lieuNaissance: {
            ...getDefault("lieuNaissance"),
            emptyValue: "",
            type: "string",
            title: "Lieu de naissance",
          },
          mail: { ...getDefault("mail"), type: "string", title: "Mail principal" },
          mail2: { ...getDefault("mail2"), type: "string", title: "Mail secondaire" },
          tel: { ...getDefault("tel"), type: "string", title: "Téléphone principal" },
          tel2: { ...getDefault("tel2"), type: "string", title: "Téléphone secondaire" },
        },
      },
      ...(!readOnly || locataire.autreNom
        ? {
            secondaire: OneOfGenerator(
              "Titulaire secondaire",
              "Pas de second titulaire",
              "secondTitulaire",
              "Ajouter un second titulaire ?",
              locataire && locataire.autreNom ? true : false,
              readOnly,
              {
                autreTitre: {
                  ...getDefault("autreTitre", "Monsieur"),
                  title: "Titre",
                  type: "string",
                  enum: ["Monsieur", "Madame", "Mademoiselle"],
                },
                autreNom: { ...getDefault("autreNom"), type: "string", title: "Nom" },
                autrePrenom: { ...getDefault("autrePrenom"), type: "string", title: "Prénom" },
                autreNomJeuneFille: {
                  ...getDefault("autreNomJeuneFille"),
                  type: "string",
                  title: "Nom de jeune fille",
                },
                autreNaissance: {
                  ...getDefault("autreNaissance"),
                  type: "string",
                  format: "date",
                  title: "Date de naissance",
                },
                autreLieuNaissance: {
                  ...getDefault("autreLieuNaissance"),
                  type: "string",
                  title: "Lieu de naissance",
                },
                autreMail: { ...getDefault("autreMail"), type: "string", title: "Mail principal" },
                autrMail2: { ...getDefault("autrMail2"), type: "string", title: "Mail secondaire" },
                autreTel: {
                  ...getDefault("autreTel"),
                  type: "string",
                  title: "Téléphone principal",
                },
                autreTel2: {
                  ...getDefault("autreTel2"),
                  type: "string",
                  title: "Téléphone secondaire",
                },
              },
              ["autreNom", "autrePrenom", "autreMail", "autreTel"]
            ),
          }
        : {}),

      ...(!readOnly || locataire.mineur
        ? {
            isMineur: OneOfGenerator(
              "Locataire mineur",
              "Le locataire n'est pas mineur",
              "mineur",
              "Le locataire est mineur ?",
              locataire && locataire.mineur,
              readOnly,
              {
                mineurTitre: {
                  ...getDefault("mineurTitre", "Monsieur"),
                  title: "Titre",
                  type: "string",
                  enum: ["Monsieur", "Madame", "Mademoiselle"],
                },
                mineurNom: { ...getDefault("mineurNom"), type: "string", title: "Nom" },
                mineurPrenom: { ...getDefault("mineurPrenom"), type: "string", title: "Prénom" },
                mineurNomJeuneFille: {
                  ...getDefault("mineurNomJeuneFille"),
                  type: "string",
                  title: "Nom de jeune fille",
                },
                mineurNaissance: {
                  ...getDefault("mineurNaissance"),
                  type: "string",
                  format: "date",
                  title: "Date de naissance",
                },
                mineurLieuNaissance: {
                  ...getDefault("mineurLieuNaissance"),
                  type: "string",
                  title: "Lieu de naissance",
                },
                mineurMail: {
                  ...getDefault("mineurMail"),
                  type: "string",
                  title: "Mail principal",
                },
                autrMail2: { ...getDefault("autrMail2"), type: "string", title: "Mail secondaire" },
                mineurTel: {
                  ...getDefault("mineurTel"),
                  type: "string",
                  title: "Téléphone principal",
                },
                mineurTel2: {
                  ...getDefault("mineurTel2"),
                  type: "string",
                  title: "Téléphone secondaire",
                },
              },
              ["mineurNom", "mineurPrenom", "mineurMail", "mineurTel"]
            ),
          }
        : {}),
    },
  }
  console.log("scceham", schema)
  if (readOnly) deleteEmptyFields(schema)
  const uiSchema = {
    rp: {
      "ui:widget": "radio",
    },
    secondaire: {
      spoiler: "secondTitulaire",
    },
    isMineur: {
      spoiler: "mineur",
    },
    dossier: {
      fraisDossierTVA: { "ui:readonly": true },
      fraisDossierTTC: { "ui:readonly": true },
    },
  }
  return { schema, uiSchema }
}

export const appartementSchema = (
  appartement,
  equipements,
  onCreateEquipement,
  defaultIndice,
  readOnly,
  isNew
) => {
  function getDefault(key, isDep = false, def = undefined) {
    if (isDep && (!appartement || !appartement[key])) return {}
    if (isNew || !appartement) return { default: def }
    if (def !== undefined && (!appartement || appartement[key] === undefined))
      return { default: def }
    return { default: appartement[key] }
  }
  const loyerTCC = appartement
    ? (parseInt(appartement.charge) || 0) + (parseInt(appartement.loyerHC) || 0)
    : 0
  const schema = {
    type: "object",
    readOnly,
    properties: {
      carac: {
        title: "Caractéristiques",
        type: "object",
        required: readOnly ? [] : ["adresse", "codePostal", "ville"],
        properties: {
          adresse: { ...getDefault("adresse"), type: "string", title: "Adresse" },
          codePostal: { ...getDefault("codePostal"), type: "string", title: "Code Postal" },
          ville: { ...getDefault("ville"), type: "string", title: "Ville" },
          situation: { ...getDefault("situation"), type: "string", title: "Situation du bien" },
          typeImmeuble: {
            ...getDefault("typeImmeuble", false, "collectif"),
            title: "Type d'habitation",
            enum: ["collectif", "individuel"],
            enumNames: ["Collectif", "Individuel"],
          },
          destination: {
            ...getDefault("destination", false, "usage d'habitation"),
            title: "Déstination des locaux",
            enum: ["usage d'habitation", "usage mixte (habitation et professionel)"],
            enumNames: ["Usage d'habitation", "Usage mixte (habitation et professionel)"],
          },
          numeroClef: {
            ...getDefault("numeroClef"),
            type: "number",
            title: "Numéro correspondance tableau clef",
          },
          pdl: { ...getDefault("pdl"), type: "string", title: "N° P.D.L." },
          periodeConstruction: {
            ...getDefault("periodeConstruction", false, "1971-1990"),
            title: "Epoque de construction",
            enum: ["avant 1946", "1946-1970", "1971-1990", "après 1990"],
          },
          wip: { ...getDefault("wip"), type: "boolean", title: "Travaux en cours" },
          quitanceAuto: {
            ...getDefault("quitanceAuto"),
            type: "boolean",
            title: "Gestion automatique des quittances",
          },
          separator1: { title: "", type: "null", fullWidth: true },
          detailClef: { ...getDefault("detailClef"), type: "string", title: "Detail des clefs" },
          detailTelecomande: {
            ...getDefault("detailTelecomande"),
            type: "string",
            title: "Detail des télécommandes",
          },
          digicode: { ...getDefault("digicode"), type: "string", title: "Digicode" },
          etage: { ...getDefault("etage"), type: "string", title: "Localisation du logement" },
          autresInfo: {
            ...getDefault("autresInfo"),
            type: "string",
            title: "Informations complementaires",
          },
        },
      },
      equipments: {
        type: "object",
        title: "Description",
        properties: {
          nbPiece: { ...getDefault("nbPiece"), type: "number", title: "Nombre de Pièces" },
          surface: { ...getDefault("surface"), type: "number", title: "Surface (m²)" },
          chauffage: {
            ...getDefault("chauffage", false, "collective"),
            title: "Type de chauffage",
            enum: ["collective", "individuelle"],
            enumNames: ["Collective", "Individuelle"],
          },
          eauChaude: {
            ...getDefault("eauChaude", false, "collective"),
            title: "Gestion du chauffage de l'eau",
            enum: ["collective", "individuelle"],
            enumNames: ["Collective", "Individuelle"],
          },
          separator0: { title: "Equipements individuels", type: "null", fullWidth: true },
          ...(equipements || [])
            .filter(eq => !eq.collectif)
            .reduce((out, equi) => {
              out[equi.nom] = {
                ...getDefault(
                  equi.nom,
                  false,
                  ((appartement || {}).Equipements || []).some(eq => eq.nom === equi.nom)
                    ? true
                    : undefined
                ),
                type: "boolean",
                title: equi.nom,
              }
              return out
            }, {}),
          cave: OneOfGenerator(
            "",
            "Pas de cave",
            "cave",
            "Cave",
            appartement && appartement.caveNum !== null ? true : false,
            readOnly,
            {
              caveNum: { ...getDefault("caveNum"), type: "string", title: "Numéro de cave" },
            }
          ),
          addEquipementIndividuel: {
            type: "string",
            title: "Ajouter un équipement individuel",
            onCreate: v => onCreateEquipement(v, false),
          },
          separator1: { title: "Equipements collectifs", type: "null", fullWidth: true },
          ...(equipements || [])
            .filter(eq => eq.collectif)
            .reduce((out, equi) => {
              out[equi.nom] = {
                ...getDefault(
                  equi.nom,
                  false,
                  ((appartement || {}).Equipements || []).some(eq => eq.nom === equi.nom)
                    ? true
                    : undefined
                ),
                type: "boolean",
                title: equi.nom,
              }
              return out
            }, {}),
          addEquipementCollectif: {
            type: "string",
            title: "Ajouter un équipement collectif",
            onCreate: v => onCreateEquipement(v, true),
          },
          elementEquipement: {
            ...getDefault("elementEquipement"),
            type: "string",
            fullWidth: true,
            title: "Autres équipements",
          },
          elementEquipementImportant: {
            ...getDefault("elementEquipementImportant"),
            type: "string",
            fullWidth: true,
            title: "Autres équipements importants (affichés en rouge)",
          },
        },
      },
      loyer: {
        type: "object",
        title: "Loyer",
        properties: {
          loyerHC: { ...getDefault("loyerHC"), type: "number", title: "Loyer Hors Charge (€)" },
          charge: { ...getDefault("charge"), type: "number", title: "Charges (€)" },
          loyerTCC: { default: loyerTCC, type: "string", title: "Loyer Charges comprises" },
        },
      },
      copro: OneOfGenerator(
        "Copropriété",
        "Pas de copropriété",
        "copropriete",
        "Présence d'une copropriété ?",
        appartement && appartement.copropriete,
        readOnly,
        {
          syndicNom: { ...getDefault("syndicNom"), type: "string", title: "Nom du syndic" },
          syndicMail: { ...getDefault("syndicMail"), type: "string", title: "Mail du syndic" },
          syndicTel: { ...getDefault("syndicTel"), type: "string", title: "Téléphone du syndic" },
          gestionnaireSyndicNom: {
            ...getDefault("gestionnaireSyndicNom"),
            type: "string",
            title: "Nom du gestionnaire de l’immeuble",
          },
          gestionnaireSyndicTel: {
            ...getDefault("gestionnaireSyndicTel"),
            type: "string",
            title: "Téléphone du gestionnaire",
          },
          gestionnaireSyndicMail: {
            ...getDefault("gestionnaireSyndicMail"),
            type: "string",
            title: "Mail du gestionnaire",
          },
          conseillerSyndical: {
            ...getDefault("conseillerSyndical"),
            type: "string",
            title: "Conseil syndical",
          },
          autreSyndic: {
            ...getDefault("autreSyndic"),
            type: "string",
            title: "Informations complémentaires",
          },
        },
        ["syndicNom", "conseillerSyndicNom"]
      ),
      gardien: OneOfGenerator(
        "Gardiennage",
        "Pas de gardiennage",
        "gardiennage",
        "Présence d'un gardiennage ?",
        appartement && appartement.gardiennage,
        readOnly,
        {
          gardienNom: {
            ...getDefault("gardienNom", true),
            type: "string",
            title: "Nom du gardien",
          },
          gardienTel: {
            ...getDefault("gardienTel", true),
            type: "string",
            title: "Téléphone du gardien",
          },
        },
        ["gardienNom"]
      ),
      Images: {
        type: "object",
        title: "Images",
        properties: [],
      },
    },
  }
  if (readOnly) deleteEmptyFields(schema, ["cave"])
  const uiSchema = {
    loyer: {
      loyerTCC: { "ui:readonly": true },
    },
    carac: {
      etage: { "ui:widget": "textarea", "ui:options": { rows: 4 } },
      digicode: { "ui:widget": "textarea", "ui:options": { rows: 4 } },
      autresInfo: { "ui:widget": "textarea", "ui:options": { rows: 4 } },
      detailTelecomande: { "ui:widget": "textarea", "ui:options": { rows: 4 } },
      detailClef: { "ui:widget": "textarea", "ui:options": { rows: 4 } },
    },
    equipments: {
      elementEquipement: { "ui:widget": "textarea", "ui:options": { rows: 5 } },
      elementEquipementImportant: { "ui:widget": "textarea", "ui:options": { rows: 5 } },
      addEquipementIndividuel: { "ui:widget": "CreateEquipement" },
      addEquipementCollectif: { "ui:widget": "CreateEquipement" },
    },
    copro: {
      conseillerSyndical: { "ui:widget": "textarea", "ui:options": { rows: 2 } },
      autreSyndic: { "ui:widget": "textarea", "ui:options": { rows: 2 } },
    },
  }
  console.log("appart schema", defaultIndice, schema)
  return { schema, uiSchema }
}

export const missionSchema = (mission, readOnly, isNew, defaultTVA, onChange) => {
  function getDefault(key, def = undefined) {
    if (def && (!mission || !mission[key])) return { default: def }
    if (isNew || !mission) return { default: undefined }
    return { default: mission[key] }
  }

  const valeurTotalMission = mission
    ? (parseInt(mission.nbAppart) || 0) * (Math.round(mission.valeurMissionAppt * 100) / 100 || 0)
    : 0
  console.log("DEFAULT TVA", defaultTVA)
  const schema = {
    type: "object",
    properties: {
      data: {
        type: "object",
        title: "data",
        readOnly,
        required: [
          "type",
          "nbAppart",
          "accompte1",
          "accompte2",
          "reliquat",
          "valeurMissionAppt",
          "valeurTotalMission",
        ],
        properties: {
          domicileUnique: {
            ...getDefault("domicileUnique"),
            type: "boolean",
            title: "Domicile Unique",
          },
          nbAppart: { ...getDefault("nbAppart"), type: "number", title: "Nombre d'appartement" },
          dateSignature: {
            ...getDefault("dateSignature"),
            type: "string",
            format: "date",
            title: "Date de signature",
          },
          tauxTva: {
            ...getDefault("tauxTva", defaultTVA),
            type: "number",
            title: "Taux de T.V.A (%)",
          },
          valeurMissionAppt: {
            ...getDefault("valeurMissionAppt"),
            type: "number",
            title: "Valeur Mission/Appartement TTC",
          },
          valeurTotalMission: {
            default: valeurTotalMission,
            readOnly: true,
            type: "string",
            title: "Valeur Totale de la mission TTC",
          },
        },
      },
    },
  }
  return {
    schema,
    uiSchema: { data: { hideTitle: ["data"] } },
    onChange: ({ formData }) => {
      const obj = _.cloneDeep(formData.data)
      obj.valeurTotalMission =
        (parseInt(obj.nbAppart) || 0) * (Math.round(obj.valeurMissionAppt * 100) / 100 || 0)
      onChange({ data: obj })
    },
  }
}

export const clientSchema = (client, clients, readOnly, isNew) => {
  function getDefault(key, def = undefined) {
    if (def && (!client || !client[key])) return { default: def }
    if (isNew || !client) return { default: undefined }
    return { default: client[key] }
  }
  const schema = {
    type: "object",
    readOnly,
    properties: {
      personal: {
        title: "Données personelles",
        type: "object",
        required: readOnly ? [] : ["nom", "prenom"],
        properties: {
          nom: { ...getDefault("nom"), type: "string", title: "Nom" },
          prenom: { ...getDefault("prenom"), type: "string", title: "Prénom" },
          nomJeuneFille: {
            ...getDefault("nomJeuneFille"),
            type: "string",
            title: "Nom de jeune fille",
          },
          titre: {
            ...getDefault("titre", "Monsieur"),
            title: "Titre",
            type: "string",
            enum: ["Monsieur", "Madame", "Mademoiselle"],
          },
          sexe: {
            ...getDefault("sexe", "Non définit"),
            title: "sexe",
            enum: ["Non définit", "Male", "Femelle"],
            enumNames: ["Non définit", "Homme", "Femme"],
          },
          lieuNaissance: {
            ...getDefault("lieuNaissance"),
            type: "string",
            title: "Lieu de naissance",
          },
          cpNaissance: {
            ...getDefault("cpNaissance"),
            type: "string",
            title: "Code postal de naissance",
          },
          naissance: {
            ...getDefault("naissance"),
            type: "string",
            format: "date",
            title: "Date de naissance",
          },
          numEdf: { ...getDefault("numEdf"), type: "string", title: "Numéro client EDF" },
          numEnedis: { ...getDefault("numEnedis"), type: "string", title: "Numéro P.D.L. ENEDIS" },
        },
      },
      coordonates: {
        type: "object",
        title: "Coordonnées",
        required: ["telDefaut", "mailDefaut", "adresseDefaut"],
        properties: {
          adresse: { ...getDefault("adresse"), type: "string", title: "Adresse personelle" },
          codePostal: { ...getDefault("codePostal"), type: "string", title: "Code Postal" },
          ville: { ...getDefault("ville"), type: "string", title: "Ville" },
          separator5: { title: "", type: "null", fullWidth: true },
          adresseFiscale: {
            ...getDefault("adresseFiscale"),
            type: "string",
            title: "Adresse fiscale",
          },
          codePostalFiscale: {
            ...getDefault("codePostalFiscale"),
            type: "string",
            title: "Code Postal fiscal",
          },
          villeFiscale: { ...getDefault("villeFiscale"), type: "string", title: "Ville fiscale" },
          separator6: { title: "", type: "null", fullWidth: true },
          adresseDefaut: {
            ...getDefault("adresseDefaut", "adresse"),
            title: "Adresse de contact",
            enumNames: ["Personnelle", "Fiscale"],
            enum: ["adresse", "adresseFiscale"],
          },
          separator0: { title: "Mail", type: "null", fullWidth: true },
          mailPerso: { ...getDefault("mailPerso"), type: "string", title: "mail personnel" },
          mailPro: { ...getDefault("mailPro"), type: "string", title: "mail professionnel" },
          mailDefaut: {
            ...getDefault("mailDefaut", "mailPerso"),
            title: "Mail de contact",
            enumNames: ["Personnel", "Professionnel"],
            enum: ["mailPerso", "mailPro"],
          },
          separator1: { title: "Téléphone", type: "null", fullWidth: true },
          fixePerso: {
            ...getDefault("fixePerso"),
            type: "string",
            title: "Téléphone fixe personnel",
          },
          fixePro: {
            ...getDefault("fixePro"),
            type: "string",
            title: "Téléphone fixe professionnel",
          },
          mobilePerso: { ...getDefault("mobilePerso"), type: "string", title: "Mobile personnel" },
          mobilePro: { ...getDefault("mobilePro"), type: "string", title: "Mobile professionnel" },
          telDefaut: {
            ...getDefault("telDefaut", "mobilePerso"),
            title: "Téléphone de contact",
            enumNames: [
              "Mobile Personnel",
              "Mobile Professionnel",
              "Fixe Personnel",
              "Fixe Professionnel",
            ],
            enum: ["mobilePerso", "mobilePro", "fixePerso", "fixePro"],
          },
        },
      },
      poi: {
        type: "object",
        title: "LMNP",
        required: ["adresse"],
        properties: {
          creationPOI: {
            ...getDefault("creationPOI"),
            type: "string",
            format: "date",
            title: "POI crée le",
          },
          dateDepotPOI: {
            ...getDefault("dateDepotPOI"),
            type: "string",
            format: "date",
            title: "POI déposé le",
          },
          numSiret: { ...getDefault("numSiret"), type: "string", title: "Numéro SIRET" },
        },
      },
      mariageField: {
        type: "object",
        title: "Mariage / Pacse",
        properties: {
          mariage: {
            fullWidth: true,
            default: client && client.mariage ? client.mariage : undefined,
            title: "Marié/Pacsé avec",
            type: "string",
            format: "selector",
            readOnly,
            noTxt: "Aucun client sélectionné",
            selectorTitle: "Lier un client",
            displayValue: app => app.nom + " " + app.prenom,
            choices: (clients || []).filter(c => c.id !== (client || {}).id),
            columns: [{ title: "Nom", field: "nom" }, { title: "Prénom", field: "prenom" }],
            selectTxt: "lier au client",
          },
        },
      },
    },
  }
  const uiSchema = {
    mariageField: {
      mariage: {
        "ui:widget": "selector",
      },
    },
  }
  if (readOnly) deleteEmptyFields(schema, ["mariage"])
  return { schema, uiSchema }
}

export const accompteSchema = (accompte, appartements, readOnly, isNew) => {
  function getDefault(key, def = undefined) {
    if (isNew || !accompte) return { default: def }
    return { default: accompte[key] }
  }

  const schema = {
    type: "object",
    readOnly,
    properties: {
      data: {
        title: "data",
        type: "object",
        required: readOnly ? [] : ["intitule", "montant", "numFacture"],
        properties: {
          intitule: { ...getDefault("intitule"), type: "string", title: "Intitulé" },
          date: { ...getDefault("date"), type: "string", format: "date", title: "Date" },
          montant: { ...getDefault("montant"), type: "number", title: "Montant TTC (€)" },
          numFacture: { ...getDefault("numFacture"), type: "string", title: "Numéro de facture" },
          mode: {
            ...getDefault("mode", "Virement"),
            title: "Mode de reglement",
            enum: ["Cheque", "Virement"],
          },
          regle: { ...getDefault("regle"), type: "boolean", title: "Reglemement effectué" },
        },
      },
    },
  }
  if (readOnly) deleteEmptyFields(schema)

  return { schema, uiSchema: { data: { hideTitle: ["data"] } } }
}

export const intendanceSchema = (
  intendance,
  onChange,
  readOnly,
  isNew,
  appartements,
  defaultTVA
) => {
  function getDefault(key, def = undefined) {
    if (isNew || !intendance) return { default: def }
    return { default: intendance[key] }
  }

  const schema = {
    type: "object",
    readOnly,
    properties: {
      data: {
        title: "data",
        type: "object",
        required: ["dateDepart", "dateFin", "prix", "tva"],
        properties: {
          dateDepart: {
            ...getDefault("dateDepart"),
            type: "string",
            format: "date",
            title: "Date de début",
          },
          dateFin: {
            ...getDefault("dateFin"),
            type: "string",
            format: "date",
            title: "Date d'écheance",
          },
          separator1: { title: "", type: "null", fullWidth: true },
          prix: { ...getDefault("prix"), type: "string", title: "Prix TTC (€)" },
          tva: { ...getDefault("tva", defaultTVA), type: "number", title: "T.V.A. (%)" },
          prixHT: { type: "number", title: "Prix HT (€)", readOnly: true },
          mode: {
            ...getDefault("mode", "Virement"),
            title: "Mode de reglement",
            enum: ["Cheque", "Virement"],
          },
          datePaiement: {
            ...getDefault("datePaiement"),
            type: "string",
            format: "date",
            title: "Date de paiement",
          },
          regle: { ...getDefault("regle"), type: "boolean", title: "Reglemement effectué" },
        },
      },
      ...(appartements
        ? {
            appart: {
              type: "object",
              title: "Appartement concerné",
              required: readOnly ? [] : ["appart"],
              properties: {
                appart: {
                  fullWidth: true,
                  default: intendance && intendance.Appartement,
                  title: "Appartement concerné",
                  type: "string",
                  format: "selector",
                  readOnly,
                  noTxt: "Aucun appartement sélectionné",
                  selectorTitle: "Lier un client",
                  displayValue: app => app.adresse,
                  choices: appartements || [],
                  columns: appartementColumns,
                  selectTxt: "lier a la mission",
                },
              },
            },
          }
        : {}),
    },
  }
  if (readOnly) deleteEmptyFields(schema)

  return {
    schema,
    uiSchema: { data: { hideTitle: ["data"] } },
    onChange: ({ formData }) => {
      const obj = _.cloneDeep(formData.data)
      const TTC = parseInt(obj.prix) || 0
      const TTVA = parseInt(obj.tva) || 0
      obj.prixHT = Math.round((TTC * 100) / (1 + TTVA / 100)) / 100
      onChange({ data: obj, appart: formData.appart })
    },
  }
}

export const cautionSchema = (caution, readOnly, isNew) => {
  function getDefault(key, def = undefined) {
    if (isNew || !caution) return { default: def }
    return { default: caution[key] }
  }

  const schema = {
    type: "object",
    readOnly,
    properties: {
      data: {
        title: "data",
        type: "object",
        required: readOnly ? [] : ["nom", "prenom", "mobile", "mail"],
        properties: {
          nom: { ...getDefault("nom"), type: "string", title: "Nom" },
          prenom: { ...getDefault("prenom"), type: "string", title: "Prénom" },
          titre: {
            ...getDefault("titre", "Monsieur"),
            title: "Titre",
            type: "string",
            enum: ["Monsieur", "Madame", "Mademoiselle"],
          },
          lieuNaissance: {
            ...getDefault("lieuNaissance"),
            type: "string",
            title: "Lieu de naissance",
          },
          naissance: {
            ...getDefault("naissance"),
            type: "string",
            format: "date",
            title: "Date de naissance",
          },
          adresse: { ...getDefault("adresse"), type: "string", title: "Adresse" },
          ville: { ...getDefault("ville"), type: "string", title: "Ville" },
          cp: { ...getDefault("cp"), type: "string", title: "Code postal" },
          mail: { ...getDefault("mail"), type: "string", title: "Mail" },
          mobile: { ...getDefault("mobile"), type: "string", title: "Mobile" },
          separator0: { title: "Caution solidaire", type: "null", fullWidth: true },
          dateCautionSolidaire: {
            ...getDefault("dateCautionSolidaire"),
            type: "string",
            format: "date",
            title: "Date de caution solidaire",
          },
          retourCautionSolidaire: {
            ...getDefault("retourCautionSolidaire"),
            type: "boolean",
            title: "Retournée",
          },
          signatureCautionSolidaire: {
            ...getDefault("signatureCautionSolidaire"),
            type: "boolean",
            title: "Signée",
          },
          complement: {
            ...getDefault("complement"),
            type: "string",
            title: "Information complémentaires",
            fullWidth: true,
          },
        },
      },
    },
  }
  if (readOnly) deleteEmptyFields(schema)

  return { schema, uiSchema: { data: { hideTitle: ["data"] } } }
}

export const etatLieuxSchema = (etat, isNew, readOnly) => {
  function getDefault(key, def = undefined) {
    if (isNew || !etat) return { default: def }
    return { default: etat[key] }
  }

  const schema = {
    type: "object",
    readOnly,
    properties: {
      data: {
        title: "data",
        type: "object",
        required: readOnly
          ? []
          : [
              "lieu",
              "date",
              "clef",
              "eauFroide",
              "eauChaude",
              "chauffage",
              "antenne",
              "amenagement",
            ],
        properties: {
          interphone: { ...getDefault("interphone"), type: "boolean", title: "Interphone" },
          doubleVitrage: {
            ...getDefault("doubleVitrage"),
            type: "boolean",
            title: "Double vitrage",
          },
          eauFroide: { ...getDefault("eauFroide"), type: "string", title: "Eau froide" },
          eauChaude: { ...getDefault("eauChaude"), type: "string", title: "Eau chaude" },
          chauffage: { ...getDefault("chauffage"), type: "string", title: "Chauffage" },
          antenne: {
            ...getDefault("antenne"),
            type: "string",
            title: "Antenne ou moyen de réception T.V.",
            fullWidth: true,
          },
          amenagement: {
            ...getDefault("amenagement"),
            type: "string",
            title: "Aménagements",
            fullWidth: true,
          },
          clef: {
            ...getDefault("clef"),
            type: "string",
            title: "Clés et moyens d’accès",
            fullWidth: true,
          },
          lieu: { ...getDefault("lieu"), type: "string", title: "Réalisé à" },
          date: {
            ...getDefault("date"),
            type: "string",
            format: "date",
            title: "Date de réalisation",
          },
        },
      },
    },
  }
  if (readOnly) deleteEmptyFields(schema)

  return {
    schema,
    uiSchema: {
      data: {
        hideTitle: ["data"],
        clef: { "ui:widget": "textarea", "ui:options": { rows: 4 } },
        amenagement: { "ui:widget": "textarea", "ui:options": { rows: 4 } },
      },
    },
  }
}

export const fichierSchema = (fichier, onChange, isNew, readOnly) => {
  function getDefault(key, def = undefined) {
    if (isNew || !fichier) return { default: def }
    return { default: fichier[key] }
  }

  const schema = {
    type: "object",
    readOnly,
    properties: {
      data: {
        title: "data",
        type: "object",
        required: readOnly ? [] : ["nom", "url"],
        properties: {
          nom: { ...getDefault("nom"), type: "string", title: "Nom du fichier" },
          extention: {
            ...getDefault("extention"),
            type: "string",
            title: "type de fichier",
            readOnly: true,
          },
          url: { ...getDefault("nom"), type: "string", title: "fichier", format: "data-url" },
        },
      },
    },
  }
  if (readOnly) deleteEmptyFields(schema)

  return {
    schema,
    onChange: ({ formData }) => {
      console.log("on change", formData)
      const obj = _.cloneDeep(formData.data)
      if (obj.url) {
        if (!obj.nom)
          obj.nom = obj.url.name
            .split(".")
            .slice(0, -1)
            .join(".")
        obj.extention = obj.url.name.split(".").pop()
      }
      console.log("on change out", obj)
      onChange({ data: obj })
    },
    uiSchema: {
      data: {
        hideTitle: ["data"],
        url: { "ui:widget": "file" },
        amenagement: { "ui:widget": "textarea", "ui:options": { rows: 4 } },
      },
    },
  }
}

export const userSchema = (user, isNew, readOnly) => {
  function getDefault(key, def = undefined) {
    if (isNew || !user) return { default: def }
    return { default: user[key] }
  }

  const schema = {
    type: "object",
    readOnly,
    properties: {
      data: {
        title: "data",
        type: "object",
        required: readOnly ? [] : ["email", "role"],
        properties: {
          email: { ...getDefault("email"), type: "string", title: "Email" },
          role: {
            ...getDefault("role", "user"),
            title: "Role",
            enum: ["user", "user_delete"],
            enumNames: ["Ajout / Edition", "Ajout / Edition / Suppression"],
          },
          password: {
            default: "patrimoinelocatif",
            type: "string",
            title: "Mot de passe",
            readOnly: true,
            description:
              "l'utilisateur sera invité à changer son mot de passe à sa première connexion",
          },
        },
      },
    },
  }
  if (readOnly) deleteEmptyFields(schema)

  return {
    schema,
    uiSchema: {
      data: {
        hideTitle: ["data"],
      },
    },
  }
}

export const quitanceSchema = (quitance, readOnly, isNew, onChange) => {
  function getDefault(key, def = undefined) {
    if (isNew || !quitance) return { default: def }
    return { default: quitance[key] }
  }

  const schema = {
    type: "object",
    readOnly,
    properties: {
      data: {
        title: "data",
        type: "object",
        required: ["date", "dateFin", "prixHC", "charge"],
        properties: {
          date: { ...getDefault("date"), type: "string", format: "date", title: "Date de début" },
          dateFin: {
            ...getDefault("dateFin"),
            type: "string",
            format: "date",
            title: "Date de fin",
          },
          prixHC: { ...getDefault("prixHC"), type: "number", title: "Loyer Hors Charge" },
          charge: { ...getDefault("charge"), type: "number", title: "Charges" },
        },
      },
    },
  }
  if (readOnly) deleteEmptyFields(schema)

  return {
    schema,
    onChange: ({ formData }) => onChange?.(formData),
    uiSchema: { data: { hideTitle: ["data"] } },
  }
}
