import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"

// material-ui components
import { makeStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
// core components
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"

import styles from "assets/jss/material-dashboard-react/components/customTabsStyle.js"
import { Icon, IconButton } from "@material-ui/core"

const useStyles = makeStyles(styles)

export default function CustomTabs(props) {
  const [value, setValue] = React.useState(parseInt(props.initTab) || 0)
  const handleChange = (event, value) => {
    setValue(value)
  }
  const classes = useStyles()
  const { headerColor, plainTabs, tabs, title, rtlActive, rigthBtn } = props
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  })
  return (
    <Card plain={plainTabs}>
      <CardHeader color={headerColor} plain={plainTabs}>
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone,
            scrollButtons: classes.displayNone,
          }}
          variant='scrollable'
          scrollButtons='auto'>
          {tabs.map((prop, key) => {
            var icon = {}
            if (prop.tabIcon) {
              icon = {
                icon: typeof prop.tabIcon === "string" ? <Icon>{prop.tabIcon}</Icon> : <prop.tabIcon />,
              }
            }
            return (
              <Tab
                classes={{
                  root: classes.tabRootButton,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper,
                }}
                key={key}
                label={prop.tabName}
                {...icon}
              />
            )
          })}
        </Tabs>
        {rigthBtn && rigthBtn.display && (
          <div style={{ display: "flex", alignItems: "center", float: "right" }}>
            {rigthBtn.text && <h6 style={{ margin: 0 }}>{rigthBtn.text}</h6>}
            <IconButton onClick={rigthBtn.onClick}>
              <Icon style={{ color: "white" }}>{rigthBtn.icon}</Icon>
            </IconButton>
          </div>
        )}
      </CardHeader>
      <CardBody>
        {tabs.map((prop, key) => {
          if (key === value) {
            return <div key={key}>{prop.tabContent}</div>
          }
          return null
        })}
      </CardBody>
    </Card>
  )
}

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf(["warning", "success", "danger", "info", "primary", "rose"]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired,
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
}
