import { Icon } from "@material-ui/core"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import { withStyles } from "@material-ui/core/styles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import "assets/css/form-auto.css"
import { } from "assets/jss/material-dashboard-react.js"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js"
import AssociationList from "components/AssociationList"
import Card from "components/Card/Card.js"
import CardFooter from "components/Card/CardFooter.js"
import CardHeader from "components/Card/CardHeader.js"
import CardIcon from "components/Card/CardIcon.js"
import CustomTabs from "components/CustomTabs/CustomTabs"
import Form from "components/MuiJsonForm"
import RowGenerateFile from "components/RowGenerateFile"
import PubSub from "pubsub-js"
import queryString from "query-string"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import Actions from "redux/actions"
import reducer from "redux/reducers"
import withReducer from "redux/withReducer"
import { accompteColumns, appartementColumns, clientColumns } from "utils/columns"
import fileDialog from "utils/fileDialog"
import { accompteSchema, missionSchema } from "utils/schemas"
var dispatch

class FormEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, formData: {}, initLoad: true }
    dispatch = this.props.dispatch
    this.isNew = props.match.params.id === "new"
    this.edit = props.match.params.edit ? true : false
    this.readOnly = !this.isNew && !this.edit
  }

  componentDidMount() {
    let actions = []
    if (!this.state.initLoad) this.setState({ loading: true, formData: {}, initLoad: true })
    actions.push(dispatch(Actions.getMission(this.props.match.params.id)))
    if (!this.isNew) dispatch(Actions.getClients())
    else actions.push(dispatch(Actions.getMission()))
    actions.push(dispatch(Actions.getAppartements()))
    actions.push(dispatch(Actions.getVariables()))
    Promise.all(actions).then(_ => this.setState({ loading: false, initLoad: false }))
  }

  componentDidUpdate(prevProps, prevState) {
    this.isNew = this.props.match.params.id === "new"
    this.edit = this.props.match.params.edit ? true : false
    this.readOnly = !this.isNew && !this.edit
    if (this.props.match.params !== prevProps.match.params) {
      this.componentDidMount()
    }
  }

  onSubmit = form => {
    if (this.readOnly) return
    console.log("validate", form)
    const body = { ...form.formData.data }
    console.log("VALIDATE", body)
    this.setState({ loading: true })
      ; (this.isNew ? dispatch(Actions.createMission(body)) : dispatch(Actions.setMission(this.props.match.params.id, body))).then(rep => {
        PubSub.notif({
          txt: this.isNew ? "Votre mission à été crée avec succes." : "Vos modifications ont été prises en compte.",
          color: "success",
          icon: "save",
        })
        this.setState({ loading: false })
        if (this.isNew) this.props.history.push(queryString.parse(this.props.location.search).oncreate || `/missions/${rep.payload.id}`)
      })
  }

  render() {
    const c = this.props.classes
    const mission = this.props.mission || {}
    console.log("Mission", mission, this.props.variables)
    let rest = mission.valeurTotalMission || 0
    console.log("rest", rest)
    if (mission && mission.Accomptes) {
      mission.Accomptes.filter(a => a.regle).forEach(a => (rest -= a.montant))
    }
    return (
      <div className={c.container}>
        {this.readOnly && (
          <div className='justify-b'>
            <Card style={{ width: 220 }}>
              <CardHeader color='warning' stats icon>
                <CardIcon color='warning'>
                  <Icon>home</Icon>
                </CardIcon>
                <p className={c.cardCategory}>Appartements</p>
                <h3 className={c.cardTitle}>{mission.nbAppart}</h3>
              </CardHeader>
              <CardFooter stats />
            </Card>
            <Card style={{ width: 240 }}>
              <CardHeader color='success' stats icon>
                <CardIcon color='success'>
                  <Icon>euro</Icon>
                </CardIcon>
                <p className={c.cardCategory}>Valeur Totale TTC</p>
                <h3 className={c.cardTitle}>{(mission.valeurTotalMission || 0) + "€"}</h3>
              </CardHeader>
              <CardFooter stats style={{ marginTop: 0 }}>
                <div className={c.stats + " fullW  align-c justify-b"}>
                  <p className='no-margin'>Valeur Totale HT</p>
                  <h5 className={c.cardTitle}>{Math.round((mission.valeurTotalMission * 100) / (1 + (mission.tauxTva || 0) / 100)) / 100 + "€"}</h5>
                </div>
              </CardFooter>
              <CardFooter stats style={{ margin: 0 }}></CardFooter>
            </Card>
            <Card style={{ width: 220 }}>
              <CardHeader color='danger' stats icon>
                <CardIcon color='danger'>
                  <Icon>account_balance_wallet</Icon>
                </CardIcon>
                <p className={c.cardCategory}>Reste à payer TTC</p>
                <h3 className={c.cardTitle}>{rest + "€"}</h3>
              </CardHeader>
              <CardFooter stats />
            </Card>
          </div>
        )}
        <CustomTabs
          initTab={queryString.parse(this.props.location.search).tab}
          title={this.state.loading ? "" : (mission ? "" : "Nouvelle Mission").toUpperCase()}
          headerColor='info'
          rigthBtn={!this.isNew && { display: !this.edit, icon: "create", onClick: _ => this.props.history.push(this.props.match.url + "/edit") }}
          tabs={[
            {
              tabName: "Details",
              tabIcon: "work_outline",
              tabContent: (
                <>
                  <Form
                    {...missionSchema(
                      this.props.mission,
                      this.readOnly,
                      this.isNew,
                      this.props.variables && parseInt(this.props.variables.tva.valeur),
                      formData => this.setState({ formData })
                    )}
                    formData={this.state.formData}
                    initLoad={this.state.initLoad}
                    loading={this.state.loading}
                    readOnly={this.readOnly}
                    onSubmit={this.onSubmit}
                    onError={console.log}
                    removeEditOnSubmit
                  />
                </>
              ),
            },
            {
              tabName: "Commanditaires",
              tabIcon: "assignment_ind",
              tabContent: (
                <AssociationList
                  data={(this.props.mission || {}).Clients}
                  columns={clientColumns}
                  noDataTxt='Aucun client assigné.'
                  detailTxt='Voir les details du client'
                  onDetails={o => this.props.history.push(`/client/${o.id}`)}
                  removeTxt='Retirer le client'
                  onRemove={clientId => dispatch(Actions.removeMissionCustomer(this.props.mission.id, clientId))}
                  onAdd={clientId => dispatch(Actions.addMissionCustomer(this.props.mission.id, clientId))}
                  addTxt='Sélectionner un client'
                  addData={this.props.clients}
                  addColumn={clientColumns}
                  createTxt='Créer un client'
                  onCreate={_ => this.props.history.push(`/client/new?oncreate=${window.location.pathname}?tab=1`)}
                />
              ),
            },
            {
              tabName: "Appartement",
              tabIcon: "home",
              tabContent: (
                <AssociationList
                  data={(this.props.mission || {}).Appartements}
                  columns={appartementColumns}
                  noDataTxt='Aucun appartement lié.'
                  detailTxt="Voir les details de l'appartement"
                  onDetails={o => this.props.history.push(`/appartements/${o.id}`)}
                  hideHeader={true}
                  removeTxt="Retirer l'appartement"
                  onRemove={appartId => dispatch(Actions.removeMissionAppartement(this.props.mission.id, appartId))}
                  onAdd={appartId => dispatch(Actions.addMissionAppartement(this.props.mission.id, appartId))}
                  addTxt='Sélectionner un appartement'
                  addData={this.props.appartements}
                  addColumn={appartementColumns}
                  createTxt='Créer un appartement'
                  onCreate={_ => this.props.history.push(`/appartements/new?oncreate=${window.location.pathname}?tab=2`)}
                />
              ),
            },
            {
              tabName: "Paiements",
              tabIcon: "account_balance",
              tabContent: (
                <AssociationList
                  title={`Paiement${((this.props.mission || {}).Accomptes || []).length > 1 ? "s" : ""}`}
                  data={(this.props.mission || {}).Accomptes || []}
                  columns={accompteColumns}
                  detailPanel={rd => (
                    <div style={{ background: "#EEE", padding: 10 }}>
                      <Form
                        {...accompteSchema(rd, this.props.appartements, false, false, state => this.setState({ ["FormData_" + rd.id]: state }))}
                        formData={this.state["formData_" + rd.id]}
                        onSubmit={({ formData: { data } }) =>
                          dispatch(Actions.setAccompte(rd.id, data)).then(_ => dispatch(Actions.getMission(this.props.match.params.id)))
                        }
                        onError={console.log}
                      />
                    </div>
                  )}
                  onTopRightButton={close => (
                    <Form
                      style={{ width: "100%" }}
                      {...accompteSchema({}, this.props.appartements, false, true, state => this.setState({ formData_new: state }))}
                      formData={this.state["formData_new"]}
                      onSubmit={({ formData: { data } }) => {
                        dispatch(Actions.createAccompte(data)).then(rep =>
                          dispatch(Actions.addMissionAccompte(this.props.match.params.id, rep.payload.id)).then(close)
                        )
                      }}
                      onError={console.log}
                    />
                  )}
                  topRightButtonTxt='Nouveau paiement'
                  detailTxt="Voir l'appartement concerné"
                  noDataTxt='Aucun paiement.'
                  removeTxt='Supprimer le paiement'
                  onRemove={accompteId => dispatch(Actions.removeMissionAccompte(this.props.match.params.id, accompteId))}
                />
              ),
            },
            {
              tabName: "Documents",
              tabIcon: "description",
              tabContent: (
                <>
                  <RowGenerateFile
                    title='Ordre de mission'
                    generateUrl={`Mission/${mission.id}/ordreMission/generate`}
                    generateName={`Mission_non_signée.docx`}
                    uploaded={mission.ordreMission}
                    uploadedName={`Mission_signée.docx`}
                    onUpload={_ => fileDialog("ordreMission").then(fd => dispatch(Actions.setMission(mission.id, fd)))}
                  />
                  {(mission.Clients || []).some(p => p.Fichiers?.length) && (
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Client</h4>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className='col'>
                        {(mission.Clients || []).map(prop =>
                          prop.Fichiers.map(file => (
                            <RowGenerateFile
                              key={file.id}
                              title={`${prop.nom} ${prop.prenom}_${file.nom}`}
                              uploaded={file.url}
                              uploadedName={file.nom + "." + file.extention}
                            />
                          ))
                        )}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )}
                </>
              ),
            },
          ].filter(t => !this.isNew || t.tabName === "Details")}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  mission: state.reducer.mission,
  clients: state.reducer.clients,
  variables: state.reducer.variables,
  appartements: state.reducer.appartements,
})
export default withRouter(withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(FormEditor))))
