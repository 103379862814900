import { Icon, IconButton, Paper } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { } from "assets/jss/material-dashboard-react.js"
import axios from "axios.js"
import MaterialTable from "components/MaterialTable.js"
import moment from "moment"
import "moment/locale/fr"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import Actions from "redux/actions"
import reducer from "redux/reducers"
import withReducer from "redux/withReducer"
import { appartementColumns, dossierClientColumns } from "utils/columns"
import downloadFile from "utils/downloadFile"

moment.locale("fr")
var dispatch


class FormList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      load: true,
      quitances: [],
      generate: false,
      isGenerating: false,
      date: moment(),
      dialog: false,
      auto: "all",
      checked: [],
    }
    dispatch = this.props.dispatch
  }

  getQuitances = () => {
    const { date } = this.state
    this.setState({ load: true })
    return axios.get(`Quitance/byYear/${date.year()}`).then(({ data }) => this.setState({ quitances: data, load: false }))
  }
  componentDidMount() {
    this.getQuitances()
    dispatch(Actions.getLocataires())
    dispatch(Actions.getAppartements())
  }

  render() {
    const c = this.props.classes
    const { appartements } = this.props
    const { quitances } = this.state
    console.log("quitances", quitances)
    return (
      <div className={c.container}>
        <Paper style={{ marginBottom: 10, padding: 10 }}>
          <div className='row fullW justify-c align-c'>
            <IconButton
              onClick={_ => {
                this.setState({ date: moment(this.state.date).add(-1, "y") }, this.getQuitances)
              }}>
              <Icon>keyboard_arrow_left</Icon>
            </IconButton>
            <h4 style={{ width: 200, textAlign: "center", margin: 0, padding: 0 }}>{this.state.date.format("YYYY")}</h4>
            <IconButton
              onClick={_ => {
                this.setState({ date: moment(this.state.date).add(1, "y") }, this.getQuitances)
              }}>
              <Icon>keyboard_arrow_right</Icon>
            </IconButton>
          </div>
        </Paper>
        <MaterialTable
          title=''
          isLoading={!appartements}
          columns={appartementColumns}
          actions={[]}
          data={appartements}
          detailPanel={row => (
            <MaterialTable
              title=''
              actions={[{
                icon: "assignment_returned",
                tooltip: "Générer le récapitulatif annuel des quitances",
                onClick: (event, rowData) => axios.get(`Quitance/${rowData.id}/generate/${this.state.date.year()}`).then(rep => downloadFile(rep.data.generated, "Quitance-Annuelle.pdf")),
              },]}
              columns={dossierClientColumns}
              data={this.state.quitances.filter(d => d.AppartementId === row.id).reduce((a, c) => {
                console.log(a, c)
                if (!a.some(q => q.DossierId === c.DossierId)) a.push(c)
                return a
              }, []).map(q => this.props.dossiers.find(d => q.DossierId === d.id))}
              onRowClick={_ => null}
              options={{
                actionsColumnIndex: -1,
                filtering: false,
                toolbar: false,
                paging: false,
                search: false,
              }}
            />
          )}
          onRowClick={_ => null}
          options={{
            actionsColumnIndex: -1,
            filtering: true,
            pageSize: 20,
          }}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container: {},
})


const mapStateToProps = state => ({
  canDelete: state.reducer.canDelete,
  appartements: state.reducer.appartements,
  dossiers: (state.reducer.locataires || []).reduce((out, curr) => {
    const locs = curr.locations.map(l => {
      console.log("start", l)
      const { Dossier, ...rest } = l
      rest.proprietaire = (state.reducer.clients || []).find(p => p.biens.some(b => b.id === rest.id))
      return { ...Dossier, appartement: rest, locataire: curr }
    })
    return (out = out.concat(locs))
  }, []),
})


export default withRouter(withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(FormList))))
