import { withStyles } from "@material-ui/core/styles"
import {} from "assets/jss/material-dashboard-react.js"
import React from "react"
import { CircularProgress } from "@material-ui/core"
import axios from "axios.js"

class ValidationQuitance extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { loading: true, error: false }
    this.regle = this.props.match.params.state === "true"
  }

  componentDidMount() {
    axios
      .post(`/Quitance/validation/${this.regle ? "true" : "false"}/${this.props.match.params.uuid}`)
      .then(rep => {
        this.setState({ loading: false, error: !rep.data.ok })
      })
  }

  render() {
    const c = this.props.classes
    return (
      <div className={c.container}>
        {this.state.loading ? (
          <CircularProgress />
        ) : this.state.error ? (
          <>
            <h1 className='text-c'>
              Votre réponse a déjà été prise en compte par nos services. Si toutefois, vous aviez
              fait une erreur lors de la réponse à notre mail, vous pouvez nous contacter à
              location@patrimoinelocatif.com.
            </h1>
          </>
        ) : (
          <>
            <h1 className='text-c'>{`Le ${
              this.regle ? "" : "non-"
            }paiement de votre quittance a bien été enregistré.`}</h1>
            <h2 className='text-c'>Vous pouvez fermer cette page.</h2>
          </>
        )}
      </div>
    )
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
  logo: {
    objectFit: "contain",
    maxHeight: "50%",
    width: "50%",
  },
})

export default withStyles(styles)(ValidationQuitance)
