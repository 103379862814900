import { Icon, IconButton, Paper, Switch, Typography } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import { withStyles } from "@material-ui/core/styles"
import { } from "assets/jss/material-dashboard-react.js"
import axios from "axios.js"
import Button from "components/CustomButtons/Button.js"
import MaterialTable from "components/MaterialTable.js"
import moment from "moment"
import "moment/locale/fr"
import PubSub from "pubsub-js"
import React from "react"
import Form from "components/MuiJsonForm"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import Actions from "redux/actions"
import reducer from "redux/reducers"
import { quitanceSchema } from "utils/schemas"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import withReducer from "redux/withReducer"
import { etatColumns, quitanceColumns, quitanceGenerateColumns } from "utils/columns"
import downloadFile from "utils/downloadFile"
moment.locale("fr")
var dispatch

const RedRadio = withStyles({
  root: {
    color: "#f59673",
    "&$checked": {
      color: "#f59673",
    },
  },
  checked: {},
})(props => <Radio color='default' {...props} />)

const GreenRadio = withStyles({
  root: {
    color: "#6ac47b ",
    "&$checked": {
      color: "#6ac47b",
    },
  },
  checked: {},
})(props => <Radio color='default' {...props} />)

class FormList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      load: true,
      quitances: [],
      generate: false,
      isGenerating: false,
      date: new Date(),
      dialog: false,
      auto: "all",
      generateFd: {},
      checked: [],
    }
    dispatch = this.props.dispatch
  }

  getQuitances = () => {
    const { date } = this.state
    this.setState({ load: true })
    return axios
      .get(`Quitance/byDate/${date.getMonth() + 1}-${date.getFullYear()}`)
      .then(({ data }) => this.setState({ quitances: data, load: false }))
  }

  componentDidMount() {
    this.getQuitances()
    dispatch(Actions.getDossiers())
    dispatch(Actions.getLocataires())
    dispatch(Actions.getAppartements())
  }

  actions = [
    {
      icon: "swap_horiz",
      tooltip: "changer le status de paiement",
      onClick: (event, rowData) =>
        PubSub.confirm({
          title: `Changer l'état de la quittance`,
          subtitle: "Dans quel état voulez vous faire passer la quittance ?",
          buttons: [
            {
              txt: "Payé",
              onClick: _ =>
                axios
                  .get(`/Quitance/switchState/true/${rowData.id}`)
                  .then(_ => this.componentDidMount()),
            },
            {
              txt: "Impayé",
              onClick: _ =>
                axios
                  .get(`/Quitance/switchState/false/${rowData.id}`)
                  .then(_ => this.componentDidMount()),
            },
            {
              txt: "Inconnu",
              onClick: _ =>
                dispatch(
                  Actions.setQuitance(rowData.id, { regle: false, statusInconu: true })
                ).then(_ => this.componentDidMount()),
            },
          ].filter(
            b =>
              (b.txt !== "Inconnu" && rowData.statusInconu) ||
              (!(b.txt == "Payé" && rowData.regle) &&
                !(b.txt == "Impayé" && !rowData.regle) &&
                !(b.txt === "Inconnu" && rowData.statusInconu))
          ),
        }),
    },
    {
      icon: p => <Button color='primary'>Télécharger les quittances du mois</Button>,
      onClick: _ =>
        downloadFile(
          axios.static(`/Quitance/downloadMonth/${moment(this.state.date).format("YYYY/MM")}`),
          `Quittances ${moment(this.state.date).format("MMMM_YYYY")}.zip`
        ),
      isFreeAction: true,
    },
    {
      icon: "assignment_returned",
      tooltip: "Télécharger la quittance",
      onClick: (event, rowData) =>
        downloadFile(
          rowData.file,
          `Quittance_${rowData.numero}_${moment(rowData.date).format("MMMM_YYYY")}-${rowData.Locataire.nom
          }-${rowData.DossierId}.pdf`
        ),
    },
    {
      icon: "home",
      tooltip: "Voir l'appartement",
      onClick: (event, rowData) =>
        this.props.history.push(`/appartements/${rowData.Appartement.id}`),
    },
    {
      icon: "assignment_ind",
      tooltip: "Voir le client",
      onClick: (event, rowData) => this.props.history.push(`/client/${rowData.Client.id}`),
    },
    {
      icon: "supervised_user_circle",
      tooltip: "Voir le locataire",
      onClick: (event, rowData) => this.props.history.push(`/locataires/${rowData.Locataire.id}`),
    },
    this.props.canDelete && {
      icon: "delete",
      tooltip: "Supprimer le quittance",
      onClick: (event, rowData) =>
        PubSub.confirm({
          title: "Supprimer l'element ?",
          subtitle: "Cette action est irreversible.",
          onAgree: _ =>
            dispatch(Actions.deleteQuitance(rowData.id)).then(_ => this.componentDidMount()),
        }),
    },
  ]

  getFilteredQuitances = () => {
    const { auto } = this.state
    return this.state.quitances.filter(q => {
      if (auto === "auto" && !q.automatique) return false
      if (auto === "manual" && q.automatique) return false
      return true
    })
  }

  getGenerateRowColumn = r => {
    if (this.state.quitances.find(d => d.DossierId === r.id)) return "#6ac47b"
    if (this.state.checked.includes(r.id)) return "#aebfe8"
    else return "#f59673"
  }

  generateAutoQuitance = () => {
    this.setState({ isGenerating: true })
    const proms = this.state.checked.map(id => {
      if (!this.state.quitances.find(d => d.DossierId === id))
        return axios.post(`Quitance/generate/${id}`, { date: this.state.date.toISOString() })
      return Promise.resolve()
    })
    Promise.all(proms).then(_ => {
      this.getQuitances().then(_ => this.setState({ isGenerating: false }))
    })
  }

  GenerateAutoButton = () => {
    if (!this.state.generate) return null
    if (this.state.checked.length > 0 && this.state.isGenerating) return <CircularProgress />
    else
      return (
        <Button
          color='primary'
          onClick={_ => {
            this.setState({ dialog: { type: "auto", onClick: this.generateAutoQuitance } })
          }}>
          Générer les quittances automatiques
        </Button>
      )
  }

  render() {
    const c = this.props.classes
    const { generate, dialog, quitances } = this.state
    const endMonth = moment(this.state.date).endOf("month")
    const startMonth = moment(this.state.date).startOf("month")
    console.log("quitances", this.state.generateFd)
    return (
      <div className={c.container}>
        {false && (
          <Button
            onClick={_ =>
              axios
                .post(`Quitance/generate/4`, { date: this.state.date.toISOString() })
                .then(rep => {
                  console.log(rep)
                  downloadFile(rep.data.file, `quittance.pdf`)
                })
            }>
            TEST
          </Button>
        )}
        <Paper style={{ marginBottom: 10, padding: 10 }}>
          <div className='row, fullW, justify-o'>
            {generate ? (
              <FormControl component='fieldset'>
                <FormLabel component='legend'>Légende</FormLabel>
                <RadioGroup
                  className='row'
                  aria-label='gender'
                  name='auto'
                  value={this.state.auto}
                  onChange={e => this.setState({ auto: e.target.value })}>
                  <FormControlLabel
                    value='all'
                    control={<Radio checked={true} />}
                    label='Automatiuqe'
                  />
                  <FormControlLabel
                    value='auto'
                    control={<RedRadio checked={true} />}
                    label='Manuelle'
                  />
                  <FormControlLabel
                    value='auto'
                    control={<GreenRadio checked={true} />}
                    label='Générée'
                  />
                </RadioGroup>
              </FormControl>
            ) : (
                <FormControl component='fieldset'>
                  <FormLabel component='legend'>Gestion des paiements</FormLabel>
                  <RadioGroup
                    className='row'
                    aria-label='gender'
                    name='auto'
                    value={this.state.auto}
                    onChange={e => this.setState({ auto: e.target.value })}>
                    <FormControlLabel value='all' control={<Radio />} label='Toutes' />
                    <FormControlLabel value='auto' control={<Radio />} label='Automatiques' />
                    <FormControlLabel value='manual' control={<Radio />} label='Manuelles' />
                  </RadioGroup>
                </FormControl>
              )}
            <div className='row align-c'>
              <p
                style={{
                  color: !generate ? "green" : "unset",
                  fontWeight: !generate ? "bold" : "unset",
                }}>
                Lecture
              </p>
              <Switch
                checked={generate}
                onChange={_ => this.setState({ generate: !generate })}
                name='checkedA'
              />
              <p
                style={{
                  color: generate ? "green" : "unset",
                  fontWeight: generate ? "bold" : "unset",
                }}>
                Génération
              </p>
            </div>
          </div>
          <div className='row fullW justify-c align-c'>
            <IconButton
              onClick={_ => {
                this.state.date.setMonth(this.state.date.getMonth() - 1)
                this.setState({ date: this.state.date }, this.getQuitances)
              }}>
              <Icon>keyboard_arrow_left</Icon>
            </IconButton>
            <h4 style={{ width: 200, textAlign: "center", margin: 0, padding: 0 }}>
              {moment(this.state.date).format("MMMM YYYY")}
            </h4>
            <IconButton
              onClick={_ => {
                this.state.date.setMonth(this.state.date.getMonth() + 1)
                this.setState({ date: this.state.date }, this.getQuitances)
              }}>
              <Icon>keyboard_arrow_right</Icon>
            </IconButton>
          </div>
        </Paper>
        <div className='justify-c fullW'>{this.GenerateAutoButton()}</div>
        {!generate && (
          <MaterialTable
            title=''
            isLoading={this.state.load}
            columns={quitanceColumns}
            actions={this.actions}
            data={this.getFilteredQuitances()}
            detailPanel={row => (
              <MaterialTable
                title=''
                columns={etatColumns}
                data={row.Etats}
                onRowClick={_ => null}
                options={{
                  actionsColumnIndex: -1,
                  filtering: false,
                  header: false,
                  toolbar: false,
                  search: false,
                  paging: false,
                }}
              />
            )}
            onRowClick={_ => null}
            options={{
              actionsColumnIndex: -1,
              pageSize:20,
              filtering: true,
              exportAllData: true,

            }}
          />
        )}
        {generate && (
          <MaterialTable
            title=''
            isLoading={this.state.load}
            columns={quitanceGenerateColumns}
            data={this.props.dossiers.
              filter(d => (moment(d.dateDebutBail).isSameOrBefore(startMonth, "month")) && (!d.dateFinBail || moment(d.dateFinBail).isSameOrAfter(endMonth, "month")))
            }
            onRowClick={_ => null}
            detailPanel={row => {
              if (
                !this.state.checked.includes(row.id) &&
                !this.state.quitances.find(d => d.DossierId === row.id)
              )
                return (
                  <div style={{ margin: 10 }}>
                    <Form
                      {...quitanceSchema(row, false, true, fd => this.setState({ generateFd: fd }))}
                      formData={this.state.generateFd}
                      onSubmit={({ formData }) => {
                        this.setState({
                          dialog: {
                            ...formData.data,
                            onClick: () =>
                              axios
                                .post(`Quitance/generate/manual/${row.id}`, { ...formData.data })
                                .then(this.getQuitances),
                          },
                        })
                      }}
                    >
                      <Typography>{`Loyer total: ${((this.state.generateFd?.data?.prixHC + this.state.generateFd?.data?.charge) || 0).toFixed(2)}€`}</Typography>
                    </Form>
                  </div>
                )
              else return null
            }}
            options={{
              actionsColumnIndex: -1,
              filtering: true,
              selection: true,
              paging: false,
              rowStyle: rowData => ({
                backgroundColor: this.getGenerateRowColumn(rowData),
              }),
            }}
            onSelectionChange={rows => {
              this.setState({ checked: rows.map(r => r.id) })
            }}
          />
        )}
        <div className='justify-c fullW'>{this.GenerateAutoButton()}</div>
        <Dialog open={dialog ? true : false} onClose={_ => this.setState({ dialog: false })}>
          <DialogTitle>{"Générer les quittances ?"}</DialogTitle>
          <DialogContent>
            {dialog.type === "auto" ? (
              <DialogContentText>
                Etes vous sûr de vouloir bien validé la création de quittance automatiquement pour
                les dossiers sélectionnés. Cette action est irréversible et enverra des mails au
                propriétaire et locataire.
              </DialogContentText>
            ) : (
                <>
                  <DialogContentText>
                    Etes vous sûr de confirmer la quittance pour les informations suivantes :
                </DialogContentText>
                  <DialogContentText>{`date de début : ${moment(dialog.date).format("LL")}`}</DialogContentText>
                  <DialogContentText>{`date de fin : ${moment(dialog.dateFin).format("LL")}`}</DialogContentText>
                  <DialogContentText>{`loyer Hors charges : ${dialog.prixHC?.toFixed(2)}€`}</DialogContentText>
                  <DialogContentText>{`Charges: ${dialog.charge?.toFixed(2)}€`}</DialogContentText>
                  <DialogContentText>{`Loyer Total: ${(dialog.charge + dialog.prixHC)?.toFixed(2)}€`}</DialogContentText>
                </>
              )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={_ => {
                dialog.onClick()
                this.setState({ dialog: false, generateFd: {} })
              }}
              color='primary'>
              Confirmer
            </Button>
            <Button
              onClick={_ => this.setState({ dialog: false, generateFd: {} })}
              color='primary'
              autoFocus>
              Annuler
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const styles = theme => ({
  container: {},
})

const mapStateToProps = state => ({
  quitances: state.reducer.quitances,
  canDelete: state.reducer.canDelete,
  dossiers: state.reducer.dossiers?.map(d => ({
    ...d,
    Appartement: state.reducer.appartements?.find(a => a.id === d.AppartementId),
    Locataire: state.reducer.locataires?.find(a => a.id === d.LocataireId),
  })),
})
export default withRouter(
  withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(FormList)))
)
