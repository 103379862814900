import axios from "axios.js"
import Actions from "../actions"
const defaultState = {
  currentUser: null,
  login: true,
  canDelete: false,
}

const primaryKey = "id"

const reducer = function (state = defaultState, action) {
  const CRUDreducer = key => {
    switch (action.type) {
      case Actions["SET_" + key.toUpperCase() + "S"]:
        return {
          ...state,
          [key + "s"]: action.payload,
        }
      case Actions["SET_" + key.toUpperCase()]:
        return {
          ...state,
          [key]: action.payload ? { ...state[key], ...action.payload } : null,
          [key + "s"]:
            state[key + "s"] && action.payload ? state[key + "s"].map(obj => (obj.id === action.payload.id ? action.payload : obj)) : undefined,
        }
      case Actions["ADD_" + key.toUpperCase()]:
        return {
          ...state,
          [key + "s"]: (state[key + "s"] || []).concat([action.payload]),
        }
      case Actions["DELETE_" + key.toUpperCase()]:
        return {
          ...state,
          [key + "s"]: (state[key + "s"] || []).filter(c => c[primaryKey] !== action.payload),
        }
      default:
        return null
    }
  }
  if (!action) return state
  if (CRUDreducer("client")) return CRUDreducer("client")
  if (CRUDreducer("appartement")) return CRUDreducer("appartement")
  if (CRUDreducer("locataire")) return CRUDreducer("locataire")
  if (CRUDreducer("mission")) return CRUDreducer("mission")
  if (CRUDreducer("quitance")) return CRUDreducer("quitance")
  if (CRUDreducer("accompte")) return CRUDreducer("accompte")
  if (CRUDreducer("equipement")) return CRUDreducer("equipement")
  if (CRUDreducer("image")) return CRUDreducer("image")
  if (CRUDreducer("intendance")) return CRUDreducer("intendance")
  if (CRUDreducer("dossier")) return CRUDreducer("dossier")
  if (CRUDreducer("dossier")) return CRUDreducer("dossier")
  if (CRUDreducer("etatLieux")) return CRUDreducer("etatLieux")
  if (CRUDreducer("fichier")) return CRUDreducer("fichier")
  if (CRUDreducer("user")) return CRUDreducer("user")

  switch (action.type) {
    case Actions.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        canDelete: action.payload ? action.payload.role === "admin" || action.payload.role === "user_delete" : false,
      }
    case Actions.SET_APPART_HISTORY:
      return {
        ...state,
        appartement: { ...(state.appartement || {}), history: action.payload },
      }
    case Actions.SET_TOKEN:
      axios.defaults.headers.common["Authorization"] = "Token " + action.payload
      if (action.remember) localStorage.setItem("jwt_access_token", action.payload)
      return {
        ...state,
        token: action.payload,
      }
    case Actions.ADD_MISSION:
      return {
        ...state,
        client: { ...(state.client || {}), Missions: ((state.client || {}).Missions || []).concat([action.payload]) },
      }
    case Actions.SET_MISSION:
      return {
        ...state,
        client: {
          ...(state.client || {}),
          Missions: ((state.client || {}).Missions || []).map(m => (m.id === action.payload.id ? action.payload : m)),
        },
      }
    case Actions.DELETE_MISSION:
      return {
        ...state,
        client: { ...(state.client || {}), Missions: ((state.client || {}).Missions || []).filter(c => c.id !== action.payload) },
      }
    case Actions.SET_VARIABLES:
      return {
        ...state,
        variables: Object.assign({}, ...action.payload.map(v => ({ [v.id]: v }))),
      }
    case Actions.SET_VARIABLE:
      return {
        ...state,
        variables: { ...(state.variables || {}), [action.payload.id]: action.payload },
      }
    case Actions.SET_LOGIN:
      return {
        ...state,
        login: action.payload,
      }
    default:
      return state
  }
}

export default reducer
