import { withStyles } from "@material-ui/core/styles"
import { } from "assets/jss/material-dashboard-react.js"
import AssociationList from "components/AssociationList.js"
import Form from "components/MuiJsonForm"
import RowGenerateFile from "components/RowGenerateFile"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import Actions from "redux/actions"
import reducer from "redux/reducers"
import withReducer from "redux/withReducer"
import { intendanceColumns } from "utils/columns"
import fileDialog from "utils/fileDialog"
import { intendanceSchema } from "utils/schemas"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import PubSub from "pubsub-js"
import moment from "moment"
var dispatch

class IntendancesList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: "current",
      markAsRefreshed: null,
    }
    dispatch = this.props.dispatch
  }

  componentDidMount() {
    dispatch(Actions.getIntendances())
    dispatch(Actions.getAppartements())
    dispatch(Actions.getVariables())
  }

  filterIntendance = () => {
    const int = this.props.intendances || []
    const { filter } = this.state
    if (filter === "all") return int
    return int.filter(i => {
      const end = moment(i.dateFin)
      if (filter === "noRefresh" && !i.renouvele && moment().isAfter(end)) return true
      else if (filter === "current" && moment().isBefore(end)) return true
      else if (filter === "refreshed" && i.renouvele && moment().isAfter(end)) return true
      return false
    })
  }

  getEmptyString = () => {
    const { filter } = this.state
    switch (filter) {
      case "current": return "Aucune mission d'intendance en cours"
      case "noRefresh": return "Aucune mission d'intendance non renouvelée"
      case "all": return "Aucune mission d'intendance"
      default: return "Aucune mission d'intendance"
    }
  }

  render() {
    const c = this.props.classes
    const { intendances, appartements } = this.props
    console.log("Intendance", intendances)
    console.log(this.state.FormDataIntendance_New)
    return (
      <div className={c.container}>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Filtres</FormLabel>
          <RadioGroup
            className='row'
            name='auto'
            value={this.state.filter}
            onChange={e => this.setState({ filter: e.target.value })}>
            <FormControlLabel value='current' control={<Radio />} label='En cours' />
            <FormControlLabel value='noRefresh' control={<Radio />} label='Non renouvelées' />
            <FormControlLabel value='all' control={<Radio />} label='Toutes' />
          </RadioGroup>
        </FormControl>
        <AssociationList
          title=''
          data={this.filterIntendance()}
          actions={[
            rw => ({
              icon: "refresh",
              tooltip: rw.renouvele ? "Mission déjà renouvelée" : "Renouveler la mission d'intendance",
              disabled: rw.renouvele,
              onClick: (event, rowData) => {
                this.setState({
                  FormDataIntendance_New: {
                    appart: { appart: rw.Appartement },
                    data: { ...rw, prixHT: 100, dateDepart: moment(rw.dateDepart).add(1, 'y').toISOString().split("T")[0], dateFin: moment(rw.dateFin).add(1, 'y').toISOString().split("T")[0], Appartement: undefined, id: undefined, regle: false, renouvele: false }
                  },
                  markAsRefreshed: rw.id
                }, this.openTopRight)
              }
            }),
            {
              icon: "home",
              tooltip: "Voir l'appartement concerné",
              onClick: (event, rowData) => this.props.history.push(`/Appartements/${rowData.AppartementId}`),
            },
          ]}
          columns={intendanceColumns}
          detailPanel={row => (
            <div style={{ background: "#EEE", padding: 10 }}>
              <RowGenerateFile
                key={row.id}
                title={`Ordre de mission d'Intendance`}
                generateUrl={`Appartement/${row.AppartementId}/ordreMissionIntendance/${row.id}/generate`}
                generateName={`Ordre_Mission_Intendance_Non_Signé.docx`}
                uploaded={row.ordreMission}
                uploadedName={`Ordre_Mission_Intendance.docx`}
                onUpload={_ =>
                  fileDialog("ordreMission")
                    .then(fd => dispatch(Actions.setIntendance(row.id, fd)))
                    .then(_ => this.componentDidMount())
                }
              />
              <Form
                {...intendanceSchema(row, data => this.setState({ ["FormData_" + row.id]: data }), false, false, appartements, this.props.variables?.tva.valeur)}
                formData={this.state["FormData_" + row.id]}
                onSubmit={({ formData }) => {
                  dispatch(Actions.setIntendance(row.id, { ...formData.data, AppartementId: formData.appart.appart.id })).then(_ => this.componentDidMount())
                }}
              />
            </div>
          )}
          onTopRightClose={_ => this.setState({ markAsRefreshed: null })}
          forceOpenTopRight={open => this.openTopRight = open}
          onTopRightButton={close => (
            <Form
              {...intendanceSchema(
                {},
                data => this.setState({ FormDataIntendance_New: data }),
                false,
                true,
                appartements,
                this.props.variables?.tva.valeur
              )}
              formData={this.state.FormDataIntendance_New}
              onSubmit={({ formData: { data, appart } }) => {
                console.log(appart)
                const proms = []
                if (this.state.markAsRefreshed !== null)
                  proms.push(Actions.setIntendance(this.state.markAsRefreshed, { renouvele: true }))
                proms.push(dispatch(Actions.createIntendance({ ...data, AppartementId: appart.appart.id })))
                Promise.all(proms).then(_ => {
                  this.componentDidMount()
                  close()
                })
              }}
              onError={console.log}
            />
          )}
          topRightButtonTxt="Nouvelle Mission d'Intendance"
          noDataTxt={this.getEmptyString()}
          removeTxt='Supprimer la mission'
          search={true}
          filtering={true}
          onRemove={intendanceId => dispatch(Actions.deleteIntendance(intendanceId))}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container: {},
})

const mapStateToProps = state => {
  console.log("STATE", state)
  return {
    intendances: state.reducer.intendances,
    canDelete: state.reducer.canDelete,
    appartements: state.reducer.appartements,
    variables: state.reducer.variables,
  }
}
export default withRouter(withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(IntendancesList))))
