export default formDataField => {
  return new Promise(res => {
    const dialog = document.createElement("input")
    dialog.setAttribute("type", "file")
    dialog.style = { display: "none" }
    dialog.onchange = e => {
      if (formDataField) {
        const fd = new FormData()
        console.log(e.target.files)
        fd.append(formDataField, e.target.files[0])
        res(fd)
      } else res(e.target.files[0])
    }
    document.body.appendChild(dialog)
    dialog.click()
  })
}
