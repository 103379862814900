import { withStyles } from "@material-ui/core/styles"
import "assets/css/form-auto.css"
import { } from "assets/jss/material-dashboard-react.js"
import AssociationList from "components/AssociationList"
import CustomTabs from "components/CustomTabs/CustomTabs"
import Form from "components/MuiJsonForm"
import PubSub from "pubsub-js"
import queryString from "query-string"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import Actions from "redux/actions"
import reducer from "redux/reducers"
import withReducer from "redux/withReducer"
import { appartementColumns, fichierColumns, missionColumns, quitanceColumns } from "utils/columns"
import downloadFile from "utils/downloadFile"
import fileDialog from "utils/fileDialog"
import { clientSchema, fichierSchema, missionSchema } from "utils/schemas"

var dispatch

class FormEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, formData: {}, initLoad: true }
    dispatch = this.props.dispatch
    this.isNew = props.match.params.id === "new"
    this.edit = props.match.params.edit ? true : false
    this.readOnly = !this.isNew && !this.edit
  }

  componentDidMount() {
    let action = []
    if (!this.state.initLoad) this.setState({ loading: true, formData: {}, initLoad: true })
    action.push(dispatch(Actions.getClient(this.props.match.params.id)))
    if (!this.isNew) action.push(dispatch(Actions.getAppartements()))
    if (!this.isNew) action.push(dispatch(Actions.getMissions()))
    action.push(dispatch(Actions.getQuitances()))
    action.push(dispatch(Actions.getClients()))
    action.push(dispatch(Actions.getVariables()))
    Promise.all(action).then(_ => this.setState({ loading: false, initLoad: false }))
  }

  componentDidUpdate(prevProps, prevState) {
    this.isNew = this.props.match.params.id === "new"
    this.edit = this.props.match.params.edit ? true : false
    this.readOnly = !this.isNew && !this.edit
    if (this.props.match.params !== prevProps.match.params) {
      this.componentDidMount()
    }
  }

  onSubmit = form => {
    if (this.readOnly) return
    const body = { ...form.formData.personal, ...form.formData.coordonates, ...form.formData.poi, ...form.formData.mariageField }
    if (!body.clientAttache) body.nom2 = ""
    console.log("VALIDATE", body)
    this.setState({ loading: true })
      ; (this.isNew ? dispatch(Actions.createClient(body)) : dispatch(Actions.setClient(this.props.match.params.id, body))).then(rep => {
        PubSub.notif({
          txt: this.isNew ? "Votre client à été crée avec succes." : "Vos modifications ont été prises en compte.",
          color: "success",
          icon: "save",
        })
        this.setState({ loading: false })
        if (this.isNew) this.props.history.push(queryString.parse(this.props.location.search).oncreate || `/client/${rep.payload.id}`)
      })
  }

  render() {
    const c = this.props.classes
    const { client, canDelete } = this.props
    console.log("Client", client, this.props.clients, this.props.quitances)
    return (
      <div className={c.container}>
        <CustomTabs
          initTab={queryString.parse(this.props.location.search).tab}
          title={this.state.loading ? "Chargement en cours..." : (!this.isNew ? client.nom + " " + client.prenom : "Nouveau Client").toUpperCase()}
          headerColor='info'
          rigthBtn={!this.isNew && { display: !this.edit, icon: "create", onClick: _ => this.props.history.push(this.props.match.url + "/edit") }}
          tabs={[
            {
              tabName: "Details",
              tabIcon: "assignment_ind",
              tabContent: (
                <Form
                  {...clientSchema(this.props.client, this.props.clients, this.readOnly, this.isNew)}
                  formData={this.state.formData}
                  onChange={({ formData }) => this.setState({ formData })}
                  initLoad={this.state.initLoad}
                  loading={this.state.loading}
                  readOnly={this.readOnly}
                  onSubmit={this.onSubmit}
                  onError={console.log}
                  removeEditOnSubmit
                />
              ),
            },
            {
              tabName: "Appartements",
              tabIcon: "home",
              tabContent: (
                <AssociationList
                  data={(this.props.client || {}).biens}
                  columns={appartementColumns}
                  noDataTxt='Aucun appartement assigné.'
                  detailTxt="Voir les details de l'appartement"
                  onDetails={o => this.props.history.push(`/appartements/${o.id}`)}
                  removeTxt="Retirer l'appartement"
                  onRemove={apartId => dispatch(Actions.removeOwner(apartId, this.props.client.id))}
                  onAdd={apartId => dispatch(Actions.addOwner(apartId, this.props.client.id))}
                  addTxt='Sélectionner un appartement'
                  addData={this.props.appartements}
                  addColumn={appartementColumns}
                  createTxt='Créer un appartement'
                  onCreate={_ => this.props.history.push(`/appartements/new?oncreate=${window.location.pathname}?tab=1`)}
                />
              ),
            },
            {
              tabName: "Missions",
              tabIcon: "work",
              tabContent: (
                <>
                  <h3>Missions en cours</h3>
                  <AssociationList
                    data={
                      (this.props.client || {}).Missions
                        ? (this.props.missions || []).filter(m => this.props.client.Missions.some(mi => mi.id === m.id))
                        : []
                    } //istead of client.mission to get included value of mission
                    columns={missionColumns}
                    detailPanel={rd => (
                      <div style={{ background: "#EEE" }}>
                        <Form
                          {...missionSchema(rd, false, this.isNew, this.props.variables.tva.valeur, state =>
                            this.setState({ ["formDataMission_" + rd.id]: state })
                          )}
                          formData={this.state["formDataMission_" + rd.id]}
                          onSubmit={({ formData: { data } }) => dispatch(Actions.setMission(rd.id, data))}
                          onError={console.log}
                        />
                      </div>
                    )}
                    onDetails={o => this.props.history.push(`/missions/${o.id}`)}
                    detailTxt='Acceder aux détails de la mission'
                    noDataTxt='Aucune mission en cours.'
                    removeTxt='Retirer la mission'
                    addTxt='Assigner une mission'
                    addColumn={missionColumns}
                    addData={this.props.missions}
                    createTxt='Créer une mission'
                    onCreate={_ => this.props.history.push(`/missions/new?oncreate=${window.location.pathname}?tab=2`)}
                    onAdd={missionId => dispatch(Actions.addMissionCustomer(missionId, this.props.client.id))}
                    onRemove={missionId => dispatch(Actions.removeMissionCustomer(missionId, this.props.client.id))}
                  />
                </>
              ),
            },
            {
              tabName: "Quittances",
              tabIcon: "receipt",
              tabContent: (
                <>
                  <AssociationList
                    data={(this.props.quitances || []).filter(q => q.ClientId == this.props.match.params.id)} //istead of client.mission to get included value of mission
                    columns={quitanceColumns}
                    actions={[
                      {
                        icon: "swap_horiz",
                        tooltip: "changer le status de paiement",
                        onClick: (event, rowData) =>
                          PubSub.confirm({
                            title: `Marquer la quittance commme ${rowData.regle ? "non" : ""} reglé`,
                            onAgree: _ => dispatch(Actions.setQuitance(rowData.id, { regle: !rowData.regle })),
                          }),
                      },
                      {
                        icon: "home",
                        tooltip: "Voir l'appartement",
                        onClick: (event, rowData) => this.props.history.push(`/appartements/${rowData.Appartement.id}`),
                      },
                      {
                        icon: "assignment_ind",
                        tooltip: "Voir le client",
                        onClick: (event, rowData) => this.props.history.push(`/client/${rowData.Client.id}`),
                      },
                      {
                        icon: "supervised_user_circle",
                        tooltip: "Voir le locataire",
                        onClick: (event, rowData) => this.props.history.push(`/locataires/${rowData.Locataire.id}`),
                      },
                      canDelete && {
                        icon: "delete",
                        tooltip: "Supprimer le quittance",
                        onClick: (event, rowData) =>
                          PubSub.confirm({
                            title: "Supprimer la quittance ?",
                            subtitle: "Cette action est irreversible.",
                            onAgree: _ => dispatch(Actions.deleteQuitance(rowData.id)),
                          }),
                      },
                    ]}
                    noDataTxt='Aucune quitance.'
                  />
                </>
              ),
            },
            {
              tabName: "Fichiers",
              tabIcon: "description",
              tabContent: (
                <>
                  <h3>{`Fichier${((this.props.client || {}).Fichiers || []).length > 1 ? "s" : ""} lié au client`}</h3>
                  <AssociationList
                    data={(this.props.client || {}).Fichiers || []}
                    noDataTxt='Aucune fichier lié au client.'
                    topRightButtonTxt='Ajouter un fichier'
                    onTopRightButton={close => (
                      <Form
                        style={{ width: "100%" }}
                        formData={this.state.newFileFormData}
                        {...fichierSchema({}, data => this.setState({ newFileFormData: data }), true, false)}
                        onSubmit={({ formData: { data } }) => {
                          dispatch(Actions.addFichierClient(this.props.match.params.id, data)).then(close)
                        }}
                        onError={console.log}
                      />
                    )}
                    columns={fichierColumns}
                    actions={[
                      {
                        icon: "get_app",
                        tooltip: "Télécharger le fichier",
                        onClick: (event, rowData) => downloadFile(rowData.url, `${client.nom} ${client.prenom}_${rowData.nom}.${rowData.extention}`),
                      },
                      {
                        icon: "publish",
                        tooltip: "Changer de fichier",
                        onClick: (event, rowData) => fileDialog("url").then(fd => dispatch(Actions.setFichier(rowData.id, fd))),
                      },
                      canDelete && {
                        icon: "delete",
                        tooltip: "supprimer le fichier",
                        onClick: (event, rowData) =>
                          PubSub.confirm({
                            title: "Supprimer le fichier ?",
                            subtitle: "Cette action est irreversible.",
                            onAgree: _ => dispatch(Actions.deleteFichier(rowData.id)).then(_ => this.componentDidMount()),
                          }),
                      },
                    ]}
                  />
                </>
              ),
            },
          ].filter(t => !this.isNew || t.tabName === "Details")}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container: {
    marginTop: 30,
  },
  formCard: {
    margin: "auto",
  },
})

const mapStateToProps = state => ({
  client: state.reducer.client,
  appartements: state.reducer.appartements,
  missions: state.reducer.missions,
  clients: state.reducer.clients,
  variables: state.reducer.variables,
  quitances: state.reducer.quitances,
  canDelete: state.reducer.canDelete,
})
export default withRouter(withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(FormEditor))))
