import React from "react"
import withReducer from "redux/withReducer"
import { withStyles } from "@material-ui/core/styles"
import {} from "assets/jss/material-dashboard-react.js"
import { connect } from "react-redux"
import reducer from "redux/reducers"
import Actions from "redux/actions"
import { withRouter } from "react-router"
import MaterialTable from "components/MaterialTable.js"
import Button from "components/CustomButtons/Button.js"
import PubSub from "pubsub-js"
import { missionColumns } from "utils/columns"
var dispatch

class FormList extends React.Component {
  constructor(props) {
    super(props)
    dispatch = this.props.dispatch
  }

  componentDidMount() {
    dispatch(Actions.getMissions())
  }

  render() {
    const c = this.props.classes
    const { missions, canDelete } = this.props
    console.log(
      "Mission",
      (missions || []).sort((a, b) => new Date(a.Accomptes[0]?.date || 0).getTime() - new Date(b.Accomptes[0]?.date || 0).getTime())
    )
    return (
      <div className={c.container}>
        <MaterialTable
          title=''
          isLoading={!missions}
          columns={missionColumns}
          actions={[
            {
              icon: "edit",
              tooltip: "Modifier la mission",
              onClick: (event, rowData) => this.props.history.push(`/missions/${rowData.id}/edit`),
            },
            {
              icon: "remove_red_eye",
              tooltip: "Voir les details de la mission",
              onClick: (event, rowData) => this.props.history.push(`/missions/${rowData.id}`),
            },
            canDelete && {
              icon: "delete",
              tooltip: "Supprimer la mission",
              onClick: (event, rowData) =>
                PubSub.confirm({
                  title: "Supprimer l'element ?",
                  subtitle: "Cette action est irreversible.",
                  onAgree: _ => dispatch(Actions.deleteMission(rowData.id)),
                }),
            },
            {
              icon: p => <Button color='primary'>Nouvelle Mission</Button>,
              isFreeAction: true,
              onClick: event => this.props.history.push(`/missions/new`),
            },
          ]}
          data={(missions || []).sort((a, b) => {
            if (a.Accomptes[0] || b.Accomptes[0]) return new Date(a.Accomptes[0]?.date || 0).getTime() - new Date(b.Accomptes[0]?.date || 0).getTime()
            return a.nbAppart - b.nbAppart
          })}
          onRowClick={(event, rowData) => this.props.history.push(`/missions/${rowData.id}`)}
          options={{
            filtering: true,
            pageSize: 20,
            actionsColumnIndex: -1,
          }}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container: {},
})

const mapStateToProps = state => ({ missions: state.reducer.missions, canDelete: state.reducer.canDelete })
export default withRouter(withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(FormList))))
