import { Dialog, Paper } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { withStyles } from "@material-ui/core/styles"
import {} from "assets/jss/material-dashboard-react.js"
import CardIndicator from "components/CardIndicator"
import MaterialTable from "components/MaterialTable.js"
import Form from "components/MuiJsonForm"
import moment from "moment"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import Actions from "redux/actions"
import reducer from "redux/reducers"
import withReducer from "redux/withReducer"
import { appartementDashColumns, intendanceDashColumns, missionDashColumns } from "utils/columns"
import { intendanceSchema } from "utils/schemas"
var dispatch

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      refreshIntendance: false,
      markAsRefreshed: null,
      nbAppart1: true,
      nbAppart2: true,
      nbAppart3: true,
    }
    dispatch = this.props.dispatch
  }

  componentDidMount() {
    dispatch(Actions.getAppartements())
    dispatch(Actions.getMissions())
    dispatch(Actions.getIntendances())
  }

  render() {
    const c = this.props.classes
    const { appartements, missions, intendances } = this.props
    console.log("app", appartements)
    return (
      <div className={c.container}>
        <Paper style={{ padding: 20 }}>
          <h2 className={c.title}>Appartements</h2>
          <div className='row'>
            <CardIndicator
              bgcolor='#2d6a4f'
              icon='work'
              title='En gestion'
              data={
                appartements?.filter(a =>
                  a.Intendances.some(i => moment().isBetween(i.dateDepart, i.dateFin))
                ).length
              }
              subtitle='Total'
              subdata={appartements?.length}
            />
            <CardIndicator
              bgcolor='#40916c'
              icon='perm_contact_calendar'
              title='Louée'
              data={appartements?.filter(a => a.locataires?.length).length}
            />
            <CardIndicator
              bgcolor='#52b788'
              icon='local_offer'
              title='Vacant'
              data={appartements?.filter(a => !a.wip && !a.locataires?.length).length}
            />
            <CardIndicator
              bgcolor='#74c69d'
              icon='warning'
              title='En Travaux'
              data={appartements?.filter(a => a.wip).length}
            />
          </div>
        </Paper>
        <Paper style={{ padding: 20, marginTop: 20 }}>
          <h2 className={c.title}>Missions</h2>
          <div className='row' style={{ marginBottom: 20 }}>
            <CardIndicator
              bgcolor='#bb7711'
              icon='work'
              title='En cours'
              data={missions?.length}
              subtitle='Reste a encaisser HT'
              subdata={
                missions &&
                missions?.reduce((acc, cur) => acc + cur.valeurTotalMission, 0) -
                  missions?.reduce(
                    (acc, curr) => acc + curr.Accomptes.reduce((a, c) => a + c.montant, 0),
                    0
                  ) +
                  " €"
              }
            />
            <CardIndicator
              icon='date_range'
              title='Cette année'
              data={missions?.filter(m => moment().isSame(moment(m.dateSignature), "y")).length}
              subtitle="Ca de l'année HT"
              subdata={
                missions &&
                missions
                  ?.filter(m => moment().isSame(moment(m.dateSignature), "y"))
                  .reduce((a, c) => a + c.Accomptes.reduce((_a, _c) => _a + _c.montant, 0), 0)
                  .toFixed(0) + " €"
              }
            />
          </div>
          <div className='row'>
            <p style={{ marginRight: 10 }}>Nombre d'appartements </p>
            {[1, 2, 3].map(i => (
              <FormControlLabel
                disabled={false}
                key={i}
                label={i === 3 ? "3+" : i}
                control={
                  <Checkbox
                    color='default'
                    checked={this.state["nbAppart" + i]}
                    onChange={_ =>
                      this.setState({ ["nbAppart" + i]: !this.state["nbAppart" + i] })
                    }>
                    test
                  </Checkbox>
                }
              />
            ))}
          </div>
          <MaterialTable
            title=''
            isLoading={!missions}
            columns={missionDashColumns}
            actions={[
              {
                icon: "edit",
                tooltip: "Modifier la mission",
                onClick: (event, rowData) =>
                  this.props.history.push(`/missions/${rowData.id}/edit`),
              },
              {
                icon: "remove_red_eye",
                tooltip: "Voir les details de la mission",
                onClick: (event, rowData) => this.props.history.push(`/missions/${rowData.id}`),
              },
            ]}
            data={(missions || [])
              .sort((a, b) => {
                if (a.Accomptes[0] || b.Accomptes[0])
                  return (
                    new Date(a.Accomptes[0]?.date || 0).getTime() -
                    new Date(b.Accomptes[0]?.date || 0).getTime()
                  )
                return a.nbAppart - b.nbAppart
              })
              .filter(a => {
                if (this.state.nbAppart1 && a.nbAppart === 1) return true
                if (this.state.nbAppart2 && a.nbAppart === 2) return true
                if (this.state.nbAppart3 && a.nbAppart >= 3) return true
                return false
              })}
            onRowClick={(event, rowData) => this.props.history.push(`/missions/${rowData.id}`)}
            options={{
              filtering: true,
              pageSize: 5,
              actionsColumnIndex: -1,
            }}
          />
        </Paper>
        <Paper style={{ padding: 20, marginTop: 20 }}>
          <h2 className={c.title}>Intendances</h2>
          <div className='row' style={{ marginBottom: 20 }}>
            <CardIndicator
              bgcolor='#b08968'
              icon='recent_actors'
              title='En cours'
              data={intendances?.filter(i => moment().isBefore(moment(i.dateFin))).length}
              subtitle="Ca de l'année HT"
              subdata={
                intendances &&
                intendances
                  ?.reduce(
                    (acc, cur) => acc + Math.round((cur.prix * 100) / (1 + cur.tva / 100)) / 100,
                    0
                  )
                  .toFixed(0) + " €"
              }
            />
            <CardIndicator
              bgcolor='#ddb892'
              icon='receipt'
              title='Total impayé'
              data={
                intendances &&
                intendances
                  ?.filter(m => !m.regle)
                  .reduce((a, c) => a + c.prix, 0)
                  .toFixed(0) + " €"
              }
              subtitle='Total encaissé'
              subdata={
                intendances &&
                intendances?.filter(m => m.regle).reduce((a, c) => a + c.prix, 0) + " €"
              }
            />
          </div>
          <MaterialTable
            title=''
            isLoading={!intendances}
            columns={intendanceDashColumns}
            actions={[
              rw => ({
                icon: "refresh",
                tooltip: "Renouveler la mission d'intendance",
                onClick: (event, rowData) => {
                  this.setState(
                    {
                      refreshIntendance: {
                        appart: { appart: rw.Appartement },
                        data: {
                          ...rw,
                          prixHT: 100,
                          dateDepart: moment(rw.dateDepart)
                            .add(1, "y")
                            .toISOString()
                            .split("T")[0],
                          dateFin: moment(rw.dateFin)
                            .add(1, "y")
                            .toISOString()
                            .split("T")[0],
                          Appartement: undefined,
                          id: undefined,
                          regle: false,
                          renouvele: false,
                        },
                      },
                      markAsRefreshed: rw.id,
                    },
                    this.openTopRight
                  )
                },
              }),
            ]}
            data={intendances?.filter(
              i => !i.renouvele && moment().isAfter(moment(i.dateFin).subtract(1, "m"))
            )}
            onRowClick={(event, rowData) => this.props.history.push(`/missions/${rowData.id}`)}
            options={{
              filtering: true,
              pageSize: 5,
              actionsColumnIndex: -1,
            }}
          />
        </Paper>
        <Paper style={{ padding: 20, marginTop: 20 }}>
          <h2 className={c.title}>Appartement en préavis ou vacant</h2>
          <MaterialTable
            title=''
            isLoading={!appartements}
            columns={appartementDashColumns}
            data={appartements?.filter(a =>
              a.locataires.every(
                ({ Dossier }) =>
                  (Dossier.dateFinBail && moment().isAfter(Dossier.dateFinBail)) ||
                  (Dossier.dateDebutPreavis &&
                    Dossier.dateFinPreavis &&
                    moment().isBetween(
                      moment(Dossier.dateDebutPreavis),
                      moment(Dossier.dateFinPreavis)
                    ))
              )
            )}
            actions={[
              {
                icon: "edit",
                tooltip: "Modifier l'appartement",
                onClick: (event, rowData) =>
                  this.props.history.push(`/appartements/${rowData.id}/edit`),
              },
              {
                icon: "remove_red_eye",
                tooltip: "Voir les details de l'appartement",
                onClick: (event, rowData) => this.props.history.push(`/appartements/${rowData.id}`),
              },
            ]}
            onRowClick={(event, rowData) => this.props.history.push(`/missions/${rowData.id}`)}
            options={{
              filtering: true,
              pageSize: 5,
              actionsColumnIndex: -1,
            }}
          />
        </Paper>
        <Dialog
          maxWidth={false}
          onClose={_ => this.setState({ refreshIntendance: null, markAsRefreshed: null })}
          open={this.state.markAsRefreshed !== null ? true : false}>
          <div style={{ padding: 20 }}>
            <Form
              {...intendanceSchema(
                {},
                data => this.setState({ refreshIntendance: data }),
                false,
                true,
                appartements,
                this.props.variables?.tva.valeur
              )}
              formData={this.state.refreshIntendance}
              onSubmit={({ formData: { data, appart } }) => {
                console.log(appart)
                const proms = []
                if (this.state.markAsRefreshed !== null)
                  proms.push(Actions.setIntendance(this.state.markAsRefreshed, { renouvele: true }))
                proms.push(
                  dispatch(Actions.createIntendance({ ...data, AppartementId: appart.appart.id }))
                )
                Promise.all(proms).then(_ => {
                  this.componentDidMount()
                  this.setState({ refreshIntendance: null, markAsRefreshed: null })
                })
              }}
              onError={console.log}
            />
          </div>
        </Dialog>
      </div>
    )
  }
}

const styles = theme => ({
  title: { marginTop: 10, barginBottom: 30 },
})

const mapStateToProps = state => ({
  appartements: state.reducer.appartements,
  missions: state.reducer.missions,
  intendances: state.reducer.intendances?.map(m => ({
    ...m,
    Appartement: state.reducer.appartements?.find(a => a.id === m.AppartementId),
  })),
  currentUser: state.reducer.currentUser,
})
export default withRouter(
  withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(Dashboard)))
)
