import { Icon, LinearProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js"
import Card from "components/Card/Card.js"
import CardFooter from "components/Card/CardFooter.js"
import CardHeader from "components/Card/CardHeader.js"
import CardIcon from "components/Card/CardIcon.js"
import React from "react"

const useStyles = makeStyles(styles)


export default ({ icon, title, subtitle, data, subdata, bgcolor }) => {
  const c = useStyles()
  return <Card style={{ width: 240, margin: 5, marginRight: 10 }} className={"justify-o"}>
    <CardHeader color="warning" stats icon>
      <CardIcon bgcolor={bgcolor} color='warning'>
        <Icon>{icon}</Icon>
      </CardIcon>
      <p className={c.cardCategory} >{title}</p>
      {data === undefined ? <LinearProgress style={{ marginTop: 20 }} /> : <h3 className={c.cardTitle}>{data}</h3>}
    </CardHeader>
    <CardFooter stats >
      {(subtitle || subdata) && <div className={c.stats + " fullW  align-c justify-o"}>
        {subtitle ? <p className='no-margin'>{subtitle}</p> : null}
        {subdata ? <h5 className={c.cardTitle} style={{ textAlign: "right" }}>{subdata}</h5> : null}
      </div>}
    </CardFooter>
  </Card>
}