import React from "react"
import withReducer from "redux/withReducer"
import { withStyles } from "@material-ui/core/styles"
import {} from "assets/jss/material-dashboard-react.js"
import { connect } from "react-redux"
import reducer from "redux/reducers"
import Actions from "redux/actions"
import Button from "components/CustomButtons/Button.js"
import { withRouter } from "react-router"
import MaterialTable from "components/MaterialTable.js"
import PubSub from "pubsub-js"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { appartementListColumns } from "utils/columns"
import moment from "moment"
var dispatch

class AppartementsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { onlyIntendance: false }
    dispatch = this.props.dispatch
  }

  componentDidMount() {
    dispatch(Actions.getAppartements())
  }

  render() {
    const c = this.props.classes
    const { appartements, canDelete } = this.props
    console.log("appartement", appartements)
    return (
      <div className={c.container}>
        <FormControlLabel
          control={<Checkbox checked={this.state.onlyIntendance} onChange={_ => this.setState({ onlyIntendance: !this.state.onlyIntendance })} />}
          style={{ color: "black" }}
          label='En gestion'
        />

        <MaterialTable
          title=''
          isLoading={!appartements}
          data={
            this.state.onlyIntendance ? appartements.filter(a => a.Intendances.some(i => moment().isBetween(i.dateDepart, i.dateFin))) : appartements
          }
          columns={appartementListColumns}
          actions={[
            {
              icon: "edit",
              tooltip: "Modifier l'appartement",
              onClick: (event, rowData) => this.props.history.push(`/appartements/${rowData.id}/edit`),
            },
            {
              icon: "remove_red_eye",
              tooltip: "Voir les details de l'appartement",
              onClick: (event, rowData) => this.props.history.push(`/appartements/${rowData.id}`),
            },
            canDelete && {
              icon: "delete",
              tooltip: "Supprimer l'appartement",
              onClick: (event, rowData) =>
                PubSub.confirm({
                  title: "Supprimer l'element ?",
                  subtitle: "Cette action est irreversible.",
                  onAgree: _ => dispatch(Actions.deleteAppartement(rowData.id)),
                }),
            },
            {
              icon: p => <Button color='primary'>Nouvel Appartement</Button>,
              isFreeAction: true,
              onClick: event => this.props.history.push(`/appartements/new`),
            },
          ]}
          onRowClick={(event, rowData) => this.props.history.push(`/appartements/${rowData.id}`)}
          options={{
            filtering: true,
            pageSize: 20,
            actionsColumnIndex: -1,
          }}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container: {},
})

const mapStateToProps = state => ({
  appartements: state.reducer.appartements,
  canDelete: state.reducer.canDelete,
})
export default withRouter(withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(AppartementsList))))
