import { TextField } from "@material-ui/core"
import React from "react"

const rows = [["Mur", "Sol", "Plafond", "Fenetre"], ["Electricite", "Plomberie", "Serrure", "Observation"]]
const cols = [
  { key: "entree", disp: "Entrée" },
  { key: "sejour", disp: "Séjour" },
  { key: "cuisine", disp: "Cuisine" },
  { key: "eau", disp: "Salle d'eau" },
  { key: "wc", disp: "W.C" },
]
class EtatLieuxTable extends React.Component {
  constructor(props) {
    super(props)
    const initData = {}
    cols.forEach(col => {
      rows.forEach(rws => {
        rws.forEach(row => {
          initData[col.key + row] = props.etatLieux ? props.etatLieux[col.key + row] : ""
        })
        initData[col.key + "Nom"] = props.etatLieux ? props.etatLieux[col.key + "Nom"] : col.disp
      })
    })
    this.state = {
      data: initData,
    }
  }

  componentDidMount() {
    if (this.props.onChange) {
      this.props.onChange(this.state.data)
    }
  }

  render() {
    const { data } = this.state
    const { readOnly } = this.props
    const hStyle = { fontSize: 20, textAlign: "center" }
    const tableStyle = { margin: 10, width: "100%" }
    const roStyle = {
      textAlign: "center",
      width: "100%",
    }
    console.log("data", data)
    return (
      <div className='col align-c'>
        {[0, 1].map(id => (
          <table style={tableStyle} key={id}>
            <tr>
              <th />
              {rows[id].map(row => (
                <th style={hStyle} key={row}>
                  {row}
                </th>
              ))}
            </tr>
            {cols.map(col => (
              <tr key={col.key}>
                <th style={hStyle} className='text-r'>
                  {readOnly ? (
                    <TextField
                      variant='outlined'
                      inputProps={{ style: { color: "black", fontWeight: "bold" } }}
                      style={roStyle}
                      disabled
                      value={data[col.key + "Nom"]}
                    />
                  ) : (
                      <TextField
                        variant='outlined'
                        style={roStyle}
                        inputProps={{ style: { color: "black", fontWeight: "bold" } }}
                        value={data[col.key + "Nom"]}
                        onChange={e => {
                          const newData = { ...data, [col.key + "Nom"]: e.target.value }
                          this.setState({ data: newData })
                          this.props.onChange(newData)
                        }}
                      />
                    )}
                </th>
                {rows[id].map(row => (
                  <td key={row}>
                    {readOnly ? (
                      <TextField variant='outlined' inputProps={{ style: { color: "black" } }} style={roStyle} disabled value={data[col.key + row]} />
                    ) : (
                        <TextField
                          variant='outlined'
                          style={roStyle}
                          value={data[col.key + row]}
                          onChange={e => {
                            const newData = { ...data, [col.key + row]: e.target.value }
                            this.setState({ data: newData })
                            this.props.onChange(newData)
                          }}
                        />
                      )}
                  </td>
                ))}
              </tr>
            ))}
          </table>
        ))}
      </div>
    )
  }
}

export default EtatLieuxTable
