import axios from "axios"
import PubSub from "pubsub-js"

var axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "http://dev.atwio.eu:4200/api" : "http://dev.atwio.eu:4200/api",
})

axiosInstance.static = url => (url ? axiosInstance.defaults.baseURL + "/" + url : null)

axiosInstance.interceptors.response.use(
  rep => rep,
  error => {
    if (!error.response) return (window.location.pathname = "/404")
    if (error.response.status === 401 && window.location.pathname !== "/login") {
      window.location.pathname = "/login"
    }
    if (error.response.status === 403) {
      PubSub.notif({ txt: "Vous n'avez pas les droits suffisants pour effectuer cette action.", color: "danger" })
      throw error
    }
    return error
  }
)

export default axiosInstance
