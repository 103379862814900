import axios from "axios.js"
import Actions from "./CRUD.action"

export const SET_APPART_HISTORY = "[ASSOCIATIONS APP] SET_APPART_HISTORY"

export function addOwner(idAppart, idOwner) {
  const request = axios.put(`/Appartement/${idAppart}/owner/add/${idOwner}`)
  return dispatch =>
    request.then(response => {
      dispatch({ type: Actions.SET_CLIENT, payload: response.data.proprietaire })
      dispatch({ type: Actions.SET_APPARTEMENT, payload: response.data.appartement })
    })
}

export function removeOwner(idAppart, idOwner) {
  const request = axios.delete(`/Appartement/${idAppart}/owner/remove/${idOwner}`)
  return dispatch =>
    request.then(response => {
      dispatch({ type: Actions.SET_CLIENT, payload: response.data.proprietaire })
      dispatch({ type: Actions.SET_APPARTEMENT, payload: response.data.appartement })
    })
}

export function addTenant(idAppart, idTenant, dossier) {
  const request = axios.put(`/Appartement/${idAppart}/tenant/add/${idTenant}`, dossier)
  return dispatch =>
    axios.put(`/Appartement/${idAppart}`, { loyerHC: dossier.loyerHC, charge: dossier.loyerCharge }).then(_ => {
      return request.then(response => {
        dispatch({ type: Actions.SET_LOCATAIRE, payload: response.data.locataire })
        dispatch({ type: Actions.SET_APPARTEMENT, payload: response.data.appartement })
        dispatch({ type: SET_APPART_HISTORY, payload: response.data.history })
        return response.data
      })
    })
}

export function removeTenant(idAppart, idTenant) {
  const request = axios.delete(`/Appartement/${idAppart}/tenant/remove/${idTenant}`)
  return dispatch =>
    request.then(response => {
      dispatch({ type: Actions.SET_LOCATAIRE, payload: response.data.locataire })
      dispatch({ type: Actions.SET_APPARTEMENT, payload: response.data.appartement })
      dispatch({ type: SET_APPART_HISTORY, payload: response.data.history })
    })
}

export function addMissionCustomer(idMission, idClient) {
  const request = axios.put(`/Mission/${idMission}/client/add/${idClient}`)
  return dispatch =>
    request.then(response => {
      dispatch({ type: Actions.SET_MISSION, payload: response.data.mission })
      dispatch({ type: Actions.SET_CLIENT, payload: response.data.client })
    })
}

export function removeMissionCustomer(idMission, idClient) {
  const request = axios.delete(`/Mission/${idMission}/client/remove/${idClient}`)
  return dispatch =>
    request.then(response => {
      dispatch({ type: Actions.SET_MISSION, payload: response.data.mission })
      dispatch({ type: Actions.SET_CLIENT, payload: response.data.client })
    })
}

export function addMissionAccompte(idMission, idAccompte) {
  const request = axios.put(`/Mission/${idMission}/accompte/add/${idAccompte}`)
  return dispatch => request.then(response => dispatch({ type: Actions.SET_MISSION, payload: response.data }))
}

export function removeMissionAccompte(idMission, idAccompte) {
  const request = axios.delete(`/Mission/${idMission}/accompte/remove/${idAccompte}`)
  return dispatch => request.then(response => dispatch({ type: Actions.SET_MISSION, payload: response.data }))
}

export function addMissionAppartement(idMission, idAppart) {
  const request = axios.put(`/Mission/${idMission}/appartement/add/${idAppart}`)
  return dispatch => request.then(response => dispatch({ type: Actions.SET_MISSION, payload: response.data }))
}

export function removeMissionAppartement(idMission, idAppart) {
  const request = axios.put(`/Mission/${idMission}/appartement/remove/${idAppart}`)
  return dispatch => request.then(response => dispatch({ type: Actions.SET_MISSION, payload: response.data }))
}

export function setAppartementEquipement(idAppart, idEquipement, add) {
  const request = axios.put(`/Appartement/${idAppart}/equipement/${add ? "add" : "remove"}/${idEquipement}`)
  return dispatch => request.then(response => dispatch({ type: Actions.SET_APPARTEMENT, payload: response.data }))
}

export function removeAppartementImage(idAppart, idMission) {
  const request = axios.put(`/Appartement/${idAppart}/image/remove/${idMission}`)
  return dispatch => request.then(response => dispatch({ type: Actions.SET_APPARTEMENT, payload: response.data }))
}
export function addAppartementImage(idAppart, idMission) {
  const request = axios.put(`/Appartement/${idAppart}/image/add/${idMission}`)
  return dispatch => request.then(response => dispatch({ type: Actions.SET_APPARTEMENT, payload: response.data }))
}

export function addCautionDossier(idDossier, idCaution) {
  const request = axios.put(`/Dossier/${idDossier}/caution/add/${idCaution}`)
  return dispatch => request.then(response => dispatch({ type: Actions.SET_DOSSIER, payload: response.data.dossier }))
}

export function setEtatInDossier(idDossier, form) {
  const request = axios.put(`Dossier/${idDossier}/etatIn/set`, form)
  return dispatch => request.then(response => dispatch({ type: Actions.SET_DOSSIER, payload: response.data.dossier }))
}

export function setEtatOutDossier(idDossier, form) {
  const request = axios.put(`Dossier/${idDossier}/etatOut/set`, form)
  return dispatch => request.then(response => dispatch({ type: Actions.SET_DOSSIER, payload: response.data.dossier }))
}

const jsonToFormData = obj => {
  var form_data = new FormData()

  for (var key in obj) {
    form_data.append(key, obj[key])
  }
  return form_data
}

export function addFichierClient(idClient, form) {
  return dispatch =>
    axios
      .post(`Fichier`, jsonToFormData(form))
      .then(file =>
        axios
          .put(`Client/${idClient}/fichier/add/${file.data.id}`)
          .then(response => dispatch({ type: Actions.SET_CLIENT, payload: response.data.client }))
      )
}

export function addFichierLocataire(idLocataire, form) {
  return dispatch =>
    axios
      .post(`Fichier`, jsonToFormData(form))
      .then(file =>
        axios
          .put(`Locataire/${idLocataire}/fichier/add/${file.data.id}`)
          .then(response => dispatch({ type: Actions.SET_LOCATAIRE, payload: response.data.locataire }))
      )
}
