import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import { withStyles } from "@material-ui/core/styles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import "assets/css/form-auto.css"
import { } from "assets/jss/material-dashboard-react.js"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js"
import axios from "axios.js"
import AssociationList from "components/AssociationList"
import CustomTabs from "components/CustomTabs/CustomTabs"
import EtatLieuxTable from "components/EtatLieuxTable"
import Form from "components/MuiJsonForm"
import RowGenerateFile from "components/RowGenerateFile"
import PubSub from "pubsub-js"
import queryString from "query-string"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import Actions from "redux/actions"
import reducer from "redux/reducers"
import withReducer from "redux/withReducer"
import { cautionColumns, etatLieuxColumns } from "utils/columns"
import downloadFile from "utils/downloadFile"
import fileDialog from "utils/fileDialog"
import { cautionSchema, dossierSchema, etatLieuxSchema } from "utils/schemas"

var dispatch

class FormEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      formData: {
        data: {
          //loyerHC: 0,
          // loyerCharge: 0,
          loyerTTC: 0,
        },
        encadrement: {
          //  surface: 0,
          // nbPieces: 0,
          //complementLoyer: 0
        },
      },
      initLoad: true,
    }
    dispatch = this.props.dispatch
    this.isNew = props.match.params.id === "new"
    this.edit = props.match.params.edit ? true : false
    this.readOnly = !this.isNew && !this.edit
  }

  componentDidMount() {
    let actions = []
    if (!this.state.initLoad) this.setState({ loading: true, formData: {}, initLoad: true })
    actions.push(dispatch(Actions.getDossier(this.props.match.params.id)))
    if (!this.isNew) dispatch(Actions.getClients())
    else actions.push(dispatch(Actions.getDossier()))
    actions.push(
      dispatch(Actions.getAppartements()).then(rep => {
        if (queryString.parse(this.props.location.search).relatedAppartement)
          this.setState({
            formData: {
              data: {
                appart: rep.payload.find(
                  a => a.id == queryString.parse(this.props.location.search).relatedAppartement
                ),
              },
            },
          })
      })
    )
    actions.push(
      dispatch(Actions.getLocataires()).then(rep => {
        if (queryString.parse(this.props.location.search).relatedTenant)
          this.setState({
            formData: {
              data: {
                locataire: rep.payload.find(
                  a => a.id == queryString.parse(this.props.location.search).relatedTenant
                ),
              },
            },
          })
      })
    )
    actions.push(dispatch(Actions.getVariables()))
    Promise.all(actions).then(_ => this.setState({ loading: false, initLoad: false }))
  }

  componentDidUpdate(prevProps, prevState) {
    this.isNew = this.props.match.params.id === "new"
    this.edit = this.props.match.params.edit ? true : false
    this.readOnly = !this.isNew && !this.edit
    if (this.props.match.params !== prevProps.match.params) {
      this.componentDidMount()
    }
  }

  onSubmit = form => {
    if (this.readOnly) return
    console.log("validate", form)
    const body = { ...form.formData.data, ...form.formData.encadrement, ...form.formData.clauses }
    console.log("VALIDATE", body)
    this.setState({ loading: true })
      ; (this.isNew
        ? dispatch(Actions.addTenant(body.appart.id, body.locataire.id, body))
        : dispatch(Actions.setDossier(this.props.match.params.id, body))
      ).then(rep => {
        console.log("REP", rep)
        PubSub.notif({
          txt: this.isNew
            ? "Votre dossier à été crée avec succes."
            : "Vos modifications ont été prises en compte.",
          color: "success",
          icon: "save",
        })
        this.setState({ loading: false })
        if (this.isNew)
          this.props.history.push(
            queryString.parse(this.props.location.search).oncreate || `/dossiers/${rep.dossier.id}`
          )
      })
  }

  render() {
    const c = this.props.classes
    const { dossier } = this.props
    console.log("dossier", dossier)
    return (
      <div className={c.container}>
        <CustomTabs
          initTab={queryString.parse(this.props.location.search).tab}
          title={this.state.loading ? "" : (dossier ? "" : "Nouvelle Dossier").toUpperCase()}
          headerColor='info'
          rigthBtn={
            !this.isNew && {
              display: !this.edit,
              icon: "create",
              onClick: _ => this.props.history.push(this.props.match.url + "/edit"),
            }
          }
          tabs={[
            {
              tabName: "Details",
              tabIcon: "work_outline",
              tabContent: (
                <>
                  {this.props.variables && (
                    <Form
                      {...dossierSchema(
                        dossier,
                        data => this.setState({ formData: data }),
                        (dossier || {}).appartement,
                        (dossier || {}).locataire,
                        this.props.appartements,
                        this.props.locataires,
                        undefined,
                        this.props.variables && parseFloat(this.props.variables.indice.valeur),
                        this.props.variables && this.props.variables.caracLoyer.valeur,
                        this.isNew,
                        this.readOnly,
                        _ =>
                          this.props.history.push(
                            `/appartement/new?oncreate=${window.location.pathname}?tab=2`
                          ),
                        _ =>
                          this.props.history.push(
                            `/locataires/new?oncreate=${window.location.pathname}?tab=2`
                          ),
                        "createTxt",
                        this.props.variables && this.props.variables.tva.valeur,
                        this.props.variables && this.props.variables.indiceTrim.valeur,

                      )}
                      formData={this.state.formData}
                      initLoad={this.state.initLoad}
                      loading={this.state.loading}
                      readOnly={this.readOnly}
                      onSubmit={this.onSubmit}
                      onError={console.log}
                      removeEditOnSubmit
                    />
                  )}
                </>
              ),
            },
            {
              tabName: "Cautions",
              tabIcon: "lock",
              tabContent: (
                <AssociationList
                  title={`Caution${((dossier || {}).Cautions || []).length > 1 ? "s" : ""}`}
                  data={(dossier || {}).Cautions || []}
                  columns={cautionColumns}
                  detailPanel={rd => (
                    <div style={{ background: "#EEE", padding: 10 }}>
                      <Form
                        {...cautionSchema(rd)}
                        onSubmit={({ formData: { data } }) =>
                          dispatch(Actions.setCaution(rd.id, data)).then(_ =>
                            dispatch(Actions.getDossier(this.props.match.params.id))
                          )
                        }
                        onError={console.log}
                      />
                    </div>
                  )}
                  onTopRightButton={close => (
                    <Form
                      style={{ width: "100%" }}
                      {...cautionSchema({}, false, true)}
                      onSubmit={({ formData: { data } }) => {
                        dispatch(Actions.createCaution(data)).then(rep =>
                          dispatch(
                            Actions.addCautionDossier(this.props.match.params.id, rep.payload.id)
                          ).then(close)
                        )
                      }}
                      onError={console.log}
                    />
                  )}
                  topRightButtonTxt='Nouvelle caution'
                  noDataTxt='Aucune caution.'
                  removeTxt='Supprimer la caution'
                  onRemove={cautionId =>
                    dispatch(Actions.deleteCaution(cautionId)).then(_ =>
                      dispatch(Actions.getDossier(this.props.match.params.id))
                    )
                  }
                />
              ),
            },
            {
              tabName: "Etats des lieux",
              tabIcon: "assignment_turned_in",
              tabContent: (
                <>
                  <AssociationList
                    title={`Etat des lieux entrant`}
                    data={dossier && dossier.EtatEntrant ? [dossier.EtatEntrant] : []}
                    columns={etatLieuxColumns}
                    actions={[
                      (dossier.EtatEntrant || {}).signed
                        ? {
                          icon: "get_app",
                          tooltip: "Télécharger le document signé",
                          onClick: (event, rowData) =>
                            downloadFile(
                              dossier.EtatEntrant.signed,
                              "Etats_des_lieux_signe.docx"
                            ),
                        }
                        : null,
                      {
                        icon: "publish",
                        tooltip: "Envoyer le document signé",
                        onClick: (event, rowData) => this.fileDialogIn.click(),
                      },
                      {
                        icon: "assignment_returned",
                        tooltip: "Génerer le document vierge",
                        onClick: (event, rowData) =>
                          axios
                            .get(`Dossier/${this.props.match.params.id}/etatIn/generate`)
                            .then(rep =>
                              downloadFile(
                                rep.data.generated,
                                "Etats_des_lieux_entrant_non_signe.docx"
                              )
                            ),
                      },
                    ]}
                    detailPanel={rd => (
                      <div style={{ background: "#EEE", padding: 10 }}>
                        <EtatLieuxTable readOnly={true} etatLieux={(dossier || {}).EtatEntrant} />
                        <Form
                          {...etatLieuxSchema((dossier || {}).EtatEntrant, false, true)}
                          initLoad={this.state.initLoad}
                          loading={this.state.loading}
                          readOnly={true}
                          onError={console.log}
                        />
                      </div>
                    )}
                    onTopRightButton={close => (
                      <>
                        <EtatLieuxTable
                          readOnly={false}
                          etatLieux={(dossier || {}).EtatEntrant || dossier.appartement.EtatLieux}
                          onChange={data => this.setState({ newTableData: data })}
                        />
                        <Form
                          style={{ width: "100%" }}
                          {...etatLieuxSchema(
                            (dossier || {}).EtatEntrant || dossier.appartement.EtatLieux,
                            false,
                            false
                          )}
                          onSubmit={({ formData: { data } }) => {
                            PubSub.confirm({
                              title: "Remplacer l'état des lieux ?",
                              subtitle: "L'ancien état des lieux sera remplacé, et donc supprimé.",
                              onAgree: _ =>
                                dispatch(
                                  Actions.setEtatInDossier(this.props.match.params.id, {
                                    ...data,
                                    ...this.state.newTableData,
                                  })
                                ).then(close),
                            })
                          }}
                          onError={console.log}
                        />
                      </>
                    )}
                    topRightButtonTxt={
                      dossier.EtatEntrant
                        ? "Remplacer l'état des lieux entrant"
                        : "Créer un etat des lieu entrant"
                    }
                    noDataTxt='Aucun état des lieux entrant.'
                    removeTxt="Supprimer l'état des lieux entrant"
                    onRemove={etatId =>
                      dispatch(Actions.deleteEtatLieux(etatId)).then(_ => this.componentDidMount())
                    }
                  />
                  {(dossier.EtatEntrant || dossier.EtatSortant) && (
                    <div style={{ marginTop: 20 }}>
                      <AssociationList
                        title={`Etat des lieux sortant`}
                        data={dossier && dossier.EtatSortant ? [dossier.EtatSortant] : []}
                        columns={etatLieuxColumns}
                        actions={[
                          (dossier.EtatSortant || {}).signed
                            ? {
                              icon: "get_app",
                              tooltip: "Télécharger le document signé",
                              onClick: (event, rowData) =>
                                downloadFile(
                                  dossier.EtatSortant.signed,
                                  "Etats_des_lieux_signe.docx"
                                ),
                            }
                            : null,
                          {
                            icon: "publish",
                            tooltip: "Envoyer le document signé",
                            onClick: (event, rowData) => this.fileDialogOut.click(),
                          },
                          {
                            icon: "assignment_returned",
                            tooltip: "Génerer le document vierge",
                            onClick: (event, rowData) =>
                              axios
                                .get(`Dossier/${this.props.match.params.id}/etatOut/generate`)
                                .then(rep =>
                                  downloadFile(
                                    rep.data.generated,
                                    "Etats_des_lieux_entrant_non_signe.docx"
                                  )
                                ),
                          },
                        ]}
                        detailPanel={rd => (
                          <div style={{ background: "#EEE", padding: 10 }}>
                            <EtatLieuxTable
                              readOnly={true}
                              etatLieux={(dossier || {}).EtatSortant}
                            />
                            <Form
                              {...etatLieuxSchema((dossier || {}).EtatSortant, false, true)}
                              initLoad={this.state.initLoad}
                              loading={this.state.loading}
                              readOnly={true}
                              onError={console.log}
                            />
                          </div>
                        )}
                        onTopRightButton={close => (
                          <>
                            <EtatLieuxTable
                              readOnly={false}
                              etatLieux={
                                (dossier || {}).EtatSortant || (dossier.appartement || {}).EtatLieux
                              }
                              onChange={data => this.setState({ newTableData: data })}
                            />
                            <Form
                              style={{ width: "100%" }}
                              {...etatLieuxSchema(
                                (dossier || {}).EtatSortant || dossier.appartement.EtatLieux,
                                false,
                                false
                              )}
                              onSubmit={({ formData: { data } }) => {
                                PubSub.confirm({
                                  title: "Remplacer l'état des lieux ?",
                                  subtitle:
                                    "L'ancien état des lieux sera remplacé, et donc supprimé.",
                                  onAgree: _ =>
                                    dispatch(
                                      Actions.setEtatOutDossier(this.props.match.params.id, {
                                        ...data,
                                        ...this.state.newTableData,
                                      })
                                    ).then(close),
                                })
                              }}
                              onError={console.log}
                            />
                          </>
                        )}
                        topRightButtonTxt={
                          dossier.EtatSortant
                            ? "Remplacer l'état des lieux sortant"
                            : "Créer un etat des lieu sortant"
                        }
                        noDataTxt='Aucun état des lieux sortant.'
                        removeTxt="Supprimer l'état des lieux sortant"
                        onRemove={etatId =>
                          dispatch(Actions.deleteEtatLieux(etatId)).then(_ =>
                            this.componentDidMount()
                          )
                        }
                      />
                    </div>
                  )}
                </>
              ),
            },
            {
              tabName: "Documents",
              tabIcon: "description",
              tabContent: (
                <>
                  {((dossier || {}).locataire || {}).rp ? (
                    <RowGenerateFile
                      title='Contrat de location'
                      uploaded={dossier.contratLocation}
                      uploadedName='Contrat_Location.docx'
                      onUpload={_ =>
                        fileDialog("contratLocation")
                          .then(fd => dispatch(Actions.setDossier(dossier.id, fd)))
                          .then(_ => this.componentDidMount())
                      }
                      generateUrl={`Dossier/${dossier.id}/contratLocation/generate`}
                      generateName='Contrat_Location_non_signe.docx'
                    />
                  ) : (
                      <RowGenerateFile
                        title='Contrat de location (Résidence secondaire)'
                        uploaded={dossier.contratLocation}
                        uploadedName='Contrat_Location.docx'
                        onUpload={_ =>
                          fileDialog("contratLocation")
                            .then(fd => dispatch(Actions.setDossier(dossier.id, fd)))
                            .then(_ => this.componentDidMount())
                        }
                        generateUrl={`Dossier/${dossier.id}/contratLocation/generate`}
                        generateName='Contrat_Location_Secondaire_non_signe.docx'
                      />
                    )}
                  {(((dossier || {}).appartement || {}).Intendances || []).map(intendance => (
                    <RowGenerateFile
                      key={intendance.id}
                      title={`Ordre de mission d'Intendance - ${intendance.dateDepart}/${intendance.dateFin}`}
                      generateUrl={`Appartement/${dossier.appartement.id}/ordreMissionIntendance/${intendance.id}/generate`}
                      generateName={`Ordre_Mission_Intendance_${intendance.dateDepart}/${intendance.dateFin}_Non_Signé.docx`}
                      uploaded={intendance.ordreMission}
                      uploadedName={`Ordre_Mission_Intendance_${intendance.dateDepart}/${intendance.dateFin}.docx`}
                      onUpload={_ =>
                        fileDialog("ordreMission")
                          .then(fd => dispatch(Actions.setIntendance(intendance.id, fd)))
                          .then(_ => this.componentDidMount())
                      }
                    />
                  ))}
                  {dossier.EtatEntrant && (
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Etats des lieux et Inventaires</h4>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className='col'>
                        {dossier.EtatEntrant && (
                          <RowGenerateFile
                            title="Inventaire du mobilier d'entrée"
                            generateUrl={`Dossier/inventaireMobilier/${dossier.EtatEntrant.id}/generate`}
                            generateName='Inventaire_Mobilier_Vierge_Entrant_Non_Signé.xlsx'
                            uploaded={dossier.inventaireEntree}
                            uploadedName='Inventaire_Mobilier_Vierge_Entrant.xlsx'
                            onUpload={_ =>
                              fileDialog("inventaireEntree")
                                .then(fd => dispatch(Actions.setDossier(dossier.id, fd)))
                                .then(_ => this.componentDidMount())
                            }
                          />
                        )}
                        {dossier.EtatEntrant && (
                          <RowGenerateFile
                            title="Etat des lieux d'entrée"
                            uploaded={dossier.EtatEntrant.signed}
                            uploadedName='Etat_des_lieux_Entrant.docx'
                            onUpload={_ =>
                              fileDialog("signed")
                                .then(fd =>
                                  dispatch(Actions.setEtatLieux(dossier.EtatEntrant.id, fd))
                                )
                                .then(_ => this.componentDidMount())
                            }
                            generateUrl={`Dossier/${dossier.id}/etatIn/generate`}
                            generateName='Etats_des_lieux_Entrant_non_signe.docx'
                          />
                        )}
                        {dossier.EtatEntrant && (
                          <RowGenerateFile
                            title='Inventaire du mobilier de sortie'
                            generateUrl={`Dossier/inventaireMobilier/${dossier.EtatEntrant.id}/generate`}
                            generateName='Inventaire_Mobilier_Vierge_Sortant_Non_Signé.xlsx'
                            uploaded={dossier.inventaireSortie}
                            uploadedName='Inventaire_Mobilier_Vierge_Sortant.xlsx'
                            onUpload={_ =>
                              fileDialog("inventaireSortie")
                                .then(fd => dispatch(Actions.setDossier(dossier.id, fd)))
                                .then(_ => this.componentDidMount())
                            }
                          />
                        )}
                        {dossier.EtatSortant && (
                          <RowGenerateFile
                            title='Etat des lieux de sortie'
                            uploaded={dossier.EtatSortant.signed}
                            uploadedName='Etat_des_lieux_Sortant.docx'
                            onUpload={_ =>
                              fileDialog("signed")
                                .then(fd =>
                                  dispatch(Actions.setEtatLieux(dossier.EtatSortant.id, fd))
                                )
                                .then(_ => this.componentDidMount())
                            }
                            generateUrl={`Dossier/${dossier.id}/etatOut/generate`}
                            generateName='Etats_des_lieux_Sortant_non_signe.docx'
                          />
                        )}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )}
                  <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <h4>Locataire</h4>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className='col'>
                      <RowGenerateFile
                        title='Etiquette de locataire'
                        generateUrl={`Dossier/${dossier.id}/etiquetteLocataire/generate`}
                        generateName='Etiquette_locataire.docx'
                      />
                      {(((dossier || {}).locataire || {}).Fichiers || []).map(file => (
                        <RowGenerateFile
                          key={file.id}
                          title={file.nom}
                          uploaded={file.url}
                          uploadedName={file.nom + "." + file.extention}
                        />
                      ))}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  {(((dossier || {}).appartement || {}).proprietaires || []).some(
                    p => p.Fichiers && p.Fichiers.length
                  ) && (
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <h4>Client</h4>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='col'>
                          {(((dossier || {}).appartement || {}).proprietaires || []).map(prop =>
                            prop.Fichiers.map(file => (
                              <RowGenerateFile
                                key={file.id}
                                title={`${prop.nom} ${prop.prenom}_${file.nom}`}
                                uploaded={file.url}
                                uploadedName={file.nom + "." + file.extention}
                              />
                            ))
                          )}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )}

                  {(dossier || {}).Cautions && (
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Cautions</h4>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className='col'>
                        {((dossier || {}).Cautions || []).map(caution => (
                          <RowGenerateFile
                            key={caution.id}
                            title={`Etiquette de dossier de caution - ${caution.prenom} ${caution.nom}`}
                            generateUrl={`Dossier/etiquetteCaution/${caution.id}/generate`}
                            generateName={`Etiquette_dossier_caution_${caution.prenom}_${caution.nom}.docx`}
                          />
                        ))}
                        {((dossier || {}).Cautions || []).map(caution => (
                          <RowGenerateFile
                            key={caution.id}
                            title={`Acte de cautionnement - ${caution.prenom} ${caution.nom}`}
                            generateUrl={`Dossier/${dossier.id}/acteCautionnement/${caution.id}/generate`}
                            generateName={`Acte_Cautionnement_${caution.prenom}_${caution.nom}_Non_Signé.docx`}
                            uploaded={caution.acteCautionnementSigned}
                            uploadedName={`Acte_Cautionnement_${caution.prenom}_${caution.nom}.docx`}
                            onUpload={_ =>
                              fileDialog("acteCautionnementSigned")
                                .then(fd => dispatch(Actions.setCaution(caution.id, fd)))
                                .then(_ => this.componentDidMount())
                            }
                          />
                        ))}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )}
                </>
              ),
            },
          ].filter(t => !this.isNew || t.tabName === "Details")}
        />
        <input
          type='file'
          id='fileIn'
          ref={e => (this.fileDialogIn = e)}
          style={{ display: "none" }}
          onChange={e => {
            console.log(e.target)
            const fd = new FormData()
            fd.append("signed", e.target.files[0])
            dispatch(Actions.setEtatLieux(dossier.EtatEntrant.id, fd)).then(_ => {
              PubSub.notif({ txt: "Votre document à bien été envoyé.", color: "success" })
              this.componentDidMount()
            })
          }}
        />
        <input
          type='file'
          id='file'
          ref={e => (this.fileDialogOut = e)}
          style={{ display: "none" }}
          onChange={e => {
            console.log(e.target)
            const fd = new FormData()
            fd.append("signed", e.target.files[0])
            dispatch(Actions.setEtatLieux(dossier.EtatSortant.id, fd)).then(_ => {
              PubSub.notif({ txt: "Votre document à bien été envoyé.", color: "success" })
              this.componentDidMount()
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const dossier = state.reducer.dossier || {}
  dossier.locataire = (state.reducer.locataires || []).find(l => l.id === dossier.LocataireId)
  dossier.appartement = (state.reducer.appartements || []).find(l => l.id === dossier.AppartementId)
  return {
    dossier,
    clients: state.reducer.clients,
    variables: state.reducer.variables,
    appartements: state.reducer.appartements,
    locataires: state.reducer.locataires,
  }
}
export default withRouter(
  withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(FormEditor)))
)
