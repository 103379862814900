import { IconButton } from "@material-ui/core"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import { withStyles } from "@material-ui/core/styles"
import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons"
import DeleteIcon from "@material-ui/icons/Delete"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import "assets/css/form-auto.css"
import { } from "assets/jss/material-dashboard-react.js"
import axios from "axios.js"
import AssociationList from "components/AssociationList"
import CustomTabs from "components/CustomTabs/CustomTabs"
import EtatLieuxTable from "components/EtatLieuxTable"
import MaterialTable from "components/MaterialTable.js"
import Form from "components/MuiJsonForm"
import RowGenerateFile from "components/RowGenerateFile"
import _ from "lodash"
import { DropzoneArea } from "material-ui-dropzone"
import moment from "moment"
import PubSub from "pubsub-js"
import queryString from "query-string"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import Viewer from "react-viewer"
import Actions from "redux/actions"
import reducer from "redux/reducers"
import withReducer from "redux/withReducer"
import {
  clientColumns,
  dossierClientColumns,
  dossierHistoricColumns,
  intendanceAppartColumns,
  inventaireColumns, missionColumns,
  quitanceColumns
} from "utils/columns"
import downloadFile from "utils/downloadFile"
import fileDialog from "utils/fileDialog"
import { appartementSchema, etatLieuxSchema, intendanceSchema } from "utils/schemas"

var dispatch

class AppartementEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imgVisible: false,
      loading: true,
      formData: {},
      initLoad: true,
      addOwner: false,
      files: [],
      toDelete: [],
    }
    dispatch = this.props.dispatch
    this.isNew = props.match.params.id === "new"
    console.log(props)
    this.edit = props.match.params.edit ? true : false
    this.readOnly = !this.isNew && !this.edit
  }

  componentDidMount() {
    let actions = []
    if (!this.setState.initLoad)
      this.setState({
        imgVisible: false,
        loading: true,
        formData: {},
        initLoad: true,
        addOwner: false,
        files: [],
        toDelete: [],
      })
    actions.push(dispatch(Actions.getAppartement(this.props.match.params.id)))
    if (!this.isNew) actions.push(dispatch(Actions.getClients()))
    if (!this.isNew) actions.push(dispatch(Actions.getQuitances()))
    if (!this.isNew) actions.push(dispatch(Actions.getLocataires()))
    if (!this.isNew) actions.push(dispatch(Actions.getDossiers()))
    if (!this.isNew) dispatch(Actions.getMissions())
    else actions.push(dispatch(Actions.getAppartement()))
    actions.push(dispatch(Actions.getVariables()))
    actions.push(dispatch(Actions.getEquipements()))
    Promise.all(actions).then(_ => {
      this.setState({ loading: false, initLoad: false })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    this.isNew = this.props.match.params.id === "new"
    this.edit = this.props.match.params.edit ? true : false
    this.readOnly = !this.isNew && !this.edit
    if (this.props.match.params !== prevProps.match.params) {
      this.setState({ files: [], toDelete: [] })
      this.componentDidMount()
    }
  }

  onSubmit = form => {
    if (this.readOnly) return
    const body = {
      ...form.formData.carac,
      ...form.formData.equipments,
      ...form.formData.loyer,
      ...form.formData.gardien,
      ...form.formData.copro,
      ...form.formData.work,
    }
    this.setState({ loading: true })
    const num = form.formData.equipments.cave.caveNum
    body.caveNum = form.formData.equipments.cave.cave ? (num === null ? 0 : num) : null
    return (this.isNew
      ? dispatch(Actions.createAppartement(body))
      : dispatch(Actions.setAppartement(this.props.match.params.id, body))
    ).then(rep => {
      const proms = []
      this.state.files.forEach(file => {
        const formData = new FormData()
        formData.append("url", file)
        proms.push(
          dispatch(Actions.createImage(formData)).then(a =>
            Actions.addAppartementImage(rep.payload.id, a.payload.id)
          )
        )
      })
      this.state.toDelete.forEach(file => {
        Actions.removeAppartementImage(this.props.match.params.id, file.id)
      })
      Object.entries(form.formData.equipments).forEach(([nom, value]) => {
        console.log("equipements", this.props.equipements, nom, value)
        if (value === true || value === false) {
          Actions.setAppartementEquipement(
            rep.payload.id,
            this.props.equipements.find(equi => equi.nom === nom).id,
            value
          )
        }
      })
      return Promise.all(proms).then(_ => {
        PubSub.notif({
          txt: this.isNew
            ? "Votre appartement à été crée avec succes."
            : "Vos modifications ont été prises en compte.",
          color: "success",
          icon: "save",
        })
        console.log("TOPPAGE", this.topPage)

        this.setState({ loading: false })
        this.setState({ files: [], toDelete: [] })
        if (this.isNew)
          this.props.history.push(
            queryString.parse(this.props.location.search).oncreate ||
            `/appartements/${rep.payload.id}`
          )
      })
    })
  }

  render() {
    const c = this.props.classes
    const { appartement } = this.props
    console.log("appartement", appartement)
    return (
      <div className={c.container}>
        <CustomTabs
          initTab={queryString.parse(this.props.location.search).tab}
          title={
            this.state.loading
              ? "Chargement en cours..."
              : (!this.isNew ? appartement.adresse || "" : "Nouvel Appartement").toUpperCase()
          }
          headerColor='info'
          rigthBtn={
            !this.isNew && {
              display: !this.edit,
              icon: "create",
              onClick: _ => this.props.history.push(this.props.match.url + "/edit"),
            }
          }
          tabs={[
            {
              tabName: "Details",
              tabIcon: "home",
              tabContent: (
                <>
                  <Form
                    {...appartementSchema(
                      this.props.appartement,
                      this.props.equipements,
                      (nom, collectif) => {
                        if (!nom || !nom.length) return
                        dispatch(Actions.createEquipement({ nom, collectif }))
                      },
                      this.state.loading ? 0 : parseFloat(this.props.variables.indice.valeur),
                      this.readOnly,
                      this.isNew
                    )}
                    formData={this.state.formData}
                    initLoad={this.state.initLoad}
                    loading={this.state.loading}
                    readOnly={this.readOnly}
                    onChange={({ formData }) => {
                      const obj = _.cloneDeep(formData)
                      obj.loyer.loyerTCC =
                        (parseInt(obj.loyer.charge) || 0) + (parseInt(obj.loyer.loyerHC) || 0)
                      this.setState({ formData: obj })
                    }}
                    onSubmit={this.onSubmit}
                    onError={console.log}
                    removeEditOnSubmit>
                    {!this.state.loading && !this.readOnly && (
                      <DropzoneArea
                        acceptedFiles={["image/*"]}
                        filesLimit={20}
                        dropzoneText='Cliquez ou deposez vos images ici'
                        onChange={files => this.setState({ files })}
                        maxFileSize={100000000}
                      />
                    )}
                    {!this.state.loading && !this.isNew && (
                      <div className='row wrap fullW'>
                        {appartement.Images.filter(img => !this.state.toDelete.includes(img))
                          .length > 0 || !this.readOnly ? (
                            <>
                              {appartement.Images.filter(
                                img => !this.state.toDelete.includes(img)
                              ).map((img, id) => (
                                <div
                                  key={img.url}
                                  style={{
                                    width: 200,
                                    height: 200,
                                    objectFit: "contain",
                                    margin: 10,
                                    position: "relative",
                                  }}>
                                  <img
                                    alt='appartement'
                                    onClick={_ => this.setState({ imgVisible: id })}
                                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                    src={img.url}
                                  />
                                  {!this.readOnly && (
                                    <IconButton
                                      style={{ position: "absolute", top: 10, right: 10 }}
                                      onClick={_ =>
                                        this.setState({ toDelete: [...this.state.toDelete, img] })
                                      }>
                                      <DeleteIcon
                                        style={{
                                          backgroundColor: "white",
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          padding: 10,
                                          borderRadius: 40,
                                        }}
                                      />
                                    </IconButton>
                                  )}
                                </div>
                              ))}
                              <Viewer
                                visible={this.state.imgVisible !== false}
                                downloadable={true}
                                activeIndex={this.state.imgVisible}
                                onClose={() => this.setState({ imgVisible: false })}
                                images={appartement.Images.filter(
                                  img => !this.state.toDelete.includes(img)
                                ).map(img => ({ src: img.url }))}
                              />
                            </>
                          ) : (
                            <h3
                              style={{
                                margin: 0,
                                width: "100%",
                                textAlign: "left",
                                fontWeight: 500,
                                fontSize: "0.875rem",
                              }}>
                              AUCUNE IMAGE ATTACHÉE
                            </h3>
                          )}
                      </div>
                    )}
                  </Form>
                </>
              ),
            },
            {
              tabName: "Propriétaires",
              tabIcon: "vpn_key",
              tabContent: (
                <AssociationList
                  data={(this.props.appartement || {}).proprietaires || []}
                  columns={clientColumns}
                  noDataTxt='Aucun propriétaire assigné.'
                  detailTxt='Voir les details du propriétaire'
                  onDetails={o => this.props.history.push(`/client/${o.id}`)}
                  hideHeader={true}
                  actions={[
                    rd => ({
                      icon: appartement.defaultContact === rd.id ? "radio_button_checked" : "radio_button_unchecked",
                      tooltip: "Définir comme propriétaire de contact.",
                      onClick: (_, rowData) => dispatch(Actions.setAppartement(this.props.match.params.id, { defaultContact: rd.id })).then(_ => this.componentDidMount()),
                    })]}
                  removeTxt='Retirer le proprietéire'
                  onRemove={clientId =>
                    dispatch(Actions.removeOwner(this.props.appartement.id, clientId)).then(() =>
                      this.componentDidMount()
                    )
                  }
                  onAdd={clientId =>
                    dispatch(Actions.addOwner(this.props.appartement.id, clientId)).then(() =>
                      this.componentDidMount()
                    )
                  }
                  addTxt='Sélectionner un propriétaire'
                  addData={this.props.clients}
                  addColumn={clientColumns}
                  createTxt='Créer un propriétaire'
                  onCreate={_ =>
                    this.props.history.push(
                      `/client/new?oncreate=${window.location.pathname}?tab=1`
                    )
                  }
                />
              ),
            },
            {
              tabName: "Dossiers",
              tabIcon: "supervised_user_circle",
              tabContent: (
                <>
                  <AssociationList
                    title={`Dossier${((this.props.appartement || {}).locataires || []).length > 1 ? "s" : ""
                      } en cours`}
                    data={(this.props.dossiers || []).filter(
                      d => !d.dateFinBail || moment().isBefore(moment(d.dateFinBail))
                    )}
                    columns={dossierClientColumns}
                    onDetails={locat => this.props.history.push("/Dossiers/" + locat.id)}
                    actions={[
                      {
                        icon: "edit",
                        tooltip: "Editer le dossier",
                        onClick: (event, rowData) =>
                          this.props.history.push("/Dossiers/" + rowData.id + "/edit"),
                      },
                    ]}
                    detailTxt='Voir les details du dossier'
                    topRightButtonTxt='Nouveau Dossier'
                    topRightDialogWidth='90%'
                    onTopRightButtonFn={_ =>
                      this.props.history.push(
                        "/Dossiers/new?relatedAppartement=" + this.props.appartement.id
                      )
                    }
                    noDataTxt='Aucun dossier en cours.'
                    removeTxt='Clore le dossier'
                    onRemove={(_, row) =>
                      dispatch(
                        Actions.removeTenant(this.props.appartement.id, row.locataire.id)
                      ).then(_ => this.componentDidMount())
                    }
                  />
                  <h3>Historique des dossiers</h3>
                  <AssociationList
                    data={(this.props.dossiers || []).filter(d =>
                      moment().isSameOrAfter(moment(d.dateFinBail))
                    )}
                    columns={dossierHistoricColumns}
                    noDataTxt='Aucun historique.'
                    detailTxt='Voir les details du dossier'
                    removeTxt='Supprimer ce dossier'
                    onRemove={dossierId => dispatch(Actions.deleteDossier(dossierId))}
                    onDetails={o => this.props.history.push(`/Dossiers/${o.id}`)}
                  />
                </>
              ),
            },
            {
              tabName: "Missions",
              tabIcon: "work",
              tabContent: (
                <AssociationList
                  data={(this.props.missions || []).filter(m =>
                    (m.Appartements || []).some(app => app.id == this.props.match.params.id)
                  )}
                  columns={missionColumns}
                  noDataTxt='Aucune mission liée.'
                  detailTxt='Voir les details de la mission'
                  onRemove={missionId =>
                    dispatch(Actions.removeMissionAppartement(missionId, this.props.appartement.id))
                  }
                  onAdd={missionId =>
                    dispatch(Actions.addMissionAppartement(missionId, this.props.appartement.id))
                  }
                  addTxt='Sélectionner une mission'
                  addData={this.props.missions}
                  addColumn={missionColumns}
                  removeTxt='Retirer la mission'
                  createTxt='Créer une mission'
                  onCreate={_ =>
                    this.props.history.push(
                      `/missions/new?oncreate=${window.location.pathname}?tab=3`
                    )
                  }
                  onDetails={o => this.props.history.push(`/missions/${o.id}`)}
                />
              ),
            },
            {
              tabName: "Intendance",
              tabIcon: "home_work",
              tabContent: (
                <>
                  <AssociationList
                    title={`Missions d'intendance${((this.props.appartement || {}).Intendances || []).length > 1 ? "s" : ""
                      } en cours`}
                    data={((this.props.appartement || {}).Intendances || []).filter(
                      it => new Date(it.dateFin).getTime() >= Date.now()
                    )}
                    columns={intendanceAppartColumns}
                    detailPanel={rd => (
                      <div style={{ background: "#EEE", padding: 10 }}>
                        <RowGenerateFile
                          key={rd.id}
                          title={`Ordre de mission d'Intendance`}
                          generateUrl={`Appartement/${rd.AppartementId}/ordreMissionIntendance/${rd.id}/generate`}
                          generateName={`Ordre_Mission_Intendance_Non_Signé.docx`}
                          uploaded={rd.ordreMission}
                          uploadedName={`Ordre_Mission_Intendance.docx`}
                          onUpload={_ =>
                            fileDialog("ordreMission")
                              .then(fd => dispatch(Actions.setIntendance(rd.id, fd)))
                              .then(_ => this.componentDidMount())
                          }
                        />
                        <Form
                          {...intendanceSchema(
                            rd,
                            data => this.setState({ ["FormDataIntendance_" + rd.id]: data }),
                            false,
                            false
                          )}
                          formData={this.state["FormDataIntendance_" + rd.id]}
                          onSubmit={({ formData }) =>
                            dispatch(Actions.setIntendance(rd.id, formData.data)).then(_ =>
                              dispatch(Actions.getAppartement(this.props.match.params.id))
                            )
                          }
                          onError={console.log}
                        />
                      </div>
                    )}
                    onTopRightButton={close => (
                      <Form
                        {...intendanceSchema(
                          {},
                          data => this.setState({ FormDataIntendance_New: data }),
                          false,
                          true,
                          null,
                          this.props.variables?.tva.valeur
                        )}
                        formData={this.state.FormDataIntendance_New}
                        onSubmit={({ formData: { data } }) =>
                          dispatch(
                            Actions.createIntendance({
                              ...data,
                              AppartementId: this.props.match.params.id,
                            })
                          ).then(_ =>
                            dispatch(Actions.getAppartement(this.props.match.params.id)).then(close)
                          )
                        }
                        onError={console.log}
                      />
                    )}
                    topRightButtonTxt="Nouvelle Mission d'Intendance"
                    noDataTxt="Aucune mission d'intendance en cours"
                    removeTxt='Supprimer la mission'
                    onRemove={intendanceId =>
                      dispatch(Actions.deleteIntendance(intendanceId)).then(_ =>
                        dispatch(Actions.getAppartement(this.props.match.params.id))
                      )
                    }
                  />
                  <h3>Historique des missions d'intendance</h3>
                  <AssociationList
                    data={((this.props.appartement || {}).Intendances || []).filter(
                      it => new Date(it.dateFin).getTime() < Date.now()
                    )}
                    columns={intendanceAppartColumns}
                    /* detailPanel={rd => {
                      console.log(rd)
                      return (
                        <div style={{ background: "#EEE", padding: 10 }}>
                          <Form
                            {...intendanceSchema(rd, _ => null, true, true)}
                            readOnly={true}
                            onSubmit={({ formData }) =>
                              dispatch(Actions.setIntendance(rd.id, formData.data)).then(_ =>
                                dispatch(Actions.getAppartement(this.props.match.params.id))
                              )
                            }
                            onError={console.log}
                          />
                        </div>
                      )
                    }}*/
                    actions={[
                      {
                        icon: "swap_horiz",
                        tooltip: "changer le status de paiement",
                        onClick: (event, rowData) =>
                          dispatch(
                            Actions.setIntendance(rowData.id, { regle: !rowData.regle })
                          ).then(_ => dispatch(Actions.getAppartement(this.props.match.params.id))),
                      },
                    ]}
                    noDataTxt='Aucun historique'
                    removeTxt="Supprimer la ligne de l'historique"
                    onRemove={intendanceId =>
                      dispatch(Actions.deleteIntendance(intendanceId)).then(_ =>
                        dispatch(Actions.getAppartement(this.props.match.params.id))
                      )
                    }
                  />
                </>
              ),
            },
            /*   {
                 tabName: "Inventaires",
                 tabIcon: "dns",
                 tabContent: (
                   <AssociationList
                     data={(this.props.appartement || {}).dossiers || []}
                     columns={inventaireColumns}
                     actions={[
                       rd => ({
                         icon: "flight_land",
                         tooltip: rd.inventaireEntree
                           ? "Télécharger l'inventaire ENTRANT"
                           : "Aucun inventaire d'ENTREE",
                         disabled: !rd.inventaireEntree,
                         onClick: (_, rowData) =>
                           downloadFile(rowData.inventaireEntree, "Inventaire_Entrant.xlsx"),
                       }),
                       rd => ({
                         icon: "flight_takeoff",
                         disabled: !rd.inventaireSortie,
                         tooltip: rd.inventaireSortie
                           ? "Télécharger l'inventaire SORTANT"
                           : "Aucun inventaire de SORTIE",
                         onClick: (_, rowData) =>
                           downloadFile(rowData.inventaireSortie, "Inventaire_Sortant.xlsx"),
                       }),
                       {
                         icon: "edit",
                         tooltip: "Editer les inventaires",
                         onClick: (_, rowData) =>
                           this.props.history.push("/Dossiers/" + rowData.id + "?tab=3"),
                       },
                     ]}
                     noDataTxt='Aucune inventaire lié.'
                   />
                 ),
               },*/
            {
              tabName: "Etat des lieux",
              tabIcon: "assignment_turned_in",
              tabContent: (
                <>
                  <EtatLieuxTable
                    readOnly={false}
                    etatLieux={this.state.etatLieux || (appartement || {}).EtatLieux}
                    onChange={data => this.setState({ etatLieux: data })}
                  />
                  <Form
                    style={{ width: "100%" }}
                    {...etatLieuxSchema((appartement || {}).EtatLieux, false, false)}
                    loading={this.state.loading}
                    onSubmit={({ formData: { data } }) => {
                      this.setState({ loading: true })
                      let action
                      if (!appartement.EtatLieux) {
                        action = dispatch(
                          Actions.createEtatLieux({
                            ...data,
                            ...this.state.etatLieux,
                            AppartementId: appartement.id,
                          })
                        ).then(_ => this.componentDidMount())
                      } else {
                        action = dispatch(
                          Actions.setEtatLieux(appartement.EtatLieux.id, {
                            ...data,
                            ...this.state.etatLieux,
                          })
                        )
                      }
                      action.then(_ => {
                        this.setState({ loading: false })
                        PubSub.notif({
                          txt: "Vos modifications ont été prises en compte.",
                          color: "success",
                          icon: "save",
                        })
                      })
                    }}
                    onError={console.log}
                  />
                </>
              ),
            },
            {
              tabName: "Fichiers",
              tabIcon: "description",
              tabContent: (
                <>
                  {(this.props.missions || [])
                    .filter(m =>
                      (m.Appartements || []).some(app => app.id == this.props.match.params.id)
                    )
                    .map(mission => (
                      <RowGenerateFile
                        key={mission.id}
                        title={`Ordre de mission`}
                        generateUrl={`Mission/${mission.id}/ordreMission/generate`}
                        generateName={`Ordre_Missio_Non_Signé.docx`}
                        uploaded={mission.ordreMission}
                        uploadedName={`Ordre_Mission.docx`}
                        onUpload={_ =>
                          fileDialog("ordreMission").then(fd =>
                            dispatch(Actions.setMission(mission.id, fd))
                          )
                        }
                      />
                    ))}
                  {(appartement || {}.Intendances) && (
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Intendances</h4>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className='col'>
                        {(appartement || {}).Intendances.map(intendance => (
                          <RowGenerateFile
                            key={intendance.id}
                            title={`Ordre de mission d'Intendance`}
                            generateUrl={`Appartement/${appartement.id}/ordreMissionIntendance/${intendance.id}/generate`}
                            generateName={`Ordre_Mission_Intendance_Non_Signé.docx`}
                            uploaded={intendance.ordreMission}
                            uploadedName={`Ordre_Mission_Intendance.docx`}
                            onUpload={_ =>
                              fileDialog("ordreMission")
                                .then(fd => dispatch(Actions.setIntendance(intendance.id, fd)))
                                .then(_ => this.componentDidMount())
                            }
                          />
                        ))}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )}
                  {((appartement || {}).proprietaires || []).some(
                    p => p.Fichiers && p.Fichiers.length
                  ) && (
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <h4>Clients</h4>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='col'>
                          {((appartement || {}).proprietaires || []).map(prop =>
                            prop.Fichiers.map(file => (
                              <RowGenerateFile
                                key={file.id}
                                title={`${prop.nom} ${prop.prenom}_${file.nom}`}
                                uploaded={file.url}
                                uploadedName={file.nom + "." + file.extention}
                              />
                            ))
                          )}
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )}
                  {(this.props.quitances || []).some(
                    q => q.AppartementId === (appartement || {}).id
                  ) && (
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <h4>Quitances</h4>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='col'>
                          <MaterialTable
                            title=''
                            isLoading={!(this.props.quitances && appartement)}
                            columns={quitanceColumns}
                            actions={[
                              {
                                icon: "assignment_returned",
                                tooltip: "Générer la quitance",
                                onClick: (event, rowData) =>
                                  axios
                                    .get(`Quitance/${rowData.id}/generate`)
                                    .then(rep => downloadFile(rep.data.generated, "Quitance.docx")),
                              },
                            ]}
                            data={(this.props.quitances || [])
                              .filter(q => q.AppartementId === (appartement || {}).id)
                              .sort(
                                (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                              )}
                            onRowClick={_ => null}
                            options={{
                              toolbar: true,
                              header: true,
                              filtering: false,
                              actionsColumnIndex: -1,
                            }}
                          />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )}
                </>
              ),
            },
          ]
            .filter(t => !this.isNew || t.tabName === "Details")
            .filter(t => t.tabName !== "Missions" || this.props.missions)}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container: {
    marginTop: 30,
  },
  formCard: {
    margin: "auto",
  },
})

const mapStateToProps = state => {
  const appartement = state.reducer.appartement || {}
  //appartement.dossiers = (appartement.locataires || []).map(loc => ({ ...loc.Dossier, locataire: { ...loc }, appartement }))
  return {
    appartement: state.reducer.appartement,
    clients: state.reducer.clients,
    locataires: state.reducer.locataires,
    missions: state.reducer.missions,
    equipements: state.reducer.equipements,
    variables: state.reducer.variables,
    quitances: state.reducer.quitances,
    dossiers: state.reducer.dossiers
      ?.filter(d => d.AppartementId === appartement.id)
      .map(d => ({ ...d, locataire: state.reducer.locataires?.find(l => l.id === d.LocataireId) })),
  }
}
export default withRouter(
  withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(AppartementEditor)))
)
