import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import { withStyles } from "@material-ui/core/styles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import "assets/css/form-auto.css"
import { } from "assets/jss/material-dashboard-react.js"
import axios from "axios.js"
import AssociationList from "components/AssociationList"
import CustomTabs from "components/CustomTabs/CustomTabs"
import MaterialTable from "components/MaterialTable.js"
import Form from "components/MuiJsonForm"
import RowGenerateFile from "components/RowGenerateFile"
import PubSub from "pubsub-js"
import queryString from "query-string"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import Actions from "redux/actions"
import reducer from "redux/reducers"
import withReducer from "redux/withReducer"
import { dossierAppartColumns, fichierColumns, quitanceColumns } from "utils/columns"
import downloadFile from "utils/downloadFile"
import fileDialog from "utils/fileDialog"
import { fichierSchema, locataireSchema } from "utils/schemas"
var dispatch

class FormEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: true, formData: {}, initLoad: true, formDataDossier_New: { data: {} } }
    dispatch = this.props.dispatch
    this.isNew = props.match.params.id === "new"
    this.edit = props.match.params.edit ? true : false
    this.readOnly = !this.isNew && !this.edit
  }

  componentDidMount() {
    let action = []
    if (!this.state.initLoad) this.setState({ loading: true, formData: {}, initLoad: true, formDataDossier_New: { data: {} } })
    action.push(dispatch(Actions.getLocataire(this.props.match.params.id)))
    if (!this.isNew) action.push(dispatch(Actions.getAppartements()))
    else action.push(dispatch(Actions.getLocataire()))
    action.push(dispatch(Actions.getVariables()))
    Promise.all(action).then(_ => this.setState({ loading: false, initLoad: false }))
  }

  componentDidUpdate(prevProps, prevState) {
    this.isNew = this.props.match.params.id === "new"
    this.edit = this.props.match.params.edit ? true : false
    this.readOnly = !this.isNew && !this.edit
    if (this.props.match.params !== prevProps.match.params) {
      this.componentDidMount()
    }
  }

  onSubmit = form => {
    if (this.readOnly) return
    const { personal, secondaire, isMineur, ...rest } = form.formData
    const body = { ...personal, ...secondaire, ...isMineur, ...rest }
    if (!body.secondTitulaire) body.autreNom = null
    console.log("VALIDATE", body)
    this.setState({ loading: true })
    return (this.isNew ? dispatch(Actions.createLocataire(body)) : dispatch(Actions.setLocataire(this.props.match.params.id, body))).then(rep => {
      PubSub.notif({
        txt: this.isNew ? "Votre locataire à été crée avec succes." : "Vos modifications ont été prises en compte.",
        color: "success",
        icon: "save",
      })
      this.setState({ loading: false })
      if (this.isNew) this.props.history.push(queryString.parse(this.props.location.search).oncreate || `/locataires/${rep.payload.id}`)
    })
  }

  render() {
    const c = this.props.classes
    const { locataire, canDelete } = this.props
    console.log("Locataire", locataire, this.state.formData)
    return (
      <div className={c.container}>
        <CustomTabs
          initTab={queryString.parse(this.props.location.search).tab}
          title={
            this.state.loading ? "Chargement en cours..." : (!this.isNew ? locataire.nom + " " + locataire.prenom : "Nouveau Locataire").toUpperCase()
          }
          headerColor='info'
          rigthBtn={!this.isNew && { display: !this.edit, icon: "create", onClick: _ => this.props.history.push(this.props.match.url + "/edit") }}
          tabs={[
            {
              tabName: "Details",
              tabIcon: "assignment_ind",
              tabContent: (
                <Form
                  initLoad={this.state.initLoad}
                  loading={this.state.loading}
                  readOnly={this.readOnly}
                  {...locataireSchema(this.props.locataire, this.readOnly, this.isNew)}
                  onChange={({ formData }) => this.setState({ formData })}
                  formData={this.state.formData}
                  onSubmit={this.onSubmit}
                  onError={console.log}
                  removeEditOnSubmit
                />
              ),
            },
            {
              tabName: "Dossiers",
              tabIcon: "home",
              tabContent: (
                <AssociationList
                  title={`Dossier${((locataire || {}).dossiers || []).length > 1 ? "s" : ""} en cours`}
                  data={(locataire || {}).dossiers || []}
                  columns={dossierAppartColumns}
                  onDetails={locat => this.props.history.push("/Dossiers/" + locat.id)}
                  detailTxt='Voir les details du dossier'
                  actions={[
                    {
                      icon: "edit",
                      tooltip: "Editer le dossier",
                      onClick: (event, rowData) => this.props.history.push("/Dossiers/" + rowData.id + "/edit"),
                    },
                  ]}
                  topRightButtonTxt='Nouveau Dossier'
                  topRightDialogWidth='90%'
                  onTopRightButtonFn={_ => this.props.history.push("/Dossiers/new?relatedTenant=" + locataire.id)}
                  noDataTxt='Aucun dossier en cours.'
                  removeTxt='Clore le dossier'
                  onRemove={(_, row) => dispatch(Actions.removeTenant(row.appartement.id, locataire.id))}
                />
              ),
            },
            {
              tabName: "Fichiers",
              tabIcon: "description",
              tabContent: (
                <>
                  <h3>{`Fichier${((this.props.locataire || {}).Fichiers || []).length > 1 ? "s" : ""} lié au locataire`}</h3>
                  <AssociationList
                    data={(this.props.locataire || {}).Fichiers || []}
                    noDataTxt='Aucune fichier lié au locataire.'
                    topRightButtonTxt='Ajouter un fichier'
                    onTopRightButton={close => (
                      <Form
                        style={{ width: "100%" }}
                        formData={this.state.newFileFormData}
                        {...fichierSchema({}, data => this.setState({ newFileFormData: data }), true, false)}
                        onSubmit={({ formData: { data } }) => {
                          dispatch(Actions.addFichierLocataire(this.props.match.params.id, data)).then(close)
                        }}
                        onError={console.log}
                      />
                    )}
                    columns={fichierColumns}
                    actions={[
                      {
                        icon: "get_app",
                        tooltip: "Télécharger le fichier",
                        onClick: (event, rowData) =>
                          downloadFile(rowData.url, `${locataire.nom} ${locataire.prenom}_${rowData.nom}.${rowData.extention}`),
                      },
                      {
                        icon: "publish",
                        tooltip: "Changer de fichier",
                        onClick: (event, rowData) => fileDialog("url").then(fd => dispatch(Actions.setFichier(rowData.id, fd))),
                      },
                      canDelete && {
                        icon: "delete",
                        tooltip: "supprimer le fichier",
                        onClick: (event, rowData) =>
                          PubSub.confirm({
                            title: "Supprimer le fichier ?",
                            subtitle: "Cette action est irreversible.",
                            onAgree: _ => dispatch(Actions.deleteFichier(rowData.id)).then(_ => this.componentDidMount()),
                          }),
                      },
                    ]}
                  />
                  {(locataire || {}).dossiers.map((dossier, l) => (
                    <RowGenerateFile
                      key={dossier.id}
                      title={`Dossier n°${l + 1}: Etiquette de locataire`}
                      generateUrl={`Dossier/${dossier.id}/etiquetteLocataire/generate`}
                      generateName='Etiquette_locataire.docx'
                    />
                  ))}
                  {(this.props.quitances || []).some(q => q.LocataireId === (locataire || {}).id) && (
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <h4>Quitances</h4>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className='col'>
                        <MaterialTable
                          title=''
                          isLoading={!(this.props.quitances && locataire)}
                          columns={quitanceColumns}
                          actions={[
                            {
                              icon: "assignment_returned",
                              tooltip: "Générer la quitance",
                              onClick: (event, rowData) =>
                                axios.get(`Quitance/${rowData.id}/generate`).then(rep => downloadFile(rep.data.generated, "Quitance.docx")),
                            },
                          ]}
                          data={(this.props.quitances || [])
                            .filter(q => q.LocataireId === (locataire || {}).id)
                            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())}
                          onRowClick={_ => null}
                          options={{
                            toolbar: true,
                            header: true,
                            filtering: false,
                            actionsColumnIndex: -1,
                          }}
                        />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )}
                </>
              ),
            },
          ].filter(t => !this.isNew || t.tabName === "Details")}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container: {
    marginTop: 30,
  },
  formCard: {
    margin: "auto",
  },
})

const mapStateToProps = state => {
  const locataire = state.reducer.locataire || {}
  locataire.dossiers = (locataire.locations || []).map(loc => ({ ...loc.Dossier, appartement: { ...loc }, locataire }))
  return {
    locataire,
    appartement: state.reducer.appartement,
    locataires: state.reducer.locataires,
    variables: state.reducer.variables,
    canDelete: state.reducer.canDelete,
    quitances: state.reducer.quitances,
  }
}
export default withRouter(withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(FormEditor))))
