import React from "react"
import withReducer from "redux/withReducer"
import { withStyles } from "@material-ui/core/styles"
import { } from "assets/jss/material-dashboard-react.js"
import { connect } from "react-redux"
import reducer from "redux/reducers"
import Button from "components/CustomButtons/Button.js"
import Actions from "redux/actions"
import { withRouter } from "react-router"
import MaterialTable from "components/MaterialTable.js"
import PubSub from "pubsub-js"
import { locataireListColumns } from "utils/columns"
import { Switch } from "@material-ui/core"
var dispatch

class FormList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { active: true }
    dispatch = this.props.dispatch
  }

  componentDidMount() {
    dispatch(Actions.getLocataires())
  }

  render() {
    const c = this.props.classes
    const { locataires, canDelete } = this.props
    const { active } = this.state
    console.log("Locataire", locataires)
    return (
      <div className={c.container}>
        <div className='fullW row align-c justify-e'>
          <p
            style={{
              color: !active ? "green" : "unset",
              fontWeight: !active ? "bold" : "unset",
            }}>
            Anciens locataires
          </p>
          <Switch checked={active} onChange={_ => this.setState({ active: !active })} />
          <p
            style={{
              color: active ? "green" : "unset",
              fontWeight: active ? "bold" : "unset",
            }}>
            Dossier en cours
          </p>
        </div>
        <MaterialTable
          title=''
          isLoading={!locataires}
          columns={locataireListColumns}
          actions={[
            {
              icon: "edit",
              tooltip: "Modifier le locataire",
              onClick: (event, rowData) => this.props.history.push(`/locataires/${rowData.id}/edit`),
            },
            {
              icon: "remove_red_eye",
              tooltip: "Voir les details du locataire",
              onClick: (event, rowData) => this.props.history.push(`/locataires/${rowData.id}`),
            },
            canDelete && {
              icon: "delete",
              tooltip: "Supprimer le locataire",
              onClick: (event, rowData) =>
                PubSub.confirm({
                  title: "Supprimer l'element ?",
                  subtitle: "Cette action est irreversible.",
                  onAgree: _ => dispatch(Actions.deleteLocataire(rowData.id)),
                }),
            },
            {
              icon: p => <Button color='primary'>Nouveau Locataire</Button>,
              isFreeAction: true,
              onClick: event => this.props.history.push(`/locataires/new`),
            },
          ]}
          data={locataires?.filter(l => active ? l.locations?.length > 0 : !l.locations?.length)}
          onRowClick={(event, rowData) => this.props.history.push(`/locataires/${rowData.id}`)}
          options={{
            filtering: true,
            pageSize: 20,
            actionsColumnIndex: -1,
          }}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container: {},
})

const mapStateToProps = state => ({ locataires: state.reducer.locataires, canDelete: state.reducer.canDelete })
export default withRouter(withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(FormList))))
