import { withStyles } from "@material-ui/core/styles"
import {} from "assets/jss/material-dashboard-react.js"
import React from "react"
import { TextField } from "@material-ui/core"
import Button from "components/CustomButtons/Button.js"
import PubSub from "pubsub-js"
import Actions from "redux/actions"
import { connect } from "react-redux"
var dispatch

class SetPassword extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      pw: "",
      pw2: "",
    }
    dispatch = this.props.dispatch
  }

  send = () => {
    const { pw, pw2 } = this.state
    if (pw !== pw2)
      return PubSub.notif({
        txt: "Les deux champs ne contiennent pas le meme mot de passe.",
        color: "danger",
        icon: "report_problem",
      })
    if (pw.length < 6)
      return PubSub.notif({
        txt: "Votre mot de passe doit contenir au moins 6 charactères.",
        color: "danger",
        icon: "report_problem",
      })
    dispatch(Actions.setPassword(pw)).then(rep => {
      PubSub.notif({
        txt: "Votre mot de passe à bien été modifié.",
        color: "success",
        icon: "save",
      })
      window.location.pathname = "/"
    })
  }

  render() {
    const c = this.props.classes
    return (
      <div className={c.container}>
        <h1>Changement de mot de passe</h1>
        <TextField
          label='Nouveau mot de passe'
          variant='outlined'
          inputProps={{ style: { color: "black", fontWeight: "bold", background: "white" } }}
          style={{ width: 300 }}
          type='password'
          value={this.state.pw}
          onChange={e => this.setState({ pw: e.target.value })}
        />
        <TextField
          label='Répeter le mot de passe'
          variant='outlined'
          inputProps={{ style: { color: "black", fontWeight: "bold", background: "white" } }}
          style={{ width: 300, marginTop: 20 }}
          value={this.state.pw2}
          type='password'
          onChange={e => this.setState({ pw2: e.target.value })}
        />
        <Button color='primary' variant='contained' onClick={this.send}>
          Valider{" "}
        </Button>
      </div>
    )
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
  logo: {
    objectFit: "contain",
    maxHeight: "50%",
    width: "50%",
  },
})

const mapStateToProps = state => ({})
export default withStyles(styles)(connect(mapStateToProps)(SetPassword))
