import MaterialTable from "material-table"
import React from "react"

export default props => {
  return (
    <MaterialTable
      localization={{
        body: {
          emptyDataSourceMessage: "Aucune donnée à afficher.",
          addTooltip: "Ajouter",
          deleteTooltip: "Supprimer",
          editTooltip: "Éditer",
          filterRow: {
            filterTooltip: "Filtrer",
          },
          editRow: {
            deleteText: "Supprimer l'élement ?",
            cancelTooltip: "Annuler",
            saveTooltip: "Sauvegarder",
          },
        },

        header: {
          actions: "",
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} sur {count}",
          labelRowsSelect: "lignes",
          labelRowsPerPage: "Ligne par page:",
          firstAriaLabel: "Première Page",
          firstTooltip: "Première Page",
          previousAriaLabel: "Page précédente",
          previousTooltip: "Page précédente",
          nextAriaLabel: "Page suivante",
          nextTooltip: "Page suivante",
          lastAriaLabel: "Dernière Page",
          lastTooltip: "Dernière Page",
        },
        toolbar: {
          addRemoveColumns: "Ajouter ou supprimer une collone",
          nRowsSelected: "{0} ligne(n) sélectionnées",
          showColumnsTitle: "Montrer les colonnes",
          showColumnsAriaLabel: "Montrer les colonnes",
          exportTitle: "Exporter",
          exportAriaLabel: "Exporter",
          exportName: "Exporter en CSV",
          searchTooltip: "Chercher",
          searchPlaceholder: "Chercher",
        },
      }}
      {...props}
      options={{ ...props.options,exportDelimiter:";", exportButton: true,pageSizeOptions: [5, 10, 20, 100, 150] }}
    />
  )
}
