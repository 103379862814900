/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import AppartementEditor from "views/Appartements/AppartementEditor.js"
import AppartementsList from "views/Appartements/AppartementsList.js"
import ClientEditor from "views/Clients/ClientEditor.js"
import ClientsList from "views/Clients/ClientsList.js"
import LocataireEditor from "views/Locataires/LocataireEditor.js"
import LocatairesList from "views/Locataires/LocatairesList.js"
import MissionEditor from "views/Missions/MissionEditor.js"
import MissionsList from "views/Missions/MissionsList.js"
import DossiersList from "views/Dossiers/DossiersList.js"
import DossierEditor from "views/Dossiers/DossierEditor.js"
import DashboardPage from "views/Dashboard/Dashboard.js"
import ValidationQuitance from "views/Quitances/ValidationQuitance.js"
import QuitancesList from "views/Quitances/QuitancesList.js"
import QuitancesYear from "views/Quitances/QuitancesYear.js"
import IntendancesList from "views/Intendances/IntendancesList.js"
import Parametres from "views/Parametres.js"
import Dashboard from "views/Dashboard.js"
import Login from "views/Login.js"
// core components/views for Admin layout
import LogOutPage from "views/Logout.js"
import Welcome from "views/Welcome.js"
import SetPassword from "views/SetPassword.js"

// core components/views for RTL layout

//ROLE: null = not loged, [1, 2, 3...] = only for 1 OR 2 OR 3, [] = logged, undefined = for all
//Client mission appart locataire quitance param
const dashboardRoutes = [
  {
    path: "/",
    name: "Dashboard",
    tabTitle: "Dashboard",
    icon: "dashboard",
    hideHeader: true,
    role: ["admin"],
    component: Dashboard,
  },
  {
    path: "/setPassword",
    name: "Regler le mot de passe",
    tabTitle: "Acceuil",
    hidden: true,
    hideHeader: true,
    component: SetPassword,
  },
  {
    path: "/login",
    name: "Se connecter",
    icon: "lock",
    component: Login,
    hideHeader: true,
    role: null,
  },

  {
    path: "/client/",
    name: "Clients",
    icon: "assignment_ind",
    component: ClientsList,
    role: ["admin", "user", "user_delete"],
    children: [
      {
        path: "/",
        layout: "/client",
        params: ":id/:edit?",
        defaultParams: "new",
        sideName: "Nouveau Client",
        icon: "person_add",
        name: "Fiche Client",
        role: ["admin", "user", "user_delete"],
        hidden: true,
        component: ClientEditor,
      },
      {
        path: "/new",
        layout: "/client",
        icon: "person_add",
        name: "Nouveau Client",
        role: ["admin", "user", "user_delete"],
        hidden: false,
        component: ClientEditor,
      },
    ],
  },
  {
    path: "/missions/",
    name: "Missions",
    icon: "work",
    component: MissionsList,
    role: ["admin", "user", "user_delete"],
    children: [
      {
        path: "/",
        layout: "/missions",
        params: ":id/:edit?",
        defaultParams: "new",
        sideName: "Nouvelle Mission",
        icon: "next_week",
        name: "Fiche Mission",
        role: ["admin", "user", "user_delete"],
        hidden: true,
        component: MissionEditor,
      },
      {
        path: "/new",
        layout: "/missions",
        icon: "next_week",
        name: "Nouvelle Mission",
        role: ["admin", "user", "user_delete"],
        hidden: false,
        component: MissionEditor,
      },
    ],
  },
  {
    path: "/appartements/",
    name: "Appartements",
    icon: "apartment",
    component: AppartementsList,
    role: ["admin", "user", "user_delete"],
    children: [
      {
        path: "/",
        layout: "/appartements",
        params: ":id/:edit?",
        defaultParams: "new",
        sideName: "Nouvel Appartement",
        icon: "post_add",
        name: "Fiche Appartement",
        hidden: true,
        role: ["admin", "user", "user_delete"],
        component: AppartementEditor,
      },
      {
        path: "/new",
        layout: "/appartements",
        icon: "post_add",
        name: "Nouvel Appartement",
        role: ["admin", "user", "user_delete"],
        hidden: false,
        component: AppartementEditor,
      },
    ],
  },
  {
    path: "/locataires/",
    name: "Locataires",
    icon: "supervised_user_circle",
    component: LocatairesList,
    role: ["admin", "user", "user_delete"],
    children: [
      {
        path: "/",
        layout: "/locataires",
        params: ":id/:edit?",
        defaultParams: "new",
        sideName: "Nouveau Locataire",
        icon: "person_add",
        name: "Fiche Locataire",
        role: ["admin", "user", "user_delete"],
        hidden: true,
        component: LocataireEditor,
      },
      {
        path: "/new",
        layout: "/locataires",
        icon: "person_add",
        name: "Nouveau Locataire",
        role: ["admin", "user", "user_delete"],
        hidden: false,
        component: LocataireEditor,
      },
    ],
  },
  {
    path: "/dossiers/",
    name: "Dossiers",
    icon: "folder",
    component: DossiersList,
    role: ["admin", "user", "user_delete"],
    children: [
      {
        path: "/",
        layout: "/dossiers",
        params: ":id/:edit?",
        defaultParams: "new",
        sideName: "Nouveau Dossier",
        icon: "create_new_folder",
        name: "Fiche Dossier",
        role: ["admin", "user", "user_delete"],
        hidden: true,
        component: DossierEditor,
      },
      {
        path: "/new",
        layout: "/dossiers",
        icon: "create_new_folder",
        name: "Nouveau Dossier",
        role: ["admin", "user", "user_delete"],
        hidden: false,
        component: DossierEditor,
      },
    ],
  },
  {
    path: "/quitances/",
    name: "Quittances",
    icon: "receipt",
    component: QuitancesList,
    role: ["admin", "user", "user_delete"],
    children: [
      {
        path: "/year",
        layout: "/quitances",
        icon: "event_note",
        name: "Quittances annuelles",
        role: ["admin", "user", "user_delete"],
        hidden: false,
        component: QuitancesYear,
      },
    ],
  },
  {
    path: "/quitance/validation/:state/:uuid",
    name: "Validation de quittance",
    icon: "supervised_user_circle",
    hideHeader: true,
    hideMenu: true,
    hidden: true,
    component: ValidationQuitance,
  },
  {
    path: "/intendance/",
    name: "Intendances",
    icon: "home_work",
    role: ["admin", "user", "user_delete"],
    component: IntendancesList,
  },
  {
    path: "/parametres/",
    name: "Parametres",
    icon: "settings",
    role: ["admin"],
    component: Parametres,
  },
  {
    type: "separator",
    role: [],
  },

  {
    path: "/logout",
    name: "Déconnexion",
    icon: "exit_to_app",
    component: LogOutPage,
    role: [],
  },
]

export default dashboardRoutes
