import React from "react"
import { Icon } from "@material-ui/core"
import { successColor, dangerColor } from "assets/jss/material-dashboard-react.js"
import moment from "moment"

function removeAccents(str) {
  let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  let accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  str = str.split('');
  str.forEach((letter, index) => {
    let i = accents.indexOf(letter);
    if (i != -1) {
      str[index] = accentsOut[i];
    }
  })
  return str.join('');
}

export const appartementListColumns = [
  { title: "Code Postal", field: "codePostal", defaultSort: "asc" },
  { title: "Ville", field: "ville", width: 100, defaultSort: "asc" },
  { title: "Adresse", field: "adresse" },
  { title: "Situation", field: "situation", emptyValue: "" },
  {
    title: "Proprietaire(s)",
    field: "proprietaires.nom",
    defaultSort: "asc",
    render: rd => <p>{rd.proprietaires.map(p => p.nom + " " + p.prenom).join(" / ")}</p>,
  },
  {
    title: "Etat",
    field: "locataires.length",
    customFilterAndSearch: (s, rd) =>
      (rd.wip
        ? "En Travaux"
        : rd.locataires.some(
            l => !l.Dossier.dateFinBail || moment().isBefore(moment(l.Dossier.dateFinBail))
          )
        ? "Occupé"
        : "Vacant"
      )
        .toLowerCase()
        .includes(s.toLowerCase()),
    render: rd => (
      <p>
        {rd.wip
          ? "En Travaux"
          : rd.locataires.some(
              l => !l.Dossier.dateFinBail || moment().isBefore(moment(l.Dossier.dateFinBail))
            )
          ? "Occupé"
          : "Vacant"}
      </p>
    ),
    width: 100,
  },
]

export const appartementColumns = [
  { title: "Ville", field: "ville", width: 150 },
  { title: "Adresse", field: "adresse" },
  { title: "Situation", field: "situation" },
  { title: "Surface", field: "surface", type: "numeric" },
]

export const appartementDashColumns = [
  { title: "CP", field: "codePostal", width: 150 },
  { title: "Ville", field: "ville", width: 150 },
  { title: "Adresse", field: "adresse" },
  { title: "Situation", field: "situation" },
  {
    title: "date de fin de préavis",
    field: "situation",
    customFilterAndSearch: (s, rd) => {
      const found = rd.locataires?.find(
        ({ Dossier }) =>
          Dossier.dateDebutPreavis &&
          Dossier.dateFinPreavis &&
          moment().isBetween(moment(Dossier.dateDebutPreavis), moment(Dossier.dateFinPreavis))
      )
      if (found)
        return moment(found.Dossier.dateFinPreavis)
          .format("LL")
          .includes(s)
      return false
    },
    render: rd => {
      const found = rd.locataires?.find(
        ({ Dossier }) =>
          Dossier.dateDebutPreavis &&
          Dossier.dateFinPreavis &&
          moment().isBetween(moment(Dossier.dateDebutPreavis), moment(Dossier.dateFinPreavis))
      )
      if (found) return <p>{moment(found.Dossier.dateFinPreavis).format("LL")}</p>
      return <p></p>
    },
  },
  {
    title: "etat",
    customSort: (a, b) => {
      const foundA = a.locataires?.find(
        ({ Dossier }) =>
          Dossier.dateDebutPreavis &&
          Dossier.dateFinPreavis &&
          moment().isBetween(moment(Dossier.dateDebutPreavis), moment(Dossier.dateFinPreavis))
      )
      const foundB = b.locataires?.find(
        ({ Dossier }) =>
          Dossier.dateDebutPreavis &&
          Dossier.dateFinPreavis &&
          moment().isBetween(moment(Dossier.dateDebutPreavis), moment(Dossier.dateFinPreavis))
      )
      if (foundA && foundB) return 0
      if (foundA) return 1
      if (foundB) return -1
      return 0
    },
    defaultSort: "desc",
    render: rd => {
      const found = rd.locataires?.find(
        ({ Dossier }) =>
          Dossier.dateDebutPreavis &&
          Dossier.dateFinPreavis &&
          moment().isBetween(moment(Dossier.dateDebutPreavis), moment(Dossier.dateFinPreavis))
      )
      if (found) return <p>En préavis</p>
      return <p>Vacant</p>
    },
  },
]

export const dossierClientColumns = [
  { title: "Numéro de dossier", field: "numFraisDossier" },
  { title: "Nom", field: "locataire.nom" },
  { title: "Prénom", field: "locataire.prenom" },
]

export const dossierListColumns = [
  { title: "Numéro de dossier", field: "numFraisDossier", width: 200 },
  {
    title: "Locataire",
    field: "locataire.nom",
    defaultSort: "asc",
    render: rowData => <p>{rowData.locataire.nom + " " + rowData.locataire.prenom}</p>,
    customFilterAndSearch: (term, rowData) => (removeAccents(rowData.locataire.nom.toLowerCase()) + ' ' + removeAccents(rowData.locataire.prenom.toLowerCase())).indexOf(removeAccents(term.toLowerCase())) != -1
  },
  {
    title: "Proprietaire",
    field: "appartement.proprietaire.nom",
    render: rowData => (
      <p>{rowData.appartement.proprietaire?.nom + " " + rowData.appartement.proprietaire?.prenom}</p>
    ),
    customFilterAndSearch: (term, rowData) => (removeAccents(rowData.appartement.proprietaire?.nom.toLowerCase()) + ' ' + removeAccents(rowData.appartement.proprietaire?.prenom.toLowerCase())).indexOf(removeAccents(term.toLowerCase())) != -1
  },
  { title: "Appartement", field: "appartement.adresse" },
  { title: "Situation", field: "appartement.situation" },
]

export const dossierAppartColumns = [
  { title: "Numéro de dossier", field: "numFraisDossier" },
  { title: "Ville", field: "appartement.ville" },
  { title: "Adresse", field: "appartement.adresse" },
  { title: "Situation", field: "appartement.situation" },
]

export const dossierHistoricColumns = [
  { title: "Numéro de dossier", field: "numFraisDossier" },
  { field: "locataire.nom" },
  { field: "locataire.prenom" },
  {
    title: "Début du bail",
    field: "dateDebutBail",
    render: r => <p>{moment(r.dateDebutBail).format("L")}</p>,
  },
  {
    title: "Fin du bail",
    field: "dateFinBail",
    render: r => <p>{moment(r.dateFinBail).format("L")}</p>,
  },
]

const sortClient = (a, b) => {
  const _a = a.nom
    .split(" ")
    .filter(p => p.toLowerCase() !== "de")
    .join(" ")
  const _b = b.nom
    .split(" ")
    .filter(p => p.toLowerCase() !== "de")
    .join(" ")
  if (_a > _b) return 1
  if (_b > _a) return -1
  return 0
}

export const clientListColumns = [
  {
    title: "Nom",
    field: "nom",
    defaultSort: "asc",
    customSort: sortClient,
  },
  { title: "Prénom", field: "prenom" },
  { title: "Téléphone",
   field: "telDefault",
   render: rd => <p>{rd[rd.telDefaut]}</p>,
   },
  {
    title: "Appartements",
    field: "adresse",
    render: rd => <p>{(rd.biens || []).length}</p>,
    width: 20,
  },
  {
    title: "Missions",
    field: "adresse",
    render: rd => <p>{(rd.Missions || []).length}</p>,
    width: 20,
  },
]

export const clientColumns = [
  { title: "nom", field: "nom", customSort: sortClient },
  { title: "prénom", field: "prenom" },
  { title: "client attaché", field: "prenom2" },
]

export const missionColumns = [
  {
    title: "Client concernés",
    field: "Clients[0].nom",
    render: rd => (
      <p>
        {rd.Clients.length > 0
          ? rd.Clients.map(c => `${c.nom} ${c.prenom}`).join(" - ")
          : "Aucun client attaché"}
      </p>
    ),
    customFilterAndSearch: (term, rowData) => (rowData.Clients.length > 0
      ? rowData.Clients.map(c => `${removeAccents(c.nom.toLowerCase())} ${removeAccents(c.prenom.toLowerCase())}`).join(" - ")
      : "Aucun client attaché").indexOf(removeAccents(term.toLowerCase())) != -1
  },
  { title: "Nombre d'appartement", field: "nbAppart" },
  {
    title: "Date signature",
    field: "dateSignature",
    render: r => <p>{moment(r.dateSignature).format("L")}</p>,
  },
  {
    title: "Date 1er Acompte",
    field: "Accomptes[0]?.date",
    render: rd => <p>{rd.Accomptes[0] ? moment(rd.Accomptes[0].date).format("L") : ""}</p>,
  },
  { title: "Valeur total de la mission", field: "valeurTotalMission" },
  {
    title: "Reste à payer",
    render: r => {
      let rest = r.valeurTotalMission || 0
      if (r && r.Accomptes) {
        r.Accomptes.filter(a => a.regle).forEach(a => (rest -= a.montant))
      }
      return <p>{rest}</p>
    },
  },
]

export const missionDashColumns = [
  {
    title: "Propriétaire",
    field: "Clients[0].nom",
    render: rd => (
      <p>
        {rd.Clients.length > 0
          ? rd.Clients.map(c => `${c.nom} ${c.prenom}`).join(" - ")
          : "Aucun client attaché"}
      </p>
    ),
  },
  { title: "Nombre d'appartement", field: "nbAppart" },
  {
    title: "Nombre d'appartement restant",
    field: "nbAppart",
    render: rd => <p>{rd.nbAppart - rd.Appartements.length}</p>,
    customFilterAndSearch: (t, rd) => t == rd.nbAppart - rd.Appartements.length,
  },
  {
    title: "Date 1er Acompte",
    field: "Accomptes[0]?.date",

    render: rd => <p>{rd.Accomptes[0] ? moment(rd.Accomptes[0].date).format("L") : ""}</p>,
  },
]

export const locataireListColumns = [
  { title: "Nom", field: "nom", defaultSort: "asc" },
  { title: "Prénom", field: "prenom" },
  { title: "Téléphone", field: "tel" },
  { title: "Code Postal", field: "codePostal", width: 100 },
  { title: "Ville", field: "ville", width: 150 },
]

export const parametresListColumns = [
  { title: "Nom", field: "nom", width: 200 },
  { title: "Valeur", field: "valeur" },
]

export const quitanceColumns = [
  { title: "Appartement", field: "Appartement.adresse" },
  { title: "Situation", field: "Appartement.situation" },
  { title: "Locataire", field: "Locataire.nom" },
  { title: "Date", field: "date", render: r => <p>{moment(r.date).format("L")}</p> },
  { title: "Relance(s)", field: "relance", width: 100 },
  {
    title: "Payé",
    width: 100,
    field: "regle",
    defaultSort: "asc",
    render: rd =>
      rd.statusInconu ? (
        <Icon style={{ color: "grey" }}>help_outline</Icon>
      ) : rd.regle ? (
        <Icon style={{ color: successColor[2] }}>check</Icon>
      ) : (
        <Icon style={{ color: dangerColor[3] }}>highlight_off</Icon>
      ),
  },
]

export const quitanceGenerateColumns = [
  { title: "Appartement", field: "Appartement.adresse" },
  { title: "situation", field: "Appartement.situation" },
  { title: "Locataire", field: "Locataire.nom" },
  { field: "Locataire.prenom" },
]

export const accompteColumns = [
  { title: "Intitulé", field: "intitule" },
  { title: "Montant", field: "montant" },
  { title: "N° Facture", field: "numFacture" },
  { title: "Date", field: "date", render: r => <p>{moment(r.date).format("L")}</p> },
  {
    title: "Reglé",
    field: "regle",
    width: 100,
    render: rd =>
      rd.regle ? (
        <Icon style={{ color: successColor[2] }}>check</Icon>
      ) : (
        <Icon style={{ color: dangerColor[3] }}>highlight_off</Icon>
      ),
  },
]

export const intendanceColumns = [
  { title: "Appartement", field: "Appartement.adresse" },
  { title: "", field: "Appartement.situation" },
  { title: "Prix", field: "prix" },
  {
    title: "Date de début",
    field: "dateDepart",
    render: r => <p>{moment(r.dateDepart).format("L")}</p>,
  },
  {
    title: "Date d'écheance",
    field: "dateFin",
    render: r => <p>{moment(r.dateFin).format("L")}</p>,
    defaultSort: "desc",
  },
  {
    title: "Reglé",
    field: "regle",
    width: 100,
    render: rd =>
      rd.regle ? (
        <Icon style={{ color: successColor[2] }}>check</Icon>
      ) : (
        <Icon style={{ color: dangerColor[3] }}>highlight_off</Icon>
      ),
  },
]

export const intendanceDashColumns = [
  { title: "Appartement", field: "Appartement.adresse" },
  { title: "", field: "Appartement.situation" },
  {
    title: "Propriétaire(s)",
    field: "Clients[0].nom",
    render: rd => (
      <p>
        {rd.Appartement?.proprietaires.length > 0
          ? rd.Appartement.proprietaires.map(c => `${c.nom} ${c.prenom}`).join(" - ")
          : "Aucun proprietaire attaché"}
      </p>
    ),
  },
  { title: "Prix TTC", field: "prix" },
  {
    title: "Date d'écheance",
    field: "dateFin",
    render: r => <p>{moment(r.dateFin).format("L")}</p>,
    defaultSort: "desc",
  },
]

export const intendanceAppartColumns = [
  { title: "Prix", field: "prix" },
  {
    title: "Date de début",
    field: "dateDepart",
    render: r => <p>{moment(r.dateDepart).format("L")}</p>,
  },
  {
    title: "Date d'écheance",
    field: "dateFin",
    render: r => <p>{moment(r.dateFin).format("L")}</p>,
  },
  {
    title: "Reglé",
    field: "regle",
    width: 100,
    render: rd =>
      rd.regle ? (
        <Icon style={{ color: successColor[2] }}>check</Icon>
      ) : (
        <Icon style={{ color: dangerColor[3] }}>highlight_off</Icon>
      ),
  },
]

export const inventaireColumns = [
  {
    title: "Locataire",
    field: "locataire.nom",
    render: rd => <p>{rd.locataire.nom + " " + rd.locataire.prenom}</p>,
  },
  {
    title: "Date",
    field: "dateStart",
    render: r => <p>{moment(r.dateStart).format("L")}</p>,
    defaultSort: "desc",
  },
]

export const equipementsColumns = [
  { title: "Nom de l'équipement", field: "nom" },
  {
    title: "type",
    field: "collectif",
    width: 100,
    render: rd => <p>{rd.collectif ? "Collectif" : "Individuel"}</p>,
  },
  {
    title: "Utilisation",
    width: 100,
    render: rd => <p>{(rd.Appartements || []).length}</p>,
  },
]

export const cautionColumns = [{ title: "Nom", field: "nom" }, { title: "Prénom", field: "prenom" }]

export const etatLieuxColumns = [{ title: "Fait à", field: "lieu" }, { title: "Le", field: "date" }]
export const fichierColumns = [
  { title: "Nom", field: "nom" },
  { title: "type", field: "extention" },
]
export const usersColumns = [
  { title: "Email", field: "email", editable: "never" },
  {
    title: "Role",
    field: "role",
    lookup: { user: "Ajout / Edition", user_delete: "Ajout / Edition / Suppression" },
  },
]
export const etatColumns = [
  { title: "Nom", field: "etat" },
  { title: "user", field: "user" },
  {
    title: "date",
    field: "createdAt",
    defaultSort: "desc",
    customSort: (a, b) => {
      return moment(a.date).unix() - moment(b.date).unix()
    },
    render: rd => <p>{`${moment(rd.date).format("L")} - ${moment(rd.date).format("HH:mm")}`}</p>,
  },
]
