import React from "react"
import withReducer from "redux/withReducer"
import { withStyles } from "@material-ui/core/styles"
import {} from "assets/jss/material-dashboard-react.js"
import { connect } from "react-redux"
import reducer from "redux/reducers"
import Actions from "redux/actions"
import { withRouter } from "react-router"
import MaterialTable from "components/MaterialTable.js"
import PubSub from "pubsub-js"
import { clientListColumns } from "utils/columns"
import Button from "components/CustomButtons/Button.js"
var dispatch

class FormList extends React.Component {
  constructor(props) {
    super(props)
    dispatch = this.props.dispatch
  }

  componentDidMount() {
    dispatch(Actions.getClients())
  }

  render() {
    const c = this.props.classes
    const { clients, canDelete } = this.props
    console.log("Client", clients)
    return (
      <div className={c.container}>
        <MaterialTable
          title=''
          isLoading={!clients}
          columns={clientListColumns}
          actions={[
            {
              icon: "edit",
              tooltip: "Modifier le client",
              onClick: (event, rowData) => this.props.history.push(`/client/${rowData.id}/edit`),
            },
            {
              icon: "remove_red_eye",
              tooltip: "Voir les details du client",
              onClick: (event, rowData) => this.props.history.push(`/client/${rowData.id}`),
            },
            canDelete && {
              icon: "delete",
              tooltip: "Supprimer le client",
              onClick: (event, rowData) =>
                PubSub.confirm({
                  title: "Supprimer l'element ?",
                  subtitle: "Cette action est irreversible.",
                  onAgree: _ => dispatch(Actions.deleteClient(rowData.id)),
                }),
            },
            {
              icon: p => <Button color='primary'>Nouveau Client</Button>,
              isFreeAction: true,
              onClick: event => this.props.history.push(`/client/new`),
            },
          ]}
          data={clients}
          onRowClick={(event, rowData) => this.props.history.push(`/client/${rowData.id}`)}
          options={{
            filtering: true,
            pageSize: 20,
            actionsColumnIndex: -1,
          }}
        />
      </div>
    )
  }
}

const styles = theme => ({
  container: {},
})

const mapStateToProps = state => ({ clients: state.reducer.clients, canDelete: state.reducer.canDelete })
export default withRouter(withStyles(styles)(withReducer("reducer", reducer)(connect(mapStateToProps)(FormList))))
