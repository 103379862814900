/*eslint-disable*/
import React from "react"
import PropTypes from "prop-types"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import ListItem from "@material-ui/core/ListItem"
import List from "@material-ui/core/List"
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js"

const useStyles = makeStyles(styles)

export default function Footer(props) {
  const classes = useStyles()
  return (
    <p className={classes.right} style={{ marginRight: 20 }}>
      <span>
        &copy; {1900 + new Date().getYear()}{" "}
        <a href='https://atwio.fr/' target='_blank' className={classes.a}>
          ATWIO
        </a>
      </span>
    </p>
  )
}
