import { CircularProgress, IconButton, Typography } from "@material-ui/core"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import FormControl from "@material-ui/core/FormControl"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import AddIcon from "@material-ui/icons/Add"
import CreateIcon from "@material-ui/icons/Create"
import RemoveIcon from "@material-ui/icons/Delete"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import UploadIcon from "@material-ui/icons/Publish"
import { makeStyles } from "@material-ui/styles"
import Button from "components/CustomButtons/Button.js"
import DialogSelector from "components/DialogSelector"
import { default as React, useState } from "react"
import { withTheme } from "react-jsonschema-form"
import { withRouter } from "react-router"
import { Theme as MuiTheme } from "rjsf-material-ui"
import fileDialog from "utils/fileDialog"
const CreateEquipement = ({ id, required, readonly, disabled, label, value, onChange, onBlur, onFocus, autofocus, options, schema }) => {
  return (
    <FormControl
      //error={!!rawErrors}
      className='selector'
      required={false}>
      <label className={"customLabelLeft"} style={{ minWidth: 200 }}>
        {label}
      </label>
      <TextField
        id={id}
        placeholder="Nom de l'équipement"
        required={required}
        disabled={disabled || readonly}
        style={{ minWidth: 200 }}
        onChange={e => onChange(e.target.value)}
        value={value}
      />
      {!readonly && (
        <IconButton className='no-padding' onClick={_ => schema.onCreate(value)}>
          <AddIcon />
        </IconButton>
      )}
    </FormControl>
  )
}

function TextWidget(props) {
  return <TextField {...props} onChange={v => props.onChange(v.target.value || "")} disabled={props.disabled ? true : props.readonly} />
}

const FileWidget = ({ id, required, readonly, disabled, label, value, onChange, onBlur, onFocus, autofocus, options, schema }) => {
  const displayValue = value ? value.name : "Aucun fichier sélectionné."
  return (
    <FormControl
      //error={!!rawErrors}
      className='selector'
      required={required}>
      <Typography style={{ marginRight: 10 }}>{displayValue}</Typography>
      {!readonly && (
        <IconButton className='no-padding' onClick={_ => fileDialog().then(onChange)}>
          <UploadIcon />
        </IconButton>
      )}
      {!readonly && value && (
        <IconButton className='no-padding' onClick={_ => onChange(undefined)}>
          <RemoveIcon />
        </IconButton>
      )}
    </FormControl>
  )
}

const SelectorWidget = ({ id, required, readonly, disabled, label, value, onChange, onBlur, onFocus, autofocus, options, schema }) => {
  const [open, setOpen] = useState(false)
  const displayValue = value ? (schema.displayValue ? schema.displayValue(value) : value) : schema.noTxt
  return (
    <FormControl
      //error={!!rawErrors}
      className='selector'
      required={required}>
      <TextField
        id={id}
        required={required}
        disabled={disabled || readonly}
        style={{ width: 0, height: 0 }}
        value={value ? (schema.displayValue ? schema.displayValue(value) : value) : ""}
      />
      <Typography style={{ marginRight: 10 }}>{displayValue}</Typography>
      {!readonly && (
        <IconButton className='no-padding' onClick={_ => setOpen(true)}>
          <CreateIcon />
        </IconButton>
      )}
      {!readonly && value && (
        <IconButton className='no-padding' onClick={_ => onChange(undefined)}>
          <RemoveIcon />
        </IconButton>
      )}
      <DialogSelector
        data={schema.choices}
        open={open}
        onClose={_ => setOpen(false)}
        columns={schema.columns}
        disabled={disabled || readonly}
        selectTxt={schema.selectTxt}
        onSelect={selected => onChange(selected)}
        title={schema.selectorTitle}
        onCreate={schema.onCreate}
        createTxt={schema.createTxt}
      />
    </FormControl>
  )
}

const useStyles = makeStyles({
  root: {
    marginTop: 10,
  },
})

const ObjectFieldTemplate = ({ DescriptionField, description, TitleField, title, properties, required, uiSchema, idSchema, formData }) => {
  const classes = useStyles()
  if (uiSchema.spoiler) {
    return (
      <ExpansionPanel defaultExpanded={formData[uiSchema.spoiler]} >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <TitleField id={`${idSchema.$id}-title`} title={title} required={required} />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {description && <DescriptionField id={`${idSchema.$id}-description`} description={description} />}
          <Grid container={true} spacing={2} className={classes.root}>
            {properties.map((element, index) => {
              // console.log(element)
              return (
                <Grid
                  item={true}
                  xs={12}
                  key={index}
                  style={{ marginBottom: "10px" }}
                  className={element.content.props.schema.fullWidth ? "Mui-fullWidth" : ""}>
                  {(element.content.props.schema.format === "date" ||
                    element.content.props.schema.type === "null" ||
                    element.content.props.schema.format === "selector") && (
                      <label className={"customLabel" + (element.content.props.schema.type === "null" ? "" : "Left")}>
                        {element.content.props.schema.title + (element.content.props.required ? "*" : "")}
                      </label>
                    )}
                  {element.content}
                </Grid>
              )
            })}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
  return (
    <>
      {(uiSchema["ui:title"] || title) && !(uiSchema.hideTitle || []).includes(title) && (
        <TitleField id={`${idSchema.$id}-title`} title={title} required={required} />
      )}
      {description && <DescriptionField id={`${idSchema.$id}-description`} description={description} />}
      <Grid container={true} spacing={2} className={classes.root}>
        {properties.map((element, index) => {
          // console.log(element)
          return (
            <Grid
              item={true}
              xs={12}
              key={index}
              style={{ marginBottom: "10px" }}
              className={element.content.props.schema.fullWidth ? "Mui-fullWidth" : ""}>
              {(element.content.props.schema.format === "date" ||
                element.content.props.schema.type === "null" ||
                element.content.props.schema.format === "selector") && (
                  <label className={"customLabel" + (element.content.props.schema.type === "null" ? "" : "Left")}>
                    {element.content.props.schema.title + (element.content.props.required ? "*" : "")}
                  </label>
                )}
              {element.content}
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

const FormMUI = withTheme({ ...MuiTheme, ObjectFieldTemplate })
const Form = function FormWithLoading(props) {
  const { readOnly, initLoad, loading, children, removeEditOnSubmit, onSubmit, history, ...formProps } = props
  return (
    <div className={"formauto " + (readOnly ? " formReadOnly" : "")}>
      {!initLoad ? (
        <FormMUI
          noValidate={true}
          {...formProps}
          widgets={{ selector: SelectorWidget, CreateEquipement, FileWidget, TextWidget }}
          onSubmit={data => {
            if (removeEditOnSubmit) {
              Promise.resolve(onSubmit(data)).then(_ => {
                if (window.location.pathname.includes("/edit")) history.push(window.location.pathname.replace("/edit", ""))
              })
            } else {
              onSubmit(data)
            }
          }}>
          {children}
          {!loading ? (
            <Button
              color='primary'
              type='submit'
              variant='contained'
              style={{
                visibility: readOnly ? "hidden" : "visible",
                margin: "auto",
                display: "flex",
                marginBottom: readOnly ? 0 : 15,
                marginTop: readOnly ? 0 : 40,
              }}
              className={readOnly ? "hidden" : ""}>
              Valider
            </Button>
          ) : (
              <CircularProgress style={{ width: 40 }} />
            )}
        </FormMUI>
      ) : (
          <CircularProgress />
        )}
    </div>
  )
}

const OneOfGenerator = (title, noDesc, selector, selectorTitle, condition, isReadOnly, content, required, isBugged) => {
  return {
    type: "object",
    title,
    description: isReadOnly && !condition ? noDesc : "",
    ...(isReadOnly
      ? {
        properties: condition ? content : {},
      }
      : {
        properties: {
          [selector]: {
            type: "boolean",
            title: selectorTitle,
            enum: [true, false],
            default: condition,
          },
        },
        dependencies: {
          [selector]: {
            oneOf: [
              {
                properties: {
                  [selector]: {
                    enum: [false],
                  },
                },
              },
              {
                properties: {
                  [selector]: {
                    enum: [true],
                  },
                  ...content,
                },
                required: required,
              },
            ],
          },
        },
      }),
  }
}
/**
 *   oneOf: [
            {
              title: noTitle,
              ...(isBugged ? { properties: {} } : {}),
            },
            {
              title: yesTitle,
              properties: content,
              required,
            },
          ].sort(() => (condition ? 1 : -1)),
 */

export { OneOfGenerator }

export default withRouter(Form)
