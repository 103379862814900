import axios from "axios.js"

export const SET_CURRENT_USER = "[USER APP] SET_CURRENT_USER"
export const SET_EDIT_USER = "[USER APP] SET_EDIT_USER"
export const SET_TOKEN = "[USER APP] SET_TOKEN"
export const SET_LOGIN = "[USER APP] SET_LOGIN"
export function addUser(form) {
  const request = axios.post("/user/register", form)
  return dispatch => request
}

export function resetPassword(userId) {
  const request = axios.post(`/user/${userId}/resetPassword`)
  return dispatch => request
}

export function setPassword(newPwd) {
  const request = axios.post(`/user/setPassword`, { password: newPwd })
  return dispatch => request
}

export function login(data) {
  const request = axios.post("/user/login", data)
  return dispatch =>
    request.then(response => {
      if (!response || !response.data || !response.data.user) return false
      dispatch({
        type: SET_TOKEN,
        payload: response.data.user.token,
        remember: data.remember,
      })
      return axios.get("/user/me").then(rep => {
        dispatch({
          type: SET_CURRENT_USER,
          payload: rep.data,
        })
        if (rep.data.mustSetPassword && window.location.pathname !== "/setPassword") {
          window.location.pathname = "/setPassword"
        }
        return true
      })
    })
}

export function autoLogin() {
  if (localStorage.getItem("jwt_access_token")) {
    return dispatch => {
      const out = {
        wasLogged: false,
        logged: false,
      }
      if (localStorage.getItem("jwt_access_token")) out.wasLogged = true
      dispatch({
        type: SET_TOKEN,
        payload: localStorage.getItem("jwt_access_token"),
      })
      return axios
        .get("/user/me")
        .then(rep => {
          if (!rep.data) {
            dispatch({
              type: SET_LOGIN,
              payload: false,
            })
            return out
          }
          out.logged = true
          dispatch({
            type: SET_CURRENT_USER,
            payload: rep.data,
          })
          dispatch({
            type: SET_LOGIN,
            payload: false,
          })
          if (rep.data.mustSetPassword && window.location.pathname !== "/setPassword") {
            window.location.pathname = "/setPassword"
          }
          return out
        })
        .catch(e => {
          console.log("error")
        })
    }
  } else
    return dispatch => {
      dispatch({ type: SET_LOGIN, payload: false })
      return Promise.resolve({ wasLogged: false, logged: false })
    }
}

export function logout() {
  return dispatch => {
    dispatch({
      type: SET_TOKEN,
      payload: "",
      remember: true,
    })
    dispatch({
      type: SET_CURRENT_USER,
      payload: null,
    })
  }
}
