import { Paper, IconButton, Icon, Tooltip } from "@material-ui/core"
import React from "react"
import axios from "axios.js"
import downloadFile from "utils/downloadFile"

class RowGenerateFile extends React.PureComponent {
  render() {
    const { title, uploaded, uploadedName, onUpload, generateUrl, generateName } = this.props
    return (
      <Paper className='fullW'>
        <div className='row justify-o align-c' style={{ padding: 20, marginBottom: 10 }}>
          <h4 className='no-margin'>{title}</h4>
          <div className='row'>
            {uploaded && (
              <Tooltip title='Télécharger le document sauvegardé'>
                <IconButton onClick={_ => downloadFile(uploaded, uploadedName)}>
                  <Icon>get_app</Icon>
                </IconButton>
              </Tooltip>
            )}
            {onUpload && (
              <Tooltip title='Envoyer le document signé'>
                <IconButton onClick={onUpload}>
                  <Icon>publish</Icon>
                </IconButton>
              </Tooltip>
            )}
            {generateUrl && (
              <Tooltip title='Generer le document'>
                <IconButton onClick={_ => axios.get(generateUrl).then(rep => downloadFile(rep.data.generated, generateName))}>
                  <Icon>assignment_returned</Icon>
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
      </Paper>
    )
  }
}

export default RowGenerateFile
